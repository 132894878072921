<!-- TODO: Need Refactoring  -->
<app-header-container
  bgColor="white"
  headerTitle="Distribution Management"
  *ngIf="releasesList.length > 0"
  buttonTitle="Upload APK"
  [isShowButton]="true"
  (onButtonClick)="onCreateRelease()"
></app-header-container>
<div class="container">
  <div></div>

  <div>
    <div class="feature-intro-general-wrapper">
      <div
        class="content-center"
        *ngIf="releasesList.length === 0 && !commonService.isLoading"
      >
        <div class="hello-icon"></div>
        <div class="feature-heading">
          Welcome to <br />
          Fusion Distribution Management
        </div>
        <p class="feature-description">
          Fusion allows you to distribute your releases by uploading an APK file
          on the dashboard provided to you.
        </p>
        <button class="btn-secandry" (click)="onCreateRelease()">
          Get Started
        </button>
      </div>

      <div *ngIf="releasesList.length > 0">
        <div class="main-list" #listContainer (scroll)="onScroll($event)">
          <div class="list-container">
            <div class="list-headers">
              <div class="list-header-content-container">RELEASES</div>
              <div class="list-header-content-container">UPLOADED ON</div>
              <div class="list-header-content-container">INVITED TESTERS</div>
              <div class="list-header-content-container">DOWNLOADS</div>

              <!-- <div class="right-icon-container">
                            </div> -->
            </div>

            <div
              class="list-tile"
              (click)="showDetails(item)"
              *ngFor="let item of releasesList"
            >
              <div class="list-tile-data">
                <div class="list-tile-data-text polar">
                  {{ item.releaseKuid }}
                </div>
              </div>
              <div class="list-tile-data">
                <div class="list-tile-data-text">
                  <app-time-stamp-conversion
                    [utcTime]="item.uploadedOn"
                  ></app-time-stamp-conversion>
                </div>
              </div>
              <div class="list-tile-data1">
                <div class="list-tile-data-text1">{{ item.testers }}</div>
              </div>
              <div class="list-tile-data">
                <div
                  class="list-tile-data-text1"
                  *ngIf="item.downloadCount !== null"
                >
                  {{ item.downloadCount }}
                </div>
                <div
                  class="list-tile-data-text1"
                  *ngIf="item.downloadCount === null"
                >
                  0
                </div>
              </div>

              <div
                class="right-icon-container"
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
              >
                <div class="list-tile-icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <path d="M0 0h16v16H0z" />
                      <path
                        d="M4 9.333c-.367 0-.68-.13-.941-.392A1.282 1.282 0 0 1 2.667 8c0-.367.13-.68.392-.942.26-.26.574-.391.941-.391s.68.13.941.391c.262.261.392.575.392.942s-.13.68-.392.941c-.26.262-.574.392-.941.392zm4 0c-.367 0-.68-.13-.941-.392A1.282 1.282 0 0 1 6.667 8c0-.367.13-.68.392-.942.26-.26.574-.391.941-.391s.68.13.942.391c.26.261.391.575.391.942s-.13.68-.391.941A1.284 1.284 0 0 1 8 9.333zm4 0c-.367 0-.68-.13-.942-.392A1.283 1.283 0 0 1 10.667 8c0-.367.13-.68.391-.942.261-.26.575-.391.942-.391s.68.13.942.391c.26.261.391.575.391.942s-.13.68-.391.941a1.284 1.284 0 0 1-.942.392z"
                        fill="#000"
                        fill-rule="nonzero"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <mat-menu #menu="matMenu">
                <div
                  class="title"
                  mat-menu-item
                  (click)="deleteApk(item.releaseKuid)"
                >
                  <img src="../../../../../../assets/icons/icons-details.svg" />
                  <div class="menu-title">Delete Apk</div>
                </div>
              </mat-menu>
            </div>
          </div>
          <!-- <div class="list-tile"></div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialog to create Test Run -->
<div class="show-dialog-container" [ngClass]="{ active: isShowRelease }">
  <!-- Content container inside the dialog container -->
  <div class="content-container">
    <!-- Inner contianer inside of the content container  -->
    <div class="container-inner">
      <!-- Top container to show heading and close button to close container -->
      <div class="top-container">
        <!-- Dialog heading container  -->
        <div>
          <!-- Dialog header  -->
          <div class="heading">New Release</div>
          <!-- Dialog description -->
          <div class="Lorem-ipsum-dolor-si">
            Upload new release verision and share it with a user group
          </div>
        </div>
        <!-- Button to close container  -->
        <div class="cancel-container" (click)="closeModal()">X</div>
      </div>

      <div
        class="dot-container"
        (click)="file.click()"
        (drop)="onFileDrop($event)"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
      >
        <input
          class="file-input"
          type="file"
          accept=".apk"
          (change)="upload($event)"
          #file
        />
        <img
          class="fil"
          src="../../../../../../assets/icons/ic-outline-file-upload-dis.svg"
        />
        <div class="Drag">
          Drag & Drop or <span class="Upload">Upload</span>
        </div>
        <div class="file">{{ selectedFile?.name }}</div>
      </div>
      <div class="top-container">
        <!-- Dialog heading container  -->
        <div>
          <!-- Dialog header  -->
          <div class="heading">Add Release Notes</div>

          <quill-editor
            [modules]="quillModules"
            class="editor"
            [(ngModel)]="releaseNotes"
          ></quill-editor>
        </div>
      </div>
    </div>
    <div class="apk-error" *ngIf="apkError">Please attach .APK file</div>

    <!-- Progress bar to show upload progress -->
    <div class="progress-wrapper" *ngIf="showUploadValue || showMessage">
      <div class="progress-container">
        <span class="bold" *ngIf="showUploadValue"
          >{{ uploadPercentage }}%</span
        >
        <span class="bold" *ngIf="showMessage">{{
          showVerifyUploadMessage
        }}</span>
      </div>
    </div>

    <!-- Bottom contianer to show create button -->
    <div class="bottom-container">
      <!-- Empty container to show button on right side  -->
      <div></div>
      <!-- Create Test Run Button -->
      <button class="delete-btn" (click)="uploadFile()">Create</button>
    </div>
  </div>
</div>
