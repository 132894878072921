import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor() {}
  public validatePassword(value: string) {
    return !/^(?=.*[a-zA-Z])(?=.*[0-9]).*$/.test(value);
  }

  public validateSlackUrl(value: string) {
    return /^https:\/\/hooks\.slack\.com\/.+$/.test(value);
  }
  public validateTeamsUrl(value: string) {
    return /^https:\/\/netorgft12338476\.webhook\.office\.com\/.+$/.test(value);
  }
  public validateZapierUrl(value: string) {
    return /^https:\/\/hooks\.zapier\.com\/.+$/.test(value);
  }
}
