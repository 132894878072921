import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common/common.service';
import { environment } from 'src/environments/environment';
import { EncryptionService } from '../authentication/encryption-service';
import { API_ENDPOINTS } from '../http/api-end-points';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private route: Router,
    private commonService: CommonService,
    private encryptionService: EncryptionService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const logFormat = 'background: maroon; color: white';
    this.commonService.setLoader(false);
    const errorMessage = '';

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.commonService.setLoader(false);
        this.commonService.isLoading = false;

        this.commonService.apiLogs.push({
          url: request.url,
          body: request.body,
          response: error.error,
        });

        const errorMessage = this.setError(error);
        let decryptError: any;
        if (
          request.url ==
          environment.baseApiUrl + API_ENDPOINTS.CRASHES.MATRICS_URL
        ) {
          return throwError(errorMessage);
        }

        if (error.message == 'try handshake again') {
          localStorage.removeItem('currentUser');
          this.route.navigateByUrl('/account/signin');
          return throwError(errorMessage);
        } else if (typeof error['error'] === 'string') {
          decryptError = this.encryptionService.decrypt(
            error['error'],
            localStorage.getItem('encryptionKey')
          );
        }
        switch (error.status) {
          case HttpError.BadRequest:
            this.commonService.showError(
              error.error.message.title,
              error.error.message.description
            );

            if (this.route.url.includes('/account/signin')) {
              localStorage.clear();
              this.route.navigateByUrl('/account/signin');
            }
            break;

          case HttpError.HandshakeExpired:
            this.commonService.showError(
              error.error.message.description,
              error.error.message.title
            );
            console.error('%c Session Expire 401', logFormat);
            localStorage.clear();
            this.route.navigateByUrl('/account/signin');
            break;

          case HttpError.Unauthorized:
            if (
              error.error.message.description === 'Invalid email or password'
            ) {
              this.commonService.showError(
                error.error.message.title,
                error.error.message.description
              );
            }

            localStorage.clear();
            // this.route.navigateByUrl('/account/signin');
            break;

          case HttpError.NotFound:
            this.commonService.showError(
              error.error.message.title,
              error.error.message.description
            );
            console.error('%c Not Found 404', logFormat);
            break;

          case HttpError.TimeOut:
            this.commonService.showError(
              error.error.message.title,
              error.error.message.description
            );
            console.error('%c TimeOut 408', logFormat);
            break;

          case HttpError.Forbidden:
            if (error.error.message.title === 'Package Limit Reached') {
              this.commonService.isPackegeExpired = true;
            }
            console.error('%c Forbidden 403 ', logFormat);
            break;

          case HttpError.InternalServerError:
            console.error('%c big bad 500', logFormat);
            this.commonService.showError(
              error.error.message.title,
              error.error.message.description
            );
            break;
          case HttpError.false:
            console.error('%c Conflict 409', logFormat);
            this.commonService.showError(
              error.error.message.title,
              error.error.message.description
            );
            break;
          default:
            this.commonService.showError(
              'Service not available.',
              'Please Try Again'
            );
            break;
        }
        return throwError(errorMessage);
      })
    );
  }

  setError(error: HttpErrorResponse): string {
    let errorMessage = 'Unknown error occured';
    if (error.error instanceof ErrorEvent) {
      // Client side error
      errorMessage = error.error.message;
    } else {
      // server side error
      if (error.status !== 0) {
        errorMessage = error.error;
      }
    }
    return errorMessage;
  }
}
export class HttpError {
  static BadRequest = 400;
  static HandshakeExpired = 503;
  static Unauthorized = 401;
  static Forbidden = 403;
  static NotFound = 404;
  static TimeOut = 408;
  static Conflict = 409;
  static InternalServerError = 500;
  static false = 409;
}
