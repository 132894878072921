import { Component } from '@angular/core';
import { CommonService } from './core/services/common/common.service';
import { LoaderService } from './core/services/LoaderService/loader.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLoading = this.loaderService.isLoading$;
  constructor(
    public commonService: CommonService,
    private loaderService: LoaderService,
    private router: Router
  ) {}

  ngOnInit() {
    console.log('>>>>>>>>>>>>> Build Version : 2.5.2 >>>>>>>>>>>>>>');

    const specificRoutes = [
      '/get-started/intro-survey',
      '/get-started/create-workspace',
      '/get-started/create-project',
      '/get-started/sdk-integration',
    ]; // Add the specific routes where you want to apply the logic

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url;

        // Check if the current route matches one of the specific routes
        if (specificRoutes.includes(currentRoute)) {
          // Apply the history pushState and prevent back navigation
          history.pushState(null, '', location.href);
          window.onpopstate = function () {
            history.go(1);
          };
        } else {
          // Remove the onpopstate listener when not on specific routes
          window.onpopstate = null;
        }
      }
    });
    // Handle manual URL changes (direct typing or dev tools)
    // window.addEventListener('popstate', () => {
    //   if (specificRoutes.includes(location.pathname)) {
    //     this.preventBackNavigation();
    //   }
    // });
    // Handle users trying to refresh or change the URL manually
    // window.addEventListener('beforeunload', (event) => {
    //   if (specificRoutes.includes(location.pathname)) {
    //     event.preventDefault();
    //     event.returnValue = ''; // This prevents page refresh or manual URL change
    //   }
    // });
  }
  // Function to apply back navigation prevention
  private preventBackNavigation(): void {
    history.pushState(null, '', location.href);
    window.onpopstate = function () {
      history.go(1); // Prevent back navigation
    };
  }
  title = 'fussion-app-v2';
  showLogs() {}
}
