<!-- Centered layout as in v2 designs -->
<div class="layout-centered">
  <div class="logo-container">
    <div class="ccm-logo-image-container">
      <img
        src="../../../../assets/icons/logo-fusion-logo-colored.svg"
        alt="logo"
        class="logo-image"
      />
    </div>
  </div>
  <div class="container">
    <div class="content-container">
      <div class="signIn">Customer Care Management</div>
      <div class="signIndescription">Enter your credentials</div>

      <form [formGroup]="emailForm" class="full-width">
        <div class="full-width" [hidden]="hideVerifyEmailSection">
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              emailForm.get('email').invalid && emailForm.get('email').touched
            "
            appearance="fill"
          >
            <mat-label>Email</mat-label>
            <input
              class="text-input-feild"
              matInput
              id="email"
              name="email"
              type="email"
              formControlName="email"
              email="true"
            />
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              emailForm.get('email').hasError('required') &&
              emailForm.get('email').touched
            "
          >
            Email required
          </div>
          <div
            class="error-message align-error"
            *ngIf="
              emailForm.get('email').hasError('email') &&
              emailForm.get('email').touched
            "
          >
            Invalid email format.
          </div>
          <!-- <h4 style="color: red">{{ appleLoginError }}</h4> -->
          <button class="btn-primary" type="button" (click)="verifyCCMUser()">
            Continue
          </button>
        </div>
      </form>

      <form [formGroup]="newAccountForm" class="full-width">
        <div class="full-width" *ngIf="userState === 'new-account-mod'">
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              newAccountForm.get('name').invalid &&
              newAccountForm.get('name').touched
            "
            appearance="fill"
          >
            <mat-label class="label">Name</mat-label>
            <input
              class="text-input-feild"
              matInput
              id="name"
              name="name"
              type="name"
              formControlName="name"
            />
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              newAccountForm.get('name').hasError('required') &&
              newAccountForm.get('name').touched
            "
          >
            Username is required.
          </div>
          <div
            class="error-message align-error"
            *ngIf="newAccountForm.get('name').hasError('maxlength')"
          >
            Username cannot be longer than 30 characters.
          </div>
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              newAccountForm.get('password').invalid &&
              newAccountForm.get('password').touched
            "
            appearance="fill"
          >
            <mat-label class="label">Password</mat-label>
            <div class="flx">
              <input
                class="text-input-feild"
                matInput
                type="password"
                name="password"
                id="password"
                formControlName="password"
              />
            </div>
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              newAccountForm.get('password').hasError('required') &&
              newAccountForm.get('password').touched
            "
          >
            Password is required.
          </div>
          <div
            class="error-message align-error"
            *ngIf="
              newAccountForm.get('password').hasError('pattern') &&
              newAccountForm.get('password').touched
            "
          >
            Password should be 8 characters long with letters and numbers.
          </div>
          <div
            class="error-message align-error"
            *ngIf="newAccountForm.get('password').hasError('maxLength')"
          >
            Password length must not exceed 14 characters.
          </div>
          <!-- <h4 style="color: red">{{ appleLoginError }}</h4> -->
          <button class="btn-primary" type="button" (click)="signUp()">
            Signup
          </button>
        </div>
      </form>
      <form [formGroup]="passwordForm" class="full-width">
        <div class="full-width" *ngIf="userState === 'login-mod'">
          <div class="custom-box">
            {{ data.email }}
          </div>
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              passwordForm.get('password').invalid &&
              passwordForm.get('password').touched
            "
            appearance="fill"
          >
            <mat-label>Password</mat-label>
            <div class="flx">
              <input
                class="text-input-feild"
                matInput
                type="password"
                name="password"
                id="password"
                formControlName="password"
              />
            </div>
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              passwordForm.get('password').hasError('required') &&
              passwordForm.get('password').touched
            "
          >
            Password required
          </div>
          <!-- <h4 style="color: red">{{ appleLoginError }}</h4> -->
          <button class="btn-primary" type="button" (click)="signIn()">
            Login
          </button>
        </div>
      </form>
      <!-- <div class="Divider"></div> -->
    </div>
  </div>
  <!-- <div class="copyRight">© Fusion 2024</div> -->
</div>
