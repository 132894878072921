import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { TokenService } from 'src/app/core/authentication/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
})
export class ResetpasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  showResetPassword: boolean = false;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    public commonService: CommonService,
    private _tokenService: TokenService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initializeresetPasswordForm();
  }

  initializeresetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onContinue() {
    let redirectUrl = `${environment.webSiteURL}account/createpassword?email=${this.resetPasswordForm.value.email}`;
    this.commonService.isLoading = true;
    if (this.resetPasswordForm.valid) {
      this._tokenService.requestHandShakeToken().subscribe(async (res) => {
        let { token } = res;
        localStorage.setItem('token', token);
        this._authService
          .RequestResetPassword(this.resetPasswordForm.value.email, redirectUrl)
          .subscribe((res) => {
            this.commonService.isLoading = false;
            let { message } = res;
            this.commonService.showSuccess(
              'Check your email',
              'A link has been sent your email'
            );

            this.showResetPassword = true;
          });
      });
    } else {
      // Invalid form, mark fields as touched and set errors
      this.commonService.isLoading = false;
      for (const controlName in this.resetPasswordForm.controls) {
        const control = this.resetPasswordForm.get(controlName);
        if (control && !control.touched) {
          control.markAsTouched();
        }
      }
    }
  }
}
