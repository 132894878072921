<div class="dashboard-main">
  <div class="dashboard-padding" *ngIf="dashboardData">
    <app-api-dashboard-header
      *ngIf="componentName"
      [componentName]="componentName"
      [lastUpdateTime]="lastFetchTime"
      [overallHealth]="overallHealth"
    ></app-api-dashboard-header>
    <app-graphs-component
      [dashboardData]="dashboardData"
      [recievedChartName]="chartName"
      [recievedNetworkChartName]="networkChartName"
    ></app-graphs-component>

    <div class="api-analysis-wrapper mt-24">
      <div class="analysis-content">
        <app-stats-by-platform [data]="statsByPlatform"></app-stats-by-platform>
        <app-journey-path-guide></app-journey-path-guide>
        <app-network-status-guide></app-network-status-guide>
        <img src="../../../../../../assets/images/Login.svg" alt="" />
        <app-api-call-cards
          [componentName]="componentName"
          [dashboardData]="dashboardData.journey_todays_apis_stats"
        ></app-api-call-cards>
      </div>
    </div>
  </div>
</div>
