import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-api-issues',
  templateUrl: './api-issues.component.html',
  styleUrls: ['./api-issues.component.scss'],
})
export class ApiIssuesComponent implements OnInit {
  @Input() data;
  apiEndpoint: string[];
  constructor() {}

  ngOnInit(): void {
    console.log('ApiIssuesComponent data:', this.data);
    this.apiEndpoint = this.data.map((res) => res.api_endpoint);
    console.log('this.apiEndpoint ', this.apiEndpoint);
  }
}
