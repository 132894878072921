import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stats-by-platform',
  templateUrl: './stats-by-platform.component.html',
  styleUrls: ['./stats-by-platform.component.scss'],
})
export class StatsByPlatformComponent implements OnInit {
  @Input() data: any;
  iosStats: any = {};
  androidStats: any = {};
  constructor() {}

  ngOnInit(): void {
    this.data.forEach((item: any) => {
      if (item.project_platform === 'ios') {
        this.iosStats = item;
      } else if (item.project_platform === 'android') {
        this.androidStats = item;
      }
    });
  }
}
