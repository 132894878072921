<div class="dashboard-main">
  <div class="dashboard-padding" *ngIf="progressiveInsightsDashboardData">
    <app-api-dashboard-header
      *ngIf="componentName"
      [componentName]="componentName"
      [lastUpdateTime]="lastFetchTime"
      [overallHealth]="overallHealth"
    ></app-api-dashboard-header>
    <div class="top-wrapper" *ngIf="progressiveInsightsDashboardData">
      <div class="left-section">
        <app-progressive-and-ios-chart
          [AndroidData]="doughnutChartAndroidData"
          [sentimentHistorydataAndroid]="sentimentHistoryDataAndroid"
        ></app-progressive-and-ios-chart>
      </div>
      <div class="right-section">
        <!-- Add button here -->
        <app-progressive-and-ios-sentiment-chart
          [iosData]="doughnutChartIosData"
          [sentimentHistorydataIos]="sentimentHistoryDataIos"
        ></app-progressive-and-ios-sentiment-chart>
      </div>
    </div>

    <div
      class="bottom-section-wrapper mt-24"
      *ngIf="progressiveInsightsDashboardData"
    >
      <div class="bottom-section">
        <app-and-ios-rating
          [recievedAndroidRatingData]="androidRating"
        ></app-and-ios-rating>
      </div>
      <div class="bottom-section">
        <app-and-ios-incidents
          [recievedAndroidIncidentsData]="androidIncidents"
        ></app-and-ios-incidents>
      </div>
      <div class="bottom-section">
        <app-ios-rating [recievedIosRatingData]="iosRating"></app-ios-rating>
      </div>
      <div class="bottom-section">
        <app-ios-incidents
          [recievedIosIncidentsData]="iosIncidents"
        ></app-ios-incidents>
      </div>
    </div>
  </div>
</div>
