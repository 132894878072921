import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-journey-path-guide',
  templateUrl: './journey-path-guide.component.html',
  styleUrls: ['./journey-path-guide.component.scss']
})
export class JourneyPathGuideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
