<div class="left" (click)="goToPrevious()"></div>
<div class="right" (click)="goToNext()"></div>
<div class="header-wrapper">
  <div class="header-left-content">
    <!-- <svg class="icon">
      <use
        xlink:href="../../../../../../../assets/icon-sprite.svg#Fusion-Logo"
      ></use>
    </svg> -->
    <img
      class="img-icon-fusion"
      src="../../../../../../../assets/icons/Fusion-Logo.svg"
      alt=""
    />
  </div>
  <div class="header-center-section">
    <img
      class="logo"
      src="../../../../../../../assets/icons/ubl-dashboard-logo.webp"
      alt=""
    />
    <p class="f-24 bold m-0">/ {{ componentName }}</p>
  </div>

  <div class="header-right-content">
    <div class="overall-health" *ngIf="overallHealth">
      <p class="medium-desc bold f-16 mt-5 ml-10">
        Overall Health<span class="ml-5 black bold f-16"
          >{{ overallHealth }}%</span
        >
      </p>
    </div>
    <p class="medium-desc f-16 bold">Last Updated</p>
    <div class="small-gap-line"></div>
    <p class="medium-desc f-16">{{ lastUpdated }}</p>
  </div>
</div>
