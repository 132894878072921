import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  @Input() titleHeading: string;
  @Input() description: string;
  @Input() actionButtonTitle: string;
  @Output() onCancel = new EventEmitter();
  @Output() onDone = new EventEmitter();

  constructor(public commonService: CommonService) {}
  ngOnInit(): void {}

  cancel() {
    this.onCancel.emit();
  }
  done() {
    this.onDone.emit();
    this.onCancel.emit();
  }
}
