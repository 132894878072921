<div class="layout-centered">
  <div>
    <div class="logo-container">
      <div class="logo-image-container">
        <img
          src="../../../../assets/icons/logo-fusion-logo-colored.svg"
          alt="logo"
          class="logo-image"
        />
      </div>
    </div>

    <div class="container">
      <div cass="content-container">
        <img
          class="resetLogo"
          src="../../../../../assets/icons/icon-s-idebar-rating-engine-active.svg"
        />
        <div class="title">Create Password</div>
        <div class="description">
          Password should be 8 characters long with letters and numbers.
        </div>
        <form [formGroup]="createPasswordForm" autocomplete="off">
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              createPasswordForm.get('password').invalid &&
              createPasswordForm.get('password').touched
            "
            appearance="fill"
          >
            <mat-label>Password</mat-label>
            <input
              class="text-input-feild"
              formControlName="password"
              matInput
              id="new password"
              name="passwoord"
              type="password"
            />
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              createPasswordForm.get('password').hasError('required') &&
              createPasswordForm.get('password').touched
            "
          >
            Password is required.
          </div>
          <div
            class="error-message align-error"
            *ngIf="createPasswordForm.get('password').hasError('pattern')"
          >
            Password should be 8 characters long with letters and numbers.
          </div>
          <div
            class="error-message align-error"
            *ngIf="createPasswordForm.get('password').hasError('maxLength')"
          >
            Password length must not exceed 14 characters.
          </div>
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              createPasswordForm.get('confirmPassword').invalid &&
              createPasswordForm.get('confirmPassword').touched
            "
            appearance="fill"
          >
            <mat-label>Password Confirmation</mat-label>
            <input
              class="text-input-feild"
              formControlName="confirmPassword"
              matInput
              id="match password"
              name="password"
              type="password"
            />
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              createPasswordForm.get('confirmPassword').hasError('notMatching')
            "
          >
            Passwords do not match. Please try again.
          </div>
          <button
            class="btn-primary"
            type="submit"
            (click)="onCreatePassword()"
          >
            Create
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="copyRight">© Fusion 2023</div>
</div>
