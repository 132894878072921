<div>
  <ng-container
    *ngIf="
      infoSection ||
      topReviews ||
      topReviewsDetail ||
      bucketDetailTopReview ||
      appRating ||
      bucket ||
      bucketDetailRating ||
      bucketDetailsection ||
      androidRating ||
      androidIncidents ||
      iosIncidents ||
      iosRating
    "
  >
    <ng-container *ngFor="let star of starsArray; let i = index">
      <ng-container *ngIf="infoSection || bucketDetailsection">
        <svg class="star-icon" *ngIf="rating >= i + 1">
          <use [attr.xlink:href]="getFilledStarIcon()"></use>
        </svg>
        <svg class="star-icon" *ngIf="rating < i + 1">
          <use [attr.xlink:href]="getUnfilledStarIcon()"></use>
        </svg>
      </ng-container>
      <ng-container
        *ngIf="
          !infoSection &&
          !bucketDetailsection &&
          !androidRating &&
          !androidIncidents &&
          !iosIncidents &&
          !iosRating
        "
      >
        <svg class="reviews-star" *ngIf="rating >= i + 1">
          <use [attr.xlink:href]="getFilledStarIcon()"></use>
        </svg>
        <svg class="reviews-star" *ngIf="rating < i + 1">
          <use [attr.xlink:href]="getUnfilledStarIcon()"></use>
        </svg>
      </ng-container>
      <ng-container
        *ngIf="androidRating || androidIncidents || iosRating || iosIncidents"
      >
        <svg class="api-dashboard-stars" *ngIf="rating >= i + 1">
          <use [attr.xlink:href]="getFilledStarIcon()"></use>
        </svg>
        <svg class="api-dashboard-stars" *ngIf="rating < i + 1">
          <use [attr.xlink:href]="getUnfilledStarIcon()"></use>
        </svg>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
