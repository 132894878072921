<!-- Centered layout as in v2 designs -->

<div class="layout-centered">
  <div class="container">
    <div class="content-container">
      <div class="detail-seaction">
        <h3 class="detail-title">Verify your email</h3>
        <p class="detail-description black">
          We sent an email to
          <b>{{ resetPassword ? emailValue : email }}</b
          >. with a link to verify and activate your account. Please check your
          inbox.
        </p>

        <div
          *ngIf="!resetPassword"
          class="btn-resend-email"
          (click)="resendEmail()"
        >
          Resend Email
        </div>
        <div
          *ngIf="resetPassword"
          class="btn-resend-email"
          [routerLink]="['/account/signin']"
        >
          Sign in
        </div>
      </div>
      <!-- <div
        (click)="goToGmail()"
        class="btn-primary"
        style="--mt: 2rem"
      >
       Check your email.
      </div> -->
    </div>
  </div>

  <!-- Side panel layout same as in v1 -->

  <!-- <div class="layout-side">
      <div class="left-side"></div>
  
      <div class="right-side"></div>
  </div>  -->
</div>
