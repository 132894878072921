import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-rating',
  templateUrl: './ios-rating.component.html',
  styleUrls: ['./ios-rating.component.scss'],
})
export class IosRatingComponent implements OnInit {
  @Input() recievedIosRatingData: any;
  modifiedArray: any[] = [];
  percentageArray: any[] = [];

  iosSection: string = 'iosSection';
  apiDashboard: string = 'apiDashboard';
  constructor() {}

  ngOnInit(): void {
    this.groupElementsByRating();
    this.calculatePercentages();

    console.log('Percentage data ==>', this.percentageArray);

    console.log('App rating data ==>', this.modifiedArray);
  }

  calculatePercentages() {
    this.modifiedArray.forEach((group) => {
      // Calculate the total sum for the group
      const totalSum = group.items.reduce(
        (sum, item) => sum + item.reviews_count,
        0
      );

      // Check if totalSum is greater than 0 before calculating percentages
      if (totalSum > 0) {
        // Calculate the percentage for each element in the group
        group.items.forEach((item) => {
          item.percentage = Math.round((item.reviews_count / totalSum) * 100);
        });
      } else {
        // If totalSum is 0, set percentage to 0 for each item in the group
        group.items.forEach((item) => {
          item.percentage = 0;
        });
      }
    });

    console.log('Updated Modified Array ==>', this.modifiedArray);

    // Check if this.modifiedArray is defined before mapping percentages
    if (this.modifiedArray) {
      this.percentageArray = this.modifiedArray.map((group) =>
        group.items.map((item) => item.percentage)
      );
    }
  }

  groupElementsByRating() {
    const groupedByRating = {};

    this.recievedIosRatingData.forEach((item) => {
      const userRating = item.user_rating.toString(); // Convert to string for object key

      if (!groupedByRating[userRating]) {
        groupedByRating[userRating] = {
          user_rating: item.user_rating,
          items: [],
        };
      }

      groupedByRating[userRating].items.push(item);
    });

    // Sort ratings in descending order
    const sortedRatings = Object.keys(groupedByRating).sort((a, b) => +b - +a);

    // Create a new array with sorted ratings
    this.modifiedArray = sortedRatings.map((rating) => groupedByRating[rating]);

    console.log('Modified Array ==>', this.modifiedArray);
  }

  shouldRenderRating(ratingGroup: any): boolean {
    // Check if all three values are zero
    return ratingGroup.items.some((item) => item.percentage !== 0);
  }
}
