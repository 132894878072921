import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss'],
})
export class RatingStarsComponent implements OnInit {
  @Input() rating: number = 0;
  @Input() infoSection: string = '';
  @Input() topReviews: string = '';
  @Input() topReviewsDetail: string = '';
  @Input() appRating: string = '';
  @Input() bucket: string = '';
  @Input() bucketDetailRating: string = '';
  @Input() bucketDetailsection: string = '';
  @Input() bucketDetailTopReview: string = '';
  @Input() androidRating: string = '';
  @Input() iosRating: string = '';
  @Input() androidIncidents: string = '';
  @Input() iosIncidents: string = '';
  starsArray: number[] = [1, 2, 3, 4, 5];
  constructor() {}

  ngOnInit(): void {}

  getFilledStarIcon(): string {
    return 'assets/icon-sprite.svg#Star-filled';
  }

  getUnfilledStarIcon(): string {
    return 'assets/icon-sprite.svg#Star-unfilled';
  }
}
