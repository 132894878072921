import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../authentication/token.service';
import { API_ENDPOINTS } from '../http/api-end-points';
import { environment } from 'src/environments/environment';
import { ProjectService } from 'src/app/core/services/project/project.service';
import { DistributionManagementService } from 'src/app/core/services/distribution-management/distribution-management.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public token: TokenService,
    public projectService: ProjectService,
    public distribution: DistributionManagementService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('.txt')) {
      // If the request URL matches the excluded URL, let it pass without adding the token
      return next.handle(request);
    } else if (
      request.url !=
        environment.baseApiUrl + API_ENDPOINTS.HANDSHAKE.TOKEN_REQ_URL &&
      request.url != this.projectService.getData() &&
      request.url != this.distribution.getPresignedURL()
    ) {
      if (
        request.url ==
          environment.workspaceApiUrl +
            API_ENDPOINTS.PROFILE.UPLOAD_USER_PROFILE ||
        request.url ==
          environment.workspaceApiUrl +
            API_ENDPOINTS.WORKSPACES.UPLOAD_AVATAR ||
        request.url ==
          environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.UPLOAD_AVATAR ||
        request.url ==
          environment.workspaceApiUrl +
            API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.UPLOAD_APK_RELEASE ||
        request.url ==
          environment.workspaceApiUrl +
            API_ENDPOINTS.RELEASE_MANAGEMENT.UPLOAD_MAPPING_FILE ||
        request.url ==
          environment.workspaceApiUrl +
            API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.GET_REPORT
      ) {
        request = request.clone({
          setHeaders: {
            Authorization: `${this.token.getToken()}`,
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `${this.token.getToken()}`,
            'Content-Type': 'application/json',
            Accept: 'text/plain',
          },
        });
      }
    }

    return next.handle(request);
  }
}
