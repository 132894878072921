<div class="page-wrapper">
  <div class="top-wrapper">
    <p class="sub-heading bold">System Stability</p>
    <div class="right-wrapper">
      <img
        class="icon"
        src="../../../../../../../assets/icons/success-dot.svg"
        alt=""
      />
      <p class="medium-desc f-12 bold mr-16">Good</p>

      <img
        class="icon"
        src="../../../../../../../assets/icons/normal-dot.svg"
        alt=""
      />
      <p class="medium-desc f-12 bold mr-16">Normal</p>

      <img
        class="icon"
        src="../../../../../../../assets/icons/critical-dot.svg"
        alt=""
      />
      <p class="medium-desc f-12 bold">Critical</p>
    </div>
  </div>

  <div
    class="mt-24"
    *ngIf="recievedChartName === 'system-stability-line-chart1'"
  >
    <canvas
      #chartCanvas1
      id="system-stability-line-chart1"
      width="402"
      height="180"
    ></canvas>
  </div>

  <!-- Chart 2 -->
  <div
    class="mt-24"
    *ngIf="recievedChartName === 'system-stability-line-chart2'"
  >
    <canvas
      #chartCanvas2
      id="system-stability-line-chart2"
      width="402"
      height="180"
    ></canvas>
  </div>

  <!-- Chart 3 -->
  <div
    class="mt-24"
    *ngIf="recievedChartName === 'system-stability-line-chart3'"
  >
    <canvas
      #chartCanvas3
      id="system-stability-line-chart3"
      width="402"
      height="180"
    ></canvas>
  </div>

  <!-- Chart 4 -->
  <div
    class="mt-24"
    *ngIf="recievedChartName === 'system-stability-line-chart4'"
  >
    <canvas
      #chartCanvas4
      id="system-stability-line-chart4"
      width="402"
      height="180"
    ></canvas>
  </div>
</div>
