<div class="dashboard-main">
  <div class="dashboard-padding" *ngIf="overViewDashboardData">
    <app-api-dashboard-header
      [componentName]="componentName"
      [lastUpdateTime]="lastFetchTime"
      [overallHealth]="overallHealth"
    ></app-api-dashboard-header>
    <div class="dashboard-content-wrapper mt-24">
      <div class="left-section-wrapper">
        <div class="left-section">
          <div class="left-content">
            <app-service-health-line-chart
              [recievedServiceHealthData]="serviceHealth"
            ></app-service-health-line-chart>
          </div>
          <div class="right-content">
            <p class="medium-desc bold f-19 dull-black">Fault Classification</p>
            <app-radar-chart
              [recievedAndroidRadarData]="radarAndroidData"
              [recievedIosRadarData]="radarIosData"
            ></app-radar-chart>
          </div>
        </div>
        <div class="bottom-left-section mt-24">
          <div class="top-wrapper">
            <p class="sub-heading bold">Services</p>
            <div class="right-wrapper">
              <img
                class="icon"
                src="../../../../../../../assets/icons/success-dot.svg"
                alt=""
              />
              <p class="medium-desc f-12 bold mr-16">Good</p>

              <img
                class="icon"
                src="../../../../../../../assets/icons/normal-dot.svg"
                alt=""
              />
              <p class="medium-desc f-12 bold mr-16">Normal</p>

              <img
                class="icon"
                src="../../../../../../../assets/icons/critical-dot.svg"
                alt=""
              />
              <p class="medium-desc f-12 bold">Critical</p>
            </div>
          </div>
          <div class="bottom">
            <div class="bottom-left-content mt-16">
              <p class="medium-desc f-19 bold">{{ chartData1[0] }}</p>
              <app-services-line-chart
                [recievedChartData]="chartData1[1]"
                [recievedChartName]="chartData1[0]"
              ></app-services-line-chart>
            </div>
            <div class="bottom-left-content mt-16">
              <p class="medium-desc f-19 bold">{{ chartData2[0] }}</p>
              <app-service-line-chart1
                [recievedChartData]="chartData2[1]"
                [recievedChartName]="chartData2[0]"
              ></app-service-line-chart1>
            </div>
            <div class="bottom-left-content mt-16">
              <p class="medium-desc f-19 bold">{{ chartData3[0] }}</p>
              <app-service-line-chart2
                [recievedChartData]="chartData3[1]"
                [recievedChartName]="chartData3[0]"
              ></app-service-line-chart2>
            </div>
          </div>
          <div class="bottom">
            <div class="bottom-left-content mt-16">
              <p class="medium-desc f-19 bold">{{ chartData4[0] }}</p>
              <app-service-line-chart3
                [recievedChartData]="chartData4[1]"
                [recievedChartName]="chartData4[0]"
              ></app-service-line-chart3>
            </div>
            <div class="bottom-left-content mt-16">
              <p class="medium-desc f-19 bold">{{ chartData5[0] }}</p>
              <app-service-line-chart4
                [recievedChartData]="chartData5[1]"
                [recievedChartName]="chartData5[0]"
              ></app-service-line-chart4>
            </div>
            <div class="bottom-left-content mt-16">
              <p class="medium-desc f-19 bold">{{ chartData6[0] }}</p>
              <app-service-line-char5
                [recievedChartData]="chartData6[1]"
                [recievedChartName]="chartData6[0]"
              ></app-service-line-char5>
            </div>
          </div>
        </div>
      </div>

      <div class="right-section">
        <div class="top-wrapper">
          <p class="sub-heading bold">Journey’s Health</p>
          <div class="right-wrapper">
            <img
              class="icon"
              src="../../../../../../../assets/icons/success-dot.svg"
              alt=""
            />
            <p class="medium-desc f-12 bold mr-16">Good</p>

            <img
              class="icon"
              src="../../../../../../../assets/icons/normal-dot.svg"
              alt=""
            />
            <p class="medium-desc f-12 bold mr-16">Normal</p>

            <img
              class="icon"
              src="../../../../../../../assets/icons/critical-dot.svg"
              alt=""
            />
            <p class="medium-desc f-12 bold">Critical</p>
          </div>
        </div>

        <ng-container *ngIf="journeysHealth && journeysHealth.length > 0">
          <ng-container *ngFor="let journey of journeysHealth">
            <div class="content-section mt-16">
              <app-journey-health
                class="mt-16"
                [percentage]="journey.journey_success_rate | roundOff"
                [journeyName]="journey.journey_label"
              ></app-journey-health>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!journeysHealth || journeysHealth.length === 0">
          <div class="content-section mt-16" *ngFor="let _ of [1, 2, 3, 4, 5]">
            <app-journey-health
              class="mt-16"
              [percentage]="''"
              [journeyName]="'Loading data for graph..'"
            ></app-journey-health>
          </div>
        </ng-container>
        <!-- <div class="content-section mt-24">
          <app-journey-health
            class="mt-16"
            [percentage]="signInHealth"
            [journeyName]="'Sign In'"
          ></app-journey-health>
        </div>

        <div class="content-section mt-16">
          <app-journey-health
            class="mt-16"
            [percentage]="topUpHealth"
            [journeyName]="'Top up'"
          ></app-journey-health>
        </div>

        <div class="content-section mt-16">
          <app-journey-health
            class="mt-16"
            [percentage]="utilityBillHealth"
            [journeyName]="'Utility Bill'"
          ></app-journey-health>
        </div>

        <div class="content-section mt-16">
          <app-journey-health
            class="mt-16"
            [percentage]="payAnyoneHealth"
            [journeyName]="'Pay Anyone'"
          ></app-journey-health>
        </div> -->
      </div>
    </div>
  </div>
</div>
