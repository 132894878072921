import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss'],
})
export class RadarChartComponent implements OnInit {
  @Input() recievedAndroidRadarData: any;
  @Input() recievedIosRadarData: any;
  constructor() {}

  ngOnInit(): void {}
}
