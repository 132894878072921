<div class="layout-centered" *ngIf="!showResetPassword">
  <div>
    <div class="logo-container">
      <div class="logo-image-container">
        <img
          src="../../../../assets/icons/logo-fusion-logo-colored.svg"
          alt="logo"
          class="logo-image"
          [routerLink]="['/account/signin']"
        />
      </div>
    </div>

    <div class="container">
      <div cass="content-container">
        <img
          class="resetLogo"
          src="../../../../../assets/icons/icon-s-idebar-rating-engine-active.svg"
        />
        <div class="title">Forgot Password</div>
        <div class="description">
          We have sent an email with a link to verify and activate your account.
          Please check your inbox.
        </div>
        <form
          [formGroup]="resetPasswordForm"
          autocomplete="off"
          class="form-container"
        >
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              resetPasswordForm.get('email').invalid &&
              resetPasswordForm.get('email').touched
            "
            appearance="fill"
          >
            <mat-label>Email</mat-label>
            <input
              class="text-input-feild"
              formControlName="email"
              matInput
              id="email"
              name="email"
              type="email"
            />
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              resetPasswordForm.get('email').hasError('required') &&
              resetPasswordForm.get('email').touched
            "
          >
            Email is required.
          </div>
          <div
            class="error-message align-error"
            *ngIf="resetPasswordForm.get('email').hasError('email')"
          >
            Please enter a valid email.
          </div>
          <button class="btn-primary" type="submit" (click)="onContinue()">
            Continue
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="copyRight">© Fusion 2023</div>
</div>

<app-confirmemail
  *ngIf="showResetPassword"
  [resetPassword]="showResetPassword"
  [emailValue]="resetPasswordForm.value.email"
></app-confirmemail>
