import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';
import { UblDashboardService } from 'src/app/core/services/dashboard/ubl-dashboard.service';

@Component({
  selector: 'app-overview-dashboard',
  templateUrl: './overview-dashboard.component.html',
  styleUrls: ['./overview-dashboard.component.scss'],
})
export class OverviewDashboardComponent implements OnInit {
  componentName = 'Overview';
  overViewDashboardData: any;
  serviceHealth: any;
  radarAndroidData: any;
  radarIosData: any;
  topUpHealth: any;
  utilityBillHealth: any;
  payAnyoneHealth: any;
  signInHealth: any;
  journeysHealth: any;
  servicesData: any;
  chartData1: any;
  chartData2: any;
  chartData3: any;
  chartData4: any;
  chartData5: any;
  chartData6: any;
  ublDashboardPayloadData: any;
  w_kuid: string;
  androidP_kuid: string;
  iosP_kuid: string;
  overallHealth: number;
  lastFetchTime: Date;
  ngOnInit(): void {
    this.emitublDashboardPayloadData();
    this.getOverviewData();
  }

  emitublDashboardPayloadData() {
    this.ublDashboardPayloadData = JSON.parse(
      localStorage.getItem('UblDashboardPayloadData')
    );
    if (this.ublDashboardPayloadData) {
      this.setUblDashboardPayloadData();
    } else {
      console.log('No Ubl Dashboard Payload Data found in localStorage');
    }
  }

  setUblDashboardPayloadData() {
    this.w_kuid = this.ublDashboardPayloadData[0].workspace_kuid;
    this.iosP_kuid = this.ublDashboardPayloadData[0].project_kuid;
    this.androidP_kuid = this.ublDashboardPayloadData[1].project_kuid;
  }

  constructor(
    private _ublDash: UblDashboardService,
    private commonService: CommonService
  ) {}

  getOverviewData() {
    // this.commonService.isLoading = true;

    const body = {
      workspace_kuid: this.w_kuid,
      project_kuids: [this.androidP_kuid, this.iosP_kuid],
      date: this._ublDash.getCurrentDateTime(),
    };
    this._ublDash.getOverviewDashboardData(body).subscribe((res) => {
      console.log('Overview Dashboard data ==>', res);
      this.overViewDashboardData = res.graphData;
      //   this.graphData = res.graphData;
      //   console.log('Graph Data ________', this.graphData);
      //   this.OverViewGraphData = this.graphData;
      this.lastFetchTime = res.lastFetchTime;
      console.log('Test Last Fetch Time ________________', this.lastFetchTime);
      this.overallHealth = res.overallHealth;
      console.log('Test Overall HEalth __________________', this.overallHealth);

      if (this.overViewDashboardData) {
        this.setServiceHealthData();
        this.setServicesData();
        this.setJourneysHealthData();
        this.setFaultClassificationData();
      }
      //   this.commonService.isLoading = false;
    });
  }

  setServiceHealthData() {
    this.serviceHealth = this.overViewDashboardData.services_health;
    console.log('Service Health Data ==> ', this.serviceHealth);
  }

  setFaultClassificationData() {
    this.radarAndroidData =
      this.overViewDashboardData.fault_classification.android;
    console.log('Radar Android Data ==> ', this.radarAndroidData);

    this.radarIosData = this.overViewDashboardData.fault_classification.ios;
    console.log('Radar Ios Data ==> ', this.radarIosData);
  }

  setJourneysHealthData() {
    this.journeysHealth = this.overViewDashboardData.journey_healths;
    // this.signInHealth = Math.round(
    //   this.overViewDashboardData.journey_healths[0].journey_success_rate
    // );
    // this.utilityBillHealth = Math.round(
    //   this.overViewDashboardData.journey_healths[1].journey_success_rate
    // );
    // this.topUpHealth = Math.round(
    //   this.overViewDashboardData.journey_healths[2].journey_success_rate
    // );
    // this.payAnyoneHealth = Math.round(
    //   this.overViewDashboardData.journey_healths[3].journey_success_rate
    // );
  }

  setServicesData() {
    this.servicesData = this.overViewDashboardData.apis_success_rates;
    console.log('servicesData==> ', this.servicesData);
    const serviceDataArray = Object.entries(this.servicesData);
    this.servicesData = serviceDataArray;
    // Testing the output
    console.log('UpdatedservicesData==> ', this.servicesData);
    this.chartData1 = this.servicesData[0];
    console.log('ChartData1', this.chartData1);
    this.chartData2 = this.servicesData[1];
    console.log('ChartData2', this.chartData2);
    this.chartData3 = this.servicesData[2];
    console.log('ChartData3', this.chartData3);
    this.chartData4 = this.servicesData[3];
    console.log('ChartData4', this.chartData4);
    this.chartData5 = this.servicesData[4];
    console.log('ChartData5', this.chartData5);
    this.chartData6 = this.servicesData[5];
    console.log('ChartData6', this.chartData6);
  }
}
