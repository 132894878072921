import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-apis-dashboard',
  templateUrl: './apis-dashboard.component.html',
  styleUrls: ['./apis-dashboard.component.scss'],
})
export class ApisDashboardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
