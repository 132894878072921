import { API_ENDPOINTS } from 'src/app/core/http/api-end-points';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, switchMap, Observable, throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class AiInsightService {
  sentimentalData: any;
  lastVisited_workspace: any;
  ws_kuid: any;
  selectedProject: any;
  project_kuid: string = '';
  SentimentalDashboardData: any;
  constructor(
    private http_client: HttpClient,
    private router: Router,
    private common_service: CommonService
  ) {
    this.getWorkspaceKuid();
    this.getProjectKuid();
  }

  getWorkspaceKuid() {
    const storedWorkspace = localStorage.getItem('lastVisited_workspace');
    this.lastVisited_workspace = JSON.parse(storedWorkspace || '{}');
    const workspaceKuid = this.lastVisited_workspace.ws_kuid;
    this.ws_kuid = workspaceKuid;
    console.log('workspace KUID:', this.ws_kuid);
  }

  getProjectKuid() {
    const storedProject = localStorage.getItem('selectedProject');
    this.selectedProject = JSON.parse(storedProject || '{}');
    const projectKuid = this.selectedProject.p_kuid;
    this.project_kuid = projectKuid;
    console.log('Project KUID:', this.project_kuid);
  }

  async getHistoricalData(): Promise<Observable<any>> {
    this.common_service.isLoading = true;
    const getHistoricalDatapayload = {
      project_kuid: this.project_kuid,
      workspace_kuid: this.ws_kuid,
    };

    return this.http_client
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.AI_INSIGHT.SENTIMENT_ANALYSIS.GET_HISTORICAL_DATA,
        {
          payload: JSON.stringify(getHistoricalDatapayload),
        }
      )
      .pipe(
        map((response) => {
          if (response) {
            console.log('Historical Data response');
            this.common_service.isLoading = false;
          }
          return response;
        })
      );
  }

  async getSentimentDetails(url: any): Promise<Observable<any>> {
    this.common_service.isLoading = true;
    const getReportKuidPayload = {
      project_kuid: this.project_kuid,
      app_url: url,
    };

    return this.http_client
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.AI_INSIGHT.SENTIMENT_ANALYSIS.GET_REPORT,
        {
          payload: JSON.stringify(getReportKuidPayload),
        }
      )
      .pipe(
        tap((response: any) => {
          console.log('Get report kuid ==>', response);
        }),
        switchMap((response: any) => {
          const report_kuid = response.data.kuid;
          console.log('Report ==>', report_kuid);

          return this.http_client
            .get(
              environment.workspaceApiUrl +
                API_ENDPOINTS.AI_INSIGHT.SENTIMENT_ANALYSIS.REPORT_STATUS +
                `${report_kuid}`,
              { responseType: 'text' } // Set responseType to 'text' for streaming data
            )
            .pipe(
              tap((getStatusResponse: any) => {
                console.log('GET status response ==>', getStatusResponse);
              }),
              switchMap((getStatusResponse: any) => {
                if (getStatusResponse.includes('data: complete')) {
                  const getSentimentDataPayload = {
                    project_kuid: this.project_kuid,
                    workspace_kuid: this.ws_kuid,
                    report_kuid: report_kuid,
                  };

                  return this.http_client
                    .post(
                      environment.workspaceApiUrl +
                        API_ENDPOINTS.AI_INSIGHT.SENTIMENT_ANALYSIS
                          .GET_SENTIMENT_ANALYSIS_DETAILS,
                      { payload: JSON.stringify(getSentimentDataPayload) }
                    )
                    .pipe(
                      tap((sentimentResponse: any) => {
                        console.log(
                          'Sentiment analysis details ==>',
                          sentimentResponse
                        );
                      }),
                      map((sentimentResponse: any) => {
                        if (sentimentResponse) {
                          const jsonString = JSON.stringify(sentimentResponse);

                          // Store in localStorage
                          localStorage.setItem('DashboardData', jsonString);
                          this.router.navigateByUrl(
                            'project/sentiment-analysis/dashboard'
                          );
                          this.common_service.isLoading = false;
                          this.SentimentalDashboardData = sentimentResponse;
                          return sentimentResponse;
                        }
                      }),
                      catchError((sentimentError) => {
                        console.error(
                          'Error in sentiment analysis request:',
                          sentimentError
                        );
                        return throwError(sentimentError);
                      })
                    );
                } else {
                  console.warn(
                    'GET status response did not meet expected conditions'
                  );
                  return of(null);
                }
              })
            );
        }),
        catchError((error) => {
          console.error('Error in the initial request:', error);
          return throwError(error);
        }),
        finalize(() => {
          this.common_service.isLoading = false;
        })
      );
  }

  //   async getApiIntegrationDetails(formData: any): Promise<Observable<any>> {
  //     this.common_service.isLoading = true;

  //     return this.http_client
  //       .post(
  //         environment.workspaceApiUrl +
  //           API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.GET_REPORT,
  //         formData
  //       )
  //       .pipe(
  //         switchMap((response: any) => {
  //           console.log('Get report kuid ==>', response);

  //           // Extract the report_kuid from the response
  //           //   const report_kuid = response.data.kuid;
  //           //   console.log('Report ==>', report_kuid);

  //           const getApiIntegrationDataPayload = {
  //             project_kuid: this.project_kuid,
  //             workspace_kuid: this.ws_kuid,
  //             // report_kuid: '5B5460E63F4D4DC48DDA61CDF45EABD8',
  //           };

  //           // Make the second POST request
  //           return this.http_client
  //             .post(
  //               environment.workspaceApiUrl +
  //                 API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION
  //                   .GET_API_INTEGRATION_DATA,
  //               { payload: JSON.stringify(getApiIntegrationDataPayload) }
  //             )
  //             .pipe(
  //               map((response: any) => {
  //                 if (response) {
  //                   console.log('Api Integration details ==>', response);
  //                   //   this.sentimentalData = response;
  //                   const jsonString = JSON.stringify(response);

  //                   // Store in localStorage
  //                   localStorage.setItem('ApiIntegrationData', jsonString);
  //                   //   this.router.navigateByUrl(
  //                   //     'project/api-integration/dashboard'
  //                   //   );
  //                   this.common_service.isLoading = false;

  //                   //   this.SentimentalDashboardData = response;
  //                   return response;
  //                 }
  //               }),
  //               catchError((error) => {
  //                 // Handle the error here
  //                 this.common_service.showError(
  //                   error.message.title,
  //                   error.message.description
  //                 );

  //                 // You can throw a custom error or handle it based on your needs
  //                 return throwError(error);
  //               })
  //             );
  //         }),
  //         catchError((error) => {
  //           // Handle the error from the first request here
  //           this.common_service.showError(
  //             error.message.title,
  //             error.message.description
  //           );

  //           // You can throw a custom error or handle it based on your needs
  //           return throwError(error);
  //         })
  //       );
  //   }

  getSentimentHistoryData(kuid: any): Observable<any> {
    const payload = JSON.stringify({
      project_kuid: this.project_kuid,
      workspace_kuid: this.ws_kuid,
      report_kuid: kuid,
    });

    return this.http_client.post(
      environment.workspaceApiUrl +
        API_ENDPOINTS.AI_INSIGHT.SENTIMENT_ANALYSIS
          .GET_SENTIMENT_ANALYSIS_DETAILS,
      { payload }
    );
  }

  checkDashboardData(): Observable<any> {
    const payload = JSON.stringify({
      project_kuid: this.project_kuid,
      workspace_kuid: this.ws_kuid,
    });

    return this.http_client
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.CHECK_DASHBOARD_DATA,
        { payload }
      )
      .pipe(
        map((res: any) => {
          if (res) {
            console.log('Dashboard Data Check ==>', res);
            return res;
          }
        }),
        catchError((error) => {
          this.common_service.showError(
            error.message.title,
            error.message.description
          );
          return throwError(error);
        })
      );
  }
  async getApiIntegrationDetails(formData: any): Promise<Observable<any>> {
    this.common_service.isLoading = true;

    return this.http_client
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.GET_REPORT,
        formData
      )
      .pipe(
        switchMap((response: any) => {
          console.log('Get Status ==>', response.status);

          // Extract the report_kuid from the response
          //   const report_kuid = response.data.kuid;
          //   console.log('Report ==>', report_kuid);

          const getApiIntegrationDataPayload = {
            project_kuid: this.project_kuid,
            workspace_kuid: this.ws_kuid,
            // report_kuid: report_kuid,
          };

          // Make the second GET request
          return this.http_client
            .get(
              environment.workspaceApiUrl +
                API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.REPORT_STATUS +
                this.project_kuid,
              { responseType: 'text' } // Set responseType to 'text' for streaming data
            )
            .pipe(
              tap((getStatusResponse: any) => {
                console.log('GET status response ==>', getStatusResponse);
              }),
              switchMap((getStatusResponse: any) => {
                if (getStatusResponse.includes('data: complete')) {
                  // Make the third POST request
                  return this.http_client
                    .post(
                      environment.workspaceApiUrl +
                        API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION
                          .GET_API_INTEGRATION_DATA,
                      { payload: JSON.stringify(getApiIntegrationDataPayload) }
                    )
                    .pipe(
                      map((integrationResponse: any) => {
                        if (integrationResponse) {
                          console.log(
                            'Api Integration details ==>',
                            integrationResponse
                          );
                          const jsonString =
                            JSON.stringify(integrationResponse);

                          // Store in localStorage
                          localStorage.setItem(
                            'ApiIntegrationData',
                            jsonString
                          );
                          this.router.navigateByUrl(
                            'project/api-integration/dashboard'
                          );
                          this.common_service.isLoading = false;

                          return integrationResponse;
                        }
                      }),
                      catchError((integrationError) => {
                        console.error(
                          'Error in API integration request:',
                          integrationError
                        );
                        return throwError(integrationError);
                      })
                    );
                } else {
                  console.warn(
                    'GET status response did not meet expected conditions'
                  );
                  return of(null);
                }
              })
            );
        }),
        catchError((error) => {
          // Handle the error from the first request here
          this.common_service.showError(
            error.message.title,
            error.message.description
          );

          // You can throw a custom error or handle it based on your needs
          return throwError(error);
        }),
        finalize(() => {
          this.common_service.isLoading = false;
        })
      );
  }

  getApiIntegrationHistoryDetails(): Observable<any> {
    this.common_service.isLoading = true;

    const getApiIntegrationHistoryDataPayload = {
      project_kuid: this.project_kuid,
      workspace_kuid: this.ws_kuid,
    };

    return this.http_client
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.GET_API_INTEGRATION_DATA,
        { payload: JSON.stringify(getApiIntegrationHistoryDataPayload) }
      )
      .pipe(
        map((integrationResponse: any) => {
          if (integrationResponse) {
            console.log('Api Integration details ==>', integrationResponse);
            const jsonString = JSON.stringify(integrationResponse);

            // Store in localStorage
            localStorage.setItem('ApiIntegrationData', jsonString);
            this.common_service.isLoading = false;
            this.router.navigateByUrl('project/api-integration/dashboard');
          }
          return integrationResponse;
        }),
        catchError((error) => {
          this.common_service.showError(
            error.message.title,
            error.message.description
          );
          return throwError(error);
        }),
        finalize(() => {
          this.common_service.isLoading = false;
        })
      );
  }

  feedbackSdkIntegrated(): Observable<any> {
    const payload = JSON.stringify({
      project_kuid: this.project_kuid,
      workspace_kuid: this.ws_kuid,
    });

    return this.http_client.post(
      environment.workspaceApiUrl +
        API_ENDPOINTS.AI_INSIGHT.FEEDBACK_iNSIGHTS.IS_SDK_INTEGRATED,
      { payload }
    );
  }

  getFeedbackInsightsDetails(): Observable<any> {
    this.common_service.isLoading = true;

    const getFeedbackInsightsDataPayload = {
      project_kuid: this.project_kuid,
      workspace_kuid: this.ws_kuid,
    };

    return this.http_client
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.AI_INSIGHT.FEEDBACK_iNSIGHTS.GET_FEEDBACK_INSIGHTS_DATA,
        { payload: JSON.stringify(getFeedbackInsightsDataPayload) }
      )
      .pipe(
        map((feedbackResponse: any) => {
          if (feedbackResponse) {
            console.log('Feedback Insights details ==>', feedbackResponse);
            const jsonString = JSON.stringify(feedbackResponse);

            // Store in localStorage
            localStorage.setItem('FeedbackInsightsData', jsonString);
            this.common_service.isLoading = false;
            this.router.navigateByUrl('project/feedback-insights/dashboard');
          }
          return feedbackResponse;
        }),
        catchError((error) => {
          this.common_service.showError(
            error.message.title,
            error.message.description
          );
          return throwError(error);
        }),
        finalize(() => {
          this.common_service.isLoading = false;
        })
      );
  }
}
