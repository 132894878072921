<!-- <ng-container *ngIf="dashboardData">
  <div *ngFor="let record of projectSpecificAPITrends; let i = index">
    <div [ngClass]="journeyName(record[i].endpoint)">
      <div class="container">
        <div class="title">
          <b>{{ record[i].journey_endpoint_label }}</b>
        </div>
        <div class="line-1">
          <hr />
        </div>
        <div class="row">
          <div class="column" style="padding-right: 30px; color: #4f4f4f">
            iOS
          </div>
          <div class="column">
            <div class="dot-wrapper">
              <div class="dot-wrapper-1">
                <div class="success-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record[i].project_platform === "ios"
                      ? (record[i].success_rate | number : "1.0-0")
                      : "-"
                  }}
                </div>
              </div>
              <div class="dot-wrapper-1">
                <div class="normal-dot-6px"></div>
                <div class="platform-data">-</div>
              </div>
              <div class="dot-wrapper-1">
                <div class="critical-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record[i].project_platform === "ios"
                      ? (record[i].fail_rate | number : "1.0-0")
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-2">
          <div class="column" style="padding-right: 5px; color: #4f4f4f">
            Android
          </div>
          <div class="column">
            <div class="dot-wrapper">
              <div class="dot-wrapper-1">
                <div class="success-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record[i].project_platform === "android"
                      ? (record[i].success_rate | number : "1.0-0")
                      : "-"
                  }}
                </div>
              </div>
              <div class="dot-wrapper-1">
                <div class="normal-dot-6px"></div>
                <div class="platform-data">-</div>
              </div>
              <div class="dot-wrapper-1">
                <div class="critical-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record[i].project_platform === "android"
                      ? (record[i].fail_rate | number : "1.0-0")
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->

<ng-container *ngIf="dashboardData">
  <div *ngFor="let record of projectSpecificAPITrends | keyvalue">
    <div [ngClass]="journeyName(record.key)">
      <div
        [ngClass]="
          record.value.android?.success_rate <= 95 ||
          record.value.ios?.success_rate <= 95
            ? 'container-box warning-box'
            : 'container-box'
        "
      >
        <div class="title">
          <b>{{
            record.value.android?.api_endpoint || record.value.ios?.api_endpoint
              | customTruncate : 25
          }}</b>
        </div>
        <div class="line-1">
          <hr />
        </div>
        <div class="row">
          <div class="column" style="padding-right: 30px; color: #4f4f4f">
            iOS
          </div>
          <div class="column">
            <div class="dot-wrapper">
              <div class="dot-wrapper-1">
                <div class="success-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record.value.ios
                      ? (record.value.ios?.success_rate | number : "1.0-0") +
                        "%"
                      : "-"
                  }}
                </div>
              </div>
              <div class="dot-wrapper-1">
                <div class="normal-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record.value.ios
                      ? (record.value.ios?.http_fail_rate | number : "1.0-0") +
                        "%"
                      : "-"
                  }}
                </div>
              </div>
              <div class="dot-wrapper-1">
                <div class="critical-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record.value.ios
                      ? (record.value.ios?.tcp_fail_rate | number : "1.0-0") +
                        "%"
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-2">
          <div class="column" style="padding-right: 5px; color: #4f4f4f">
            Android
          </div>
          <div class="column">
            <div class="dot-wrapper">
              <div class="dot-wrapper-1">
                <div class="success-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record.value.android
                      ? (record.value.android?.success_rate
                          | number : "1.0-0") + "%"
                      : "-"
                  }}
                </div>
              </div>
              <div class="dot-wrapper-1">
                <div class="normal-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record.value.android
                      ? (record.value.android?.http_fail_rate
                          | number : "1.0-0") + "%"
                      : "-"
                  }}
                </div>
              </div>
              <div class="dot-wrapper-1">
                <div class="critical-dot-6px"></div>
                <div class="platform-data">
                  {{
                    record.value.android
                      ? (record.value.android?.tcp_fail_rate
                          | number : "1.0-0") + "%"
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- 
<ng-container *ngIf="dashboardData">
  <div
    *ngFor="let title of projectSpecificAPITrends.slice(0, 11); let i = index"
  >
    <div
      *ngIf="
        componentName === 'Pay Anyone' &&
        title.api_endpoint === 'AddAccountTitleFetch'
      "
    >
      <div class="pay-anyone-api-endpoint-class-x">
        <div class="container">
          <div class="title">
            <b>{{ title.journey_endpoint_label }}</b>
          </div>
          <div class="line-1">
            <hr />
          </div>
          <div class="row">
            <div class="column" style="padding-right: 30px">IOS</div>
            <div class="column">
              <div class="dot-wrapper">
                <div class="dot-wrapper-1">
                  <div class="success-dot-6px"></div>
                  <div class="platform-data">
                    {{
                      title.project_platform === "ios"
                        ? (title.success_rate | number : "1.0-0")
                        : "-"
                    }}
                  </div>
                </div>
                <div class="dot-wrapper-1">
                  <div class="normal-dot-6px"></div>
                  <div class="platform-data">-</div>
                </div>
                <div class="dot-wrapper-1">
                  <div class="critical-dot-6px"></div>
                  <div class="platform-data">
                    {{
                      title.project_platform === "ios"
                        ? (title.fail_rate | number : "1.0-0")
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row-2">
            <div class="column" style="padding-right: 5px">Android</div>
            <div class="column">
              <div class="dot-wrapper">
                <div class="dot-wrapper-1">
                  <div class="success-dot-6px"></div>
                  <div class="platform-data">
                    {{
                      title.project_platform === "android"
                        ? (title.success_rate | number : "1.0-0")
                        : "-"
                    }}
                  </div>
                </div>
                <div class="dot-wrapper-1">
                  <div class="normal-dot-6px"></div>
                  <div class="platform-data">-</div>
                </div>
                <div class="dot-wrapper-1">
                  <div class="critical-dot-6px"></div>
                  <div class="platform-data">
                    {{
                      title.project_platform === "android"
                        ? (title.fail_rate | number : "1.0-0")
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dashboardData">
  <div
    *ngFor="let title of projectSpecificAPITrends.slice(0, 11); let i = index"
  >
    <div
      *ngIf="
        componentName === 'Utility Bill' &&
        title.api_endpoint === 'DetailUtilityBillLoadData'
      "
    >
      <div class="utility-bill-api-endpoint-class-x">
        <div class="container">
          <div class="title">
            <b>{{ title.journey_endpoint_label }}</b>
          </div>
          <div class="line-1">
            <hr />
          </div>
          <div class="row">
            <div class="column" style="padding-right: 30px">IOS</div>
            <div class="column">
              <div class="dot-wrapper">
                <div class="dot-wrapper-1">
                  <div class="success-dot-6px"></div>
                  <div class="platform-data">
                    {{
                      title.project_platform === "ios"
                        ? (title.success_rate | number : "1.0-0")
                        : "-"
                    }}
                  </div>
                </div>
                <div class="dot-wrapper-1">
                  <div class="normal-dot-6px"></div>
                  <div class="platform-data">-</div>
                </div>
                <div class="dot-wrapper-1">
                  <div class="critical-dot-6px"></div>
                  <div class="platform-data">
                    {{
                      title.project_platform === "ios"
                        ? (title.fail_rate | number : "1.0-0")
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row-2">
            <div class="column" style="padding-right: 5px">Android</div>
            <div class="column">
              <div class="dot-wrapper">
                <div class="dot-wrapper-1">
                  <div class="success-dot-6px"></div>
                  <div class="platform-data">
                    {{
                      title.project_platform === "android"
                        ? (title.success_rate | number : "1.0-0")
                        : "-"
                    }}
                  </div>
                </div>
                <div class="dot-wrapper-1">
                  <div class="normal-dot-6px"></div>
                  <div class="platform-data">-</div>
                </div>
                <div class="dot-wrapper-1">
                  <div class="critical-dot-6px"></div>
                  <div class="platform-data">
                    {{
                      title.project_platform === "android"
                        ? (title.fail_rate | number : "1.0-0")
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->
