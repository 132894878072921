<!-- Dialog Modal -->
<div class="show-dialog-container">
    <div class="content-container ">
        <div class="title">{{titleHeading}}</div>
        <div class="desc">{{description}}</div>
    
        <div class="flex-end">
            <div class="cancel-btn" (click)="cancel()">
                Cancel
            </div>
            <div class="done-btn" (click)="done()">
                {{actionButtonTitle}}
            </div>
        </div>
    </div>
</div>