import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// components
import { SigninComponent } from './epics/Account/signin/signin.component';
import { SignupComponent } from './epics/Account/signup/signup.component';
import { ConfirmemailComponent } from './epics/Account/confirmemail/confirmemail.component';
import { CreateWorkspaceComponent } from './epics/fusion/Pages/Workspace/create-workspace/create-workspace.component';
import { SelectWorkspaceComponent } from './epics/fusion/Pages/Workspace/select-workspace/select-workspace.component';
import { ActivationComponent } from './epics/Account/Activation/activation/activation.component';
//Services
import { AuthGuardService } from './core/services/auth/auth-guard.service';
import { ResetpasswordComponent } from './epics/Account/ResetPassword/resetpassword/resetpassword.component';
import { CreatepasswordComponent } from './epics/Account/createPassword/createpassword/createpassword.component';
import { ApisDashboardComponent } from './epics/fusion/Pages/dashboard-journey/apis-dashboard.component';
import { MobileTopUpComponent } from './epics/fusion/Pages/dashboard-journey/mobile-top-up/mobile-top-up.component';
import { UtilityBillComponent } from './epics/fusion/Pages/dashboard-journey/utility-bill/utility-bill.component';
import { LoginDashboardComponent } from './epics/fusion/Pages/dashboard-journey/login-dashboard/login-dashboard.component';
import { OverviewDashboardComponent } from './epics/fusion/Pages/dashboard-journey/overview-dashboard/overview-dashboard.component';
import { PayAnyoneDashboardComponent } from './epics/fusion/Pages/dashboard-journey/pay-anyone-dashboard/pay-anyone-dashboard.component';
import { ProgressiveInsightsDashboardComponent } from './epics/fusion/Pages/dashboard-journey/progressive-insights-dashboard/progressive-insights-dashboard.component';
import { CCMLoginComponent } from './epics/Account/ccm-login/ccm-login.component';
import { CcmAuthGuardService } from './core/services/auth/ccm-auth-guard.service';

const appRoutes: Routes = [
  {
    path: 'account',
    title: 'Fusion - Authentication',
    // canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./epics/Authentication/Authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'get-started',
    title: 'Fusion - Get Started',
    // canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./epics/get-started/get-started.module').then(
        (m) => m.GetStartedModule
      ),
  },
  {
    path: 'account/login',
    component: CCMLoginComponent,
    title: 'login',
  },
  //   {
  //     path: 'account/signin',
  //     component: SigninComponent,
  //     title: 'SignIn',
  //   },
  {
    path: 'account/signup',
    component: SignupComponent,
  },
  {
    path: 'account/confirm_email',
    component: ConfirmemailComponent,
  },
  {
    path: 'account/resetpassword',
    component: ResetpasswordComponent,
  },
  {
    path: 'account/createpassword',
    component: CreatepasswordComponent,
  },
  {
    path: 'workspace/create-workspace',
    component: CreateWorkspaceComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'workspace/select-workspace',
    canActivate: [AuthGuardService],
    component: SelectWorkspaceComponent,
  },
  {
    path: 'account/activation',
    component: ActivationComponent,
  },

  {
    path: 'apidashboard',
    component: ApisDashboardComponent,
    canActivate: [AuthGuardService],
    title: 'Fusion | Apis Dashboard',
  },
  {
    path: 'login-dashboard',
    component: LoginDashboardComponent,
    canActivate: [AuthGuardService],
    title: 'Fusion | Login Dashboard',
  },
  {
    path: 'mobile-topup-dashboard',
    component: MobileTopUpComponent,
    canActivate: [AuthGuardService],
    title: 'Fusion | Mobile Topup Dashboard',
  },
  {
    path: 'overview-dashboard',
    component: OverviewDashboardComponent,
    canActivate: [AuthGuardService],
    title: 'Fusion | Overview Dashboard',
  },
  {
    path: 'pay-anyone-dashboard',
    component: PayAnyoneDashboardComponent,
    canActivate: [AuthGuardService],
    title: 'Fusion | Pay Anyone Dashboard',
  },
  {
    path: 'progressive-insights-dashboard',
    component: ProgressiveInsightsDashboardComponent,
    canActivate: [AuthGuardService],
    title: 'Fusion | Progressive Insights Dashboard',
  },
  {
    path: 'utility-bill-dashboard',
    component: UtilityBillComponent,
    canActivate: [AuthGuardService],
    title: 'Fusion | Apis Dashboard',
  },
  {
    path: '',
    title: 'Dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./epics/fusion/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'customer-care-management',
    title: 'Customer Care Management',
    canActivate: [CcmAuthGuardService],
    loadChildren: () =>
      import('./epics/CCM/customer-care-management.module').then(
        (m) => m.CustomerCareManagementModule
      ),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
