<div class="layout-centered">
    <div class="container">
        <div class="content-container">

            <div class="detail-seaction">
                <div class="spinner"></div>
                <div class="detail-title">Account Activation</div>
                <div class="detail-description">
                    Please wait while we are activating your account.....
                </div>

            </div>

        </div>
    </div>
</div>