<div class="event-details">
  <div class="event-details-for-platforms">
    <div class="row">
      <div class="mr-8 flex-center">
        <img src="../../../../../../../assets/icons/ios icon.svg" alt="iOS" />
      </div>
      <div class="platform-name"><b>iOS</b></div>
      <div class="column">
        <div class="dot-wrapper">
          <div class="dot-wrapper-ios">
            <div class="success-line-height-20"></div>
            <div class="platform-data">
              Total successful events
              <span class="color-class">
                {{
                  iosStats.project_platform === "ios"
                    ? (iosStats.success_events | abbreviateNumber : false)
                    : ""
                }}
              </span>
            </div>
          </div>
          <div class="dot-wrapper-ios">
            <div class="normal-line-height-20"></div>
            <div class="platform-data">Total validation errors</div>
            <span class="color-class ml-5">
              {{
                iosStats.project_platform === "ios"
                  ? (iosStats.http_failed_events | abbreviateNumber : false)
                  : ""
              }}
            </span>
          </div>
          <div class="dot-wrapper-ios">
            <div class="critical-line-height-20"></div>
            <div class="platform-data">
              Total network failure
              <span class="color-class">
                {{
                  iosStats.project_platform === "ios"
                    ? (iosStats.tcp_failed_events | abbreviateNumber : false)
                    : ""
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="event-details-for-platforms">
    <div class="event-details-for-platforms android-width">
      <div class="row">
        <div class="mr-8 flex-center">
          <img
            src="../../../../../../../assets/icons/Android Icon.svg"
            alt="Android"
          />
        </div>
        <div class="platform-name"><b>Android</b></div>
        <div class="column">
          <div class="dot-wrapper">
            <div class="dot-wrapper-android">
              <div class="success-line-height-20"></div>
              <div class="platform-data">
                Total successful events
                <span class="color-class">
                  {{
                    androidStats.project_platform === "android"
                      ? (androidStats.success_events | abbreviateNumber : false)
                      : ""
                  }}
                </span>
              </div>
            </div>
            <div class="dot-wrapper-android">
              <div class="normal-line-height-20"></div>
              <div class="platform-data">Total validation errors</div>
              <span class="color-class ml-5">
                {{
                  androidStats.project_platform === "android"
                    ? (androidStats.http_failed_events
                      | abbreviateNumber : false)
                    : ""
                }}
              </span>
            </div>
            <div class="dot-wrapper-android">
              <div class="critical-line-height-20"></div>
              <div class="platform-data">
                Total network failure
                <span class="color-class">
                  {{
                    androidStats.project_platform === "android"
                      ? (androidStats.tcp_failed_events
                        | abbreviateNumber : false)
                      : ""
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
