<!-- Centered layout as in v2 designs -->

<div class="layout-centered" *ngIf="!isShowInprocessing">
  <div class="container">
    <div class="logo-container">
      <img
        src="./../../../../assets/images/logo.png"
        alt="logo"
        class="logo-image"
      />
      <div class="logo-title">Fusion</div>
    </div>

    <div class="user-title">Select a workspace to continue</div>
    <div class="gradient-container-top" *ngIf="isShowBlur"></div>
    <div class="content-container">
      <div class="Workspace-container">
        <div
          class="workspaces"
          *ngFor="let workspace of userWorkSpaces?.owned_workspaces"
        >
          <div
            class="workspace-tile"
            (click)="selectWorkspace(workspace)"
            [ngClass]="{
              active: workspace.ws_kuid == selectedWorkspace.ws_kuid
            }"
          >
            <div class="workspace-icon">
              {{
                workspace?.ws_title.charAt(0) + workspace?.ws_title.charAt(1)
              }}
            </div>
            <div>
              <div class="workspace-title">
                {{ workspace.ws_title }}

                <span class="tag">Owner</span>
              </div>
              <div class="workspace-members">
                {{ workspace.ws_members }} members
              </div>
            </div>
          </div>
        </div>
        <div
          class="workspaces"
          *ngFor="let workspace of userWorkSpaces?.shared_workspaces"
        >
          <div
            class="workspace-tile"
            (click)="selectWorkspace(workspace)"
            [ngClass]="{
              active: workspace.ws_kuid == selectedWorkspace.ws_kuid
            }"
          >
            <div class="workspace-icon">
              {{ workspace.ws_title.charAt(0) + workspace.ws_title.charAt(1) }}
            </div>
            <div>
              <div class="workspace-title">
                {{ workspace.ws_title }}

                <span class="tag">Shared</span>
              </div>
              <div class="workspace-members">
                {{ workspace.ws_members }} members
              </div>
            </div>
          </div>
        </div>

        <div class="error-message" *ngIf="error">
          Please select at least one workspace to continue
        </div>
      </div>
    </div>

    <div class="gradient-container" *ngIf="isShowBlur"></div>
    <div
      class="btn-primary"
      *ngIf="disabled"
      style="--mt: 3rem"
      (click)="onContinue()"
    >
      Continue
    </div>
    <div class="btn-primary-disabled" *ngIf="!disabled" style="--mt: 3rem">
      Continue
    </div>
  </div>
</div>

<div class="layout-centered" *ngIf="isShowInprocessing">
  <div class="content-container1">
    <div class="content1">
      <div class="logo1">
        <img
          class="star-icon"
          src="../../../../../../assets/icons/icon-s-idebar-rating-engine-active.svg"
          alt=""
        />
      </div>

      <div class="title">Welcome to Fusion</div>

      <div class="title-desc">
        Get ready to boost productivity with our powerful tools.
      </div>

      <div class="end-line">
        <div class="center"></div>
      </div>
    </div>
  </div>

  <div class="copyRight">© Fusion 2023</div>
</div>
