import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-graphs-component',
  templateUrl: './graphs-component.component.html',
  styleUrls: ['./graphs-component.component.scss'],
})
export class GraphsComponentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() dashboardData: any;
  @Input() recievedChartName: string;
  @Input() recievedNetworkChartName: string;
  successFailureJourneyData: any[] = [];
  journeySystemStability: any[] = [];
  journeyNetworkIssues: any[] = [];
  joureyAPIIssues: any[] = [];

  ordered_todays_jrnAPIs_trend;

  ngOnChanges() {
    console.log('dashboardData', this.dashboardData);
    this.successFailureJourneyData = this.dashboardData.journey_health;
    this.journeyNetworkIssues = this.dashboardData.journey_network_issues;
    this.journeySystemStability = this.dashboardData.journey_6days_history;
    this.joureyAPIIssues = this.dashboardData.journey_todays_apis_health;

    console.log('journeySystemStability', this.journeySystemStability);
    console.log('success_failure_journey', this.successFailureJourneyData);
  }
}
