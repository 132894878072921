import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-success-failure-chart',
  templateUrl: './success-failure-chart.component.html',
  styleUrls: ['./success-failure-chart.component.scss'],
})
export class SuccessFailureChartComponent implements OnInit {
  @Input() data;
  @ViewChild('successFailureChart', { static: true })
  successFailureChartRef: ElementRef;
  successPercentage: number = 0;
  failurePercentage: number = 0;
  validationPercentage: number = 0;

  constructor() {}

  ngOnInit(): void {
    console.log('success failure chart data', this.data);
    this.successPercentage = this.data[0].success_rate;
    console.log('this.successPercentage', this.successPercentage);
    this.validationPercentage = this.data[0].http_fail_rate;
    console.log('this.validationPercentage', this.validationPercentage);
    this.failurePercentage = this.data[0].tcp_fail_rate;
    console.log('this.failPercentage', this.failurePercentage);

    this.createHalfDoughnutChart(
      this.successFailureChartRef.nativeElement,
      [
        this.successPercentage,
        this.validationPercentage,
        this.failurePercentage,
      ], // Success and failure percentages
      ['#7dd198', '#fcd087', '#ce3632'], // Success and failure colors
      ['#7dd198', '#fcd087', '#ce3632'] // Background colors
    );
  }

  createHalfDoughnutChart(
    chartCanvas: HTMLCanvasElement,
    data: number[],
    backgroundColor: string[],
    borderColor: string[]
  ) {
    const ctx = chartCanvas.getContext('2d');
    const halfDoughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1,
            borderRadius: 30,
          },
        ],
      },
      options: {
        cutout: '85%',
        rotation: 270,
        circumference: 180,
        aspectRatio: 2,
        spacing: 0,
        plugins: {
          legend: {
            display: false,
          },
          //   tooltip: {
          //     filter: (tooltipItem) => {
          //       return tooltipItem.dataIndex === 0;
          //     },
          //   },
        },
      },
    });
  }
}
