import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { API_ENDPOINTS } from 'src/app/core/http/api-end-points';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { CommonService } from '../common/common.service';
@Injectable({
  providedIn: 'root',
})
export class UblDashboardService {
  //   private cachedData: any;
  //   private lastFetchTime: Date;
  private cachedProgressiveInsightsData: any;
  private lastProgressiveInsightsFetchTime: Date;
  private cachedOverviewData: any;
  private lastOverviewFetchTime: Date;
  private cachedPayAnyoneData: any;
  private lastPayAnyoneFetchTime: Date;
  private cachedLoginData: any;
  private lastLoginFetchTime: Date;
  private cachedUtilityBillData: any;
  private lastUtilityBillFetchTime: Date;
  private cachedMobileTopUpData: any;
  private lastMobileTopUpFetchTime: Date;
  overallhealth: any;
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private commonService: CommonService
  ) {}
  componentName: string = '';
  currentDate: any;
  receiveComponentName(message: string) {
    this.componentName = message;
    console.log('comp name from api-dash:', this.componentName);
  }
  getCurrentDateTime() {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    console.log('getCurrentDateTime', this.currentDate);
    return this.currentDate;
  }
  //   getDashboardData(journeyName): Observable<any> {
  //     if (this.shouldFetchData()) {
  //       return this.fetchDashboardData(journeyName);
  //     } else {
  //       return of(this.cachedData); // Return cached data if not expired
  //     }
  //   }
  //   private shouldFetchData(): boolean {
  //     if (!this.lastFetchTime) {
  //       return true; // Fetch if there's no previous fetch time
  //     }
  //     const currentTime = new Date();
  //     const diff = currentTime.getTime() - this.lastFetchTime.getTime();
  //     const minutesElapsed = diff / (1000 * 60);
  //     return minutesElapsed > 10; // Fetch if data is older than 10 minutes
  //   }
  //   private fetchDashboardData(journeyName): Observable<any> {
  //     this.commonService.isLoading = true;
  //     let body = {
  //       workspace_kuid: '41989395a1ee48adbdd0b03631bccd09',
  //       project_kuids: [
  //         '25D43170299E462D805FABA038B04822',
  //         'A5EDF1DD5DB04800AEBE7998E570EB2C',
  //       ],
  //       date: '2024-02-19',
  //       journey_name: journeyName,
  //     };
  //     const url =
  //       environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_JOURNEY_DATA;
  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //     });
  //     return this.http.post(url, body, { headers }).pipe(
  //       map((data) => {
  //         this.cachedData = data;
  //         this.lastFetchTime = new Date();
  //         this.commonService.isLoading = false;
  //         return data;
  //       }),
  //       catchError((error) => {
  //         this.commonService.isLoading = false;
  //         throw error;
  //       })
  //     );
  //   }
  //   getProgressiveInsightsData(): Observable<any[]> {
  //     if (this.shouldFetchProgressiveInsightsData()) {
  //       return this.fetchProgressiveInsightsData();
  //     } else {
  //       return of(this.cachedProgressiveInsightsData); // Return cached data if not expired
  //     }
  //   }
  //   private shouldFetchProgressiveInsightsData(): boolean {
  //     if (!this.lastProgressiveInsightsFetchTime) {
  //       return true; // Fetch if there's no previous fetch time
  //     }
  //     const currentTime = new Date();
  //     const diff =
  //       currentTime.getTime() - this.lastProgressiveInsightsFetchTime.getTime();
  //     console.log('diff ==>', diff);

  //     const minutesElapsed = diff / (1000 * 60);
  //     console.log('Minutes Elapsed ==>', minutesElapsed);

  //     return minutesElapsed > 10; // Fetch if data is older than 10 minutes
  //   }
  //   private fetchProgressiveInsightsData(): Observable<any[]> {
  //     const payloadAndroid = JSON.stringify({
  //       workspace_kuid: '41989395a1ee48adbdd0b03631bccd09',
  //       project_kuid: '25D43170299E462D805FABA038B04822',
  //     });
  //     const androidDataPost = this.http.post(
  //       environment.workspaceApiUrl +
  //         API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.GET_API_INTEGRATION_DATA,
  //       { payload: payloadAndroid }
  //     );
  //     const payloadIos = JSON.stringify({
  //       workspace_kuid: '41989395a1ee48adbdd0b03631bccd09',
  //       project_kuid: 'A5EDF1DD5DB04800AEBE7998E570EB2C',
  //     });
  //     const iosDataPost = this.http.post(
  //       environment.workspaceApiUrl +
  //         API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.GET_API_INTEGRATION_DATA,
  //       { payload: payloadIos }
  //     );
  //     return forkJoin([androidDataPost, iosDataPost]).pipe(
  //       map((data) => {
  //         this.cachedProgressiveInsightsData = data;
  //         this.lastProgressiveInsightsFetchTime = new Date();
  //         return data;
  //       }),
  //       catchError((error) => {
  //         throw error;
  //       })
  //     );
  //   }

  // getOverviewDashboardData(): Observable<any> {
  //   if (this.shouldFetchOverviewData()) {
  //     return this.fetchOverviewData();
  //   } else {
  //     return of(this.cachedOverviewData); // Return cached data if not expired
  //   }
  // }
  // private shouldFetchOverviewData(): boolean {
  //   if (!this.lastOverviewFetchTime) {
  //     return true; // Fetch if there's no previous fetch time
  //   }
  //   const currentTime = new Date();
  //   const diff = currentTime.getTime() - this.lastOverviewFetchTime.getTime();
  //   const minutesElapsed = diff / (1000 * 60);
  //   return minutesElapsed > 10; // Fetch if data is older than 10 minutes
  // }
  // private fetchOverviewData(): Observable<any> {
  //   const body = {
  //     workspace_kuid: '41989395a1ee48adbdd0b03631bccd09',
  //     project_kuids: [
  //       '25D43170299E462D805FABA038B04822',
  //       'A5EDF1DD5DB04800AEBE7998E570EB2C',
  //     ],
  //     date: '2024-02-19',
  //   };
  //   const url =
  //     environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_OVERVIEW_DATA;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //   });
  //   return this.http.post(url, body, { headers }).pipe(
  //     map((data) => {
  //       this.cachedOverviewData = data;
  //       this.lastOverviewFetchTime = new Date();
  //       return data;
  //     }),
  //     catchError((error) => {
  //       throw error;
  //     })
  //   );
  // }

  //   getPayAnyoneDashboardData(journeyName): Observable<any> {
  //     if (this.shouldFetchPayanyOneData()) {
  //       return this.fetchPayanyOneDashboardData(journeyName);
  //     } else {
  //       return of(this.cachedPayAnyoneData); // Return cached data if not expired
  //     }
  //   }
  //   private shouldFetchPayanyOneData(): boolean {
  //     if (!this.lastPayAnyoneFetchTime) {
  //       return true; // Fetch if there's no previous fetch time
  //     }
  //     const currentTime = new Date();
  //     const diff = currentTime.getTime() - this.lastPayAnyoneFetchTime.getTime();
  //     const minutesElapsed = diff / (1000 * 60);
  //     return minutesElapsed > 10; // Fetch if data is older than 10 minutes
  //   }
  //   private fetchPayanyOneDashboardData(journeyName): Observable<any> {
  //     this.commonService.isLoading = true;
  //     let body = {
  //       workspace_kuid: '41989395a1ee48adbdd0b03631bccd09',
  //       project_kuids: [
  //         '25D43170299E462D805FABA038B04822',
  //         'A5EDF1DD5DB04800AEBE7998E570EB2C',
  //       ],
  //       date: '2024-02-19',
  //       journey_name: journeyName,
  //     };
  //     const url =
  //       environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_JOURNEY_DATA;
  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //     });
  //     return this.http.post(url, body, { headers }).pipe(
  //       map((data) => {
  //         this.cachedPayAnyoneData = data;
  //         this.lastPayAnyoneFetchTime = new Date();
  //         this.commonService.isLoading = false;
  //         return data;
  //       }),
  //       catchError((error) => {
  //         this.commonService.isLoading = false;
  //         throw error;
  //       })
  //     );
  //   }

  //   getLoginDashboardData(journeyName): Observable<any> {
  //     if (this.shouldFetchLoginData()) {
  //       return this.fetchLoginDashboardData(journeyName);
  //     } else {
  //       return of(this.cachedLoginData); // Return cached data if not expired
  //     }
  //   }
  //   private shouldFetchLoginData(): boolean {
  //     if (!this.lastLoginFetchTime) {
  //       return true; // Fetch if there's no previous fetch time
  //     }
  //     const currentTime = new Date();
  //     const diff = currentTime.getTime() - this.lastLoginFetchTime.getTime();
  //     const minutesElapsed = diff / (1000 * 60);
  //     return minutesElapsed > 10; // Fetch if data is older than 10 minutes
  //   }
  //   private fetchLoginDashboardData(journeyName): Observable<any> {
  //     this.commonService.isLoading = true;
  //     let body = {
  //       workspace_kuid: '41989395a1ee48adbdd0b03631bccd09',
  //       project_kuids: [
  //         '25D43170299E462D805FABA038B04822',
  //         'A5EDF1DD5DB04800AEBE7998E570EB2C',
  //       ],
  //       date: '2024-02-19',
  //       journey_name: journeyName,
  //     };
  //     const url =
  //       environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_JOURNEY_DATA;
  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //     });
  //     return this.http.post(url, body, { headers }).pipe(
  //       map((data) => {
  //         this.cachedLoginData = data;
  //         this.lastLoginFetchTime = new Date();
  //         this.commonService.isLoading = false;
  //         return data;
  //       }),
  //       catchError((error) => {
  //         this.commonService.isLoading = false;
  //         throw error;
  //       })
  //     );
  //   }

  //   getUtilityBillDashboardData(journeyName): Observable<any> {
  //     if (this.shouldFetchUtilityBillData()) {
  //       return this.fetchUtilityBillDashboardData(journeyName);
  //     } else {
  //       return of(this.cachedUtilityBillData); // Return cached data if not expired
  //     }
  //   }
  //   private shouldFetchUtilityBillData(): boolean {
  //     if (!this.lastUtilityBillFetchTime) {
  //       return true; // Fetch if there's no previous fetch time
  //     }
  //     const currentTime = new Date();
  //     const diff =
  //       currentTime.getTime() - this.lastUtilityBillFetchTime.getTime();
  //     const minutesElapsed = diff / (1000 * 60);
  //     return minutesElapsed > 10; // Fetch if data is older than 10 minutes
  //   }
  //   private fetchUtilityBillDashboardData(journeyName): Observable<any> {
  //     this.commonService.isLoading = true;
  //     let body = {
  //       workspace_kuid: '41989395a1ee48adbdd0b03631bccd09',
  //       project_kuids: [
  //         '25D43170299E462D805FABA038B04822',
  //         'A5EDF1DD5DB04800AEBE7998E570EB2C',
  //       ],
  //       date: '2024-02-19',
  //       journey_name: journeyName,
  //     };
  //     const url =
  //       environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_JOURNEY_DATA;
  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //     });
  //     return this.http.post(url, body, { headers }).pipe(
  //       map((data) => {
  //         this.cachedUtilityBillData = data;
  //         this.lastUtilityBillFetchTime = new Date();
  //         this.commonService.isLoading = false;
  //         return data;
  //       }),
  //       catchError((error) => {
  //         this.commonService.isLoading = false;
  //         throw error;
  //       })
  //     );
  //   }

  //   getMobileTopUpDashboardData(journeyName): Observable<any> {
  //     if (this.shouldFetchMobileTopUpData()) {
  //       return this.fetchMobileTopUpDashboardData(journeyName);
  //     } else {
  //       return of(this.cachedMobileTopUpData); // Return cached data if not expired
  //     }
  //   }
  //   private shouldFetchMobileTopUpData(): boolean {
  //     if (!this.lastMobileTopUpFetchTime) {
  //       return true; // Fetch if there's no previous fetch time
  //     }
  //     const currentTime = new Date();
  //     const diff =
  //       currentTime.getTime() - this.lastMobileTopUpFetchTime.getTime();
  //     const minutesElapsed = diff / (1000 * 60);
  //     return minutesElapsed > 10; // Fetch if data is older than 10 minutes
  //   }
  //   private fetchMobileTopUpDashboardData(journeyName): Observable<any> {
  //     this.commonService.isLoading = true;
  //     let body = {
  //       workspace_kuid: '41989395a1ee48adbdd0b03631bccd09',
  //       project_kuids: [
  //         '25D43170299E462D805FABA038B04822',
  //         'A5EDF1DD5DB04800AEBE7998E570EB2C',
  //       ],
  //       date: '2024-02-19',
  //       journey_name: journeyName,
  //     };
  //     const url =
  //       environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_JOURNEY_DATA;
  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //     });
  //     return this.http.post(url, body, { headers }).pipe(
  //       map((data) => {
  //         this.cachedMobileTopUpData = data;
  //         this.lastMobileTopUpFetchTime = new Date();
  //         this.commonService.isLoading = false;
  //         return data;
  //       }),
  //       catchError((error) => {
  //         this.commonService.isLoading = false;
  //         throw error;
  //       })
  //     );
  //   }

  getOverviewDashboardData(payload: any): Observable<any> {
    if (!this.cachedOverviewData) {
      // If cache is empty or expired, fetch data from API and return it
      return this.fetchAndUpdateOverviewCache(payload);
    } else {
      // If cache is not expired, return cached data
      const cachedData = of(this.cachedOverviewData);

      // Update cache in the background only if cache is 10 minutes older
      if (!this.lastOverviewFetchTime || this.isOverviewCacheExpired()) {
        this.fetchAndUpdateOverviewCache(payload).subscribe();
      }
      return cachedData;
    }
  }

  private fetchAndUpdateOverviewCache(payload: any): Observable<any> {
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_OVERVIEW_DATA;
    // const headers = new HttpHeaders({
    //   Authorization: `Bearer ${localStorage.getItem('token')}`,
    //   'Content-Type': 'application/json',
    // });
    return this.http.post(url, payload).pipe(
      map((data: any) => {
        const servicesHealth = data.services_health || [];
        const lastIndex = servicesHealth.length - 1; // Getting the last index
        const globalSuccessRate =
          lastIndex >= 0 ? servicesHealth[lastIndex].global_success_rate : null;
        this.overallhealth = globalSuccessRate;
        this.cachedOverviewData = {
          graphData: data,
          lastFetchTime: new Date(),
          overallHealth: globalSuccessRate,
        };
        this.lastOverviewFetchTime = new Date();
        return this.cachedOverviewData;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  private isOverviewCacheExpired(): boolean {
    const currentTime = new Date();
    const cacheExpirationTime = new Date(
      this.lastOverviewFetchTime.getTime() + 10 * 60 * 1000
    ); // 10 minutes in milliseconds
    return currentTime > cacheExpirationTime;
  }

  getPayAnyoneDashboardData(payload: any): Observable<any> {
    if (!this.cachedPayAnyoneData) {
      // If cache is empty or expired, fetch data from API and return it
      return this.fetchAndUpdatePayAnyoneCache(payload);
    } else {
      // If cache is not expired, return cached data
      const cacheData = of(this.cachedPayAnyoneData);
      if (!this.lastPayAnyoneFetchTime || this.isPayAnyoneCacheExpired()) {
        this.fetchAndUpdatePayAnyoneCache(payload).subscribe();
      }
      return cacheData;
    }
  }

  private fetchAndUpdatePayAnyoneCache(payload: any): Observable<any> {
    this.commonService.isLoading = true;
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_JOURNEY_DATA;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(url, payload, { headers }).pipe(
      map((data: any) => {
        this.cachedPayAnyoneData = {
          graphData: data,
          lastFetchTime: new Date(),
          overallHealth: this.overallhealth,
        };
        this.lastPayAnyoneFetchTime = new Date();
        this.commonService.isLoading = false;
        return this.cachedPayAnyoneData;
      }),
      catchError((error) => {
        this.commonService.isLoading = false;
        throw error;
      })
    );
  }

  private isPayAnyoneCacheExpired(): boolean {
    const currentTime = new Date();
    const cacheExpirationTime = new Date(
      this.lastPayAnyoneFetchTime.getTime() + 10 * 60 * 1000
    ); // 10 minutes in milliseconds
    console.log('Last Fetch Time Test _________', cacheExpirationTime);
    return currentTime > cacheExpirationTime;
  }

  getLoginDashboardData(payload: any): Observable<any> {
    if (!this.cachedLoginData) {
      // If cache is empty or expired, fetch data from API and return it
      return this.fetchAndUpdateLoginCache(payload);
    } else {
      // If cache is not expired, return cached data
      const cacheData = of(this.cachedLoginData);
      if (!this.lastLoginFetchTime || this.isLoginCacheExpired()) {
        this.fetchAndUpdateLoginCache(payload).subscribe();
      }
      return cacheData;
    }
  }

  private fetchAndUpdateLoginCache(payload: any): Observable<any> {
    this.commonService.isLoading = true;
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_JOURNEY_DATA;
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    // });
    return this.http.post(url, payload).pipe(
      map((data: any) => {
        this.cachedLoginData = {
          graphData: data,
          lastFetchTime: new Date(),
          overallHealth: this.overallhealth,
        };
        this.lastLoginFetchTime = new Date();
        this.commonService.isLoading = false;
        return this.cachedLoginData;
      }),
      catchError((error) => {
        this.commonService.isLoading = false;
        throw error;
      })
    );
  }

  private isLoginCacheExpired(): boolean {
    const currentTime = new Date();
    const cacheExpirationTime = new Date(
      this.lastLoginFetchTime.getTime() + 10 * 60 * 1000
    ); // 10 minutes in milliseconds
    return currentTime > cacheExpirationTime;
  }

  getUtilityBillDashboardData(payload: any): Observable<any> {
    if (!this.cachedUtilityBillData) {
      // If cache is empty or expired, fetch data from API and return it
      return this.fetchAndUpdateUtilityBillCache(payload);
    } else {
      // If cache is not expired, return cached data
      const cacheData = of(this.cachedUtilityBillData);
      if (!this.lastUtilityBillFetchTime || this.isUtilityBillCacheExpired()) {
        this.fetchAndUpdateUtilityBillCache(payload).subscribe();
      }
      return cacheData;
    }
  }

  private fetchAndUpdateUtilityBillCache(payload: any): Observable<any> {
    this.commonService.isLoading = true;
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_JOURNEY_DATA;
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    // });
    return this.http.post(url, payload).pipe(
      map((data: any) => {
        this.cachedUtilityBillData = {
          graphData: data,
          lastFetchTime: new Date(),
          overallHealth: this.overallhealth,
        };
        this.lastUtilityBillFetchTime = new Date();
        this.commonService.isLoading = false;
        return this.cachedUtilityBillData;
      }),
      catchError((error) => {
        this.commonService.isLoading = false;
        throw error;
      })
    );
  }

  private isUtilityBillCacheExpired(): boolean {
    const currentTime = new Date();
    const cacheExpirationTime = new Date(
      this.lastUtilityBillFetchTime.getTime() + 10 * 60 * 1000
    ); // 10 minutes in milliseconds
    return currentTime > cacheExpirationTime;
  }

  getMobileTopUpDashboardData(payload: any): Observable<any> {
    if (!this.cachedMobileTopUpData) {
      // If cache is empty or expired, fetch data from API and return it
      return this.fetchAndUpdateMobileTopUpCache(payload);
    } else {
      // If cache is not expired, return cached data
      const cacheData = of(this.cachedMobileTopUpData);

      if (!this.lastMobileTopUpFetchTime || this.isMobileTopUpCacheExpired()) {
        this.fetchAndUpdateMobileTopUpCache(payload).subscribe();
      }
      return cacheData;
    }
  }

  private fetchAndUpdateMobileTopUpCache(payload: any): Observable<any> {
    this.commonService.isLoading = true;
    const url =
      environment.workspaceApiUrl + API_ENDPOINTS.DASHBOARD.GET_JOURNEY_DATA;
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    // });
    return this.http.post(url, payload).pipe(
      map((data: any) => {
        this.cachedMobileTopUpData = {
          graphData: data,
          lastFetchTime: new Date(),
          overallHealth: this.overallhealth,
        };
        this.lastMobileTopUpFetchTime = new Date();
        this.commonService.isLoading = false;
        return this.cachedMobileTopUpData;
      }),
      catchError((error) => {
        this.commonService.isLoading = false;
        throw error;
      })
    );
  }

  private isMobileTopUpCacheExpired(): boolean {
    const currentTime = new Date();
    const cacheExpirationTime = new Date(
      this.lastMobileTopUpFetchTime.getTime() + 10 * 60 * 1000
    ); // 10 minutes in milliseconds
    return currentTime > cacheExpirationTime;
  }

  getProgressiveInsightsData(
    androidPayload: any,
    iosPayload: any
  ): Observable<any> {
    if (!this.cachedProgressiveInsightsData) {
      // If cache is empty or expired, fetch data from API and return it
      return this.fetchAndUpdateProgressiveInsightsCache(
        androidPayload,
        iosPayload
      );
    } else {
      // If cache is not expired, return cached data
      const cacheData = of(this.cachedProgressiveInsightsData);

      if (
        !this.lastProgressiveInsightsFetchTime ||
        this.isProgressiveInsightsCacheExpired()
      ) {
        this.fetchAndUpdateProgressiveInsightsCache(
          androidPayload,
          iosPayload
        ).subscribe();
      }
      return cacheData;
    }
  }

  private fetchAndUpdateProgressiveInsightsCache(
    androidPayload: any,
    iosPayload: any
  ): Observable<any> {
    const androidDataPost = this.http.post(
      environment.workspaceApiUrl +
        API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.GET_API_INTEGRATION_DATA,
      { payload: androidPayload }
    );

    const iosDataPost = this.http.post(
      environment.workspaceApiUrl +
        API_ENDPOINTS.AI_INSIGHT.API_INTEGRATION.GET_API_INTEGRATION_DATA,
      { payload: iosPayload }
    );

    return forkJoin([androidDataPost, iosDataPost]).pipe(
      map((data: any[]) => {
        this.cachedProgressiveInsightsData = {
          androidData: data[0],
          iosData: data[1],
          lastFetchTime: new Date(),
          overallHealth: this.overallhealth,
        };
        this.lastProgressiveInsightsFetchTime = new Date();
        return this.cachedProgressiveInsightsData;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  private isProgressiveInsightsCacheExpired(): boolean {
    const currentTime = new Date();
    const cacheExpirationTime = new Date(
      this.lastProgressiveInsightsFetchTime.getTime() + 10 * 60 * 1000
    ); // 10 minutes in milliseconds
    const isExpired = currentTime > cacheExpirationTime;
    console.log('Progressive Insights Cache Expired:', isExpired);
    return isExpired;
  }
}
