import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { TokenService } from 'src/app/core/authentication/token.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';

@Component({
  selector: 'app-ccm-login',
  templateUrl: './ccm-login.component.html',
  styleUrls: ['./ccm-login.component.scss'],
})
export class CCMLoginComponent implements OnInit {
  name: string = '';
  email: string = '';
  password: string = '';
  error: boolean = false;
  showPassword: boolean = false;
  emailForm: FormGroup;
  passwordForm: FormGroup;
  newAccountForm: FormGroup;
  appleLoginError: string;
  userState: string = '';
  hideVerifyEmailSection: boolean = false;
  hideSignupSection: boolean = false;
  data: any = [];

  constructor(
    private _auth: AuthenticationService,
    private _router: Router,
    public commonService: CommonService,
    private _tokenService: TokenService,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private _hpService: HelperService
  ) {
    if (this._auth.loggedIn) {
      this._router.navigate(['']);
    }
  }

  ngOnInit(): void {
    this.initializeEmailForm();
    this.initializePasswordForm();
    this.initializeNewAccountForm();
    if (localStorage.getItem('passportToken') !== null) {
      this._tokenService.requestHandShakeToken().subscribe(async (res) => {
        let { token } = res;
        localStorage.setItem('token', token);
        this._auth.passportLogin().subscribe((res) => {
          if (res) {
            this._router.navigate(['workspace/select-workspace']);
          }
        });
      });
    }
  }

  initializeEmailForm() {
    this.emailForm = this.fb.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(64)],
      ],
    });
  }
  initializePasswordForm() {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required]],
    });
  }
  initializeNewAccountForm() {
    this.newAccountForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(30)]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(14),
          this.passwordValidator,
        ],
      ],
    });
  }
  passwordValidator = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const errors: { [key: string]: any } = {};
    if (value.length < 8) {
      errors['minlength'] = true;
      errors['pattern'] = true;
    } else if (value.length > 14) {
      errors['maxLength'] = true;
    } else if (this._hpService.validatePassword(value)) {
      errors['pattern'] = true;
    }
    if (Object.keys(errors).length > 0) {
      return errors;
    }
    return null;
  };

  receiveDataFromApple(data: string) {
    this.appleLoginError = data;
  }

  signIn() {
    if (this.passwordForm.valid) {
      this.commonService.isLoading = true;
      const body = {
        payload: JSON.stringify({
          email: this.emailForm.value.email,
          password: this.passwordForm.value.password,
        }),
      };
      this._auth.CCMSignIn(body).subscribe((res) => {
        this.hideSignupSection = true;
        this._router.navigate(['customer-care-management']);
        console.log('login rsp', res);
        this.commonService.isLoading = false;
      });
    } else {
      // Invalid form, mark fields as touched and set errors
      this.commonService.isLoading = false;
      for (const controlName in this.passwordForm.controls) {
        const control = this.passwordForm.get(controlName);
        if (control && !control.touched) {
          control.markAsTouched();
        }
      }
    }
  }

  signUp() {
    if (this.newAccountForm.valid) {
      this.commonService.isLoading = true;
      const body = {
        payload: JSON.stringify({
          email: this.emailForm.value.email,
          name: this.newAccountForm.value.name,
          password: this.newAccountForm.value.password,
        }),
      };
      this._auth.CCMSignUp(body).subscribe((res) => {
        this.hideSignupSection = true;
        console.log('login rsp', res);
        if (res) {
          this.commonService.isLoading = false;
          this._router.navigate(['customer-care-management']);
        }
      });
    } else {
      // Invalid form, mark fields as touched and set errors
      this.commonService.isLoading = false;
      for (const controlName in this.newAccountForm.controls) {
        const control = this.newAccountForm.get(controlName);
        if (control && !control.touched) {
          control.markAsTouched();
        }
      }
    }
  }

  async verifyCCMUser() {
    if (this.emailForm.valid) {
      this.commonService.isLoading = true;
      this._tokenService.requestHandShakeToken().subscribe(async (res) => {
        let { token } = res;
        localStorage.setItem('token', token);
        this.ngZone.run(async () => {
          (
            await this._auth.verifyCCMUserObservable(this.emailForm.value.email)
          ).subscribe(
            (response) => {
              if (response) {
                console.log('response', response);
                this.data = response;
                this.userState = response.user_state;
                this.hideVerifyEmailSection = true;
                this.hideSignupSection = true;
                console.log('this.userState', this.userState);
                this.commonService.isLoading = false;
              } else {
                this.commonService.showError('Something went wrong', '');
              }
            },
            (error) => {
              this.commonService.showError(
                error.message.title,
                error.message.description
              );
            }
          );
        });
      });
    } else {
      // Invalid form, mark fields as touched and set errors
      this.commonService.isLoading = false;
      for (const controlName in this.emailForm.controls) {
        const control = this.emailForm.get(controlName);
        if (control && !control.touched) {
          control.markAsTouched();
        }
      }
    }
  }
}
