<div class="top-wrapper">
  <p class="sub-heading bold">iOS Rating</p>
  <div class="right-wrapper">
    <img
      class="icon"
      src="../../../../../../../assets/icons/success-dot.svg"
      alt=""
    />
    <p class="medium-desc f-12 bold mr-16">Positive</p>

    <img
      class="icon"
      src="../../../../../../../assets/icons/neutral-dot.svg"
      alt=""
    />
    <p class="medium-desc f-12 bold mr-16">Neutral</p>

    <img
      class="icon"
      src="../../../../../../../assets/icons/critical-dot.svg"
      alt=""
    />
    <p class="medium-desc f-12 bold">Negative</p>
  </div>
</div>
<div class="mt-24">
  <ng-container *ngFor="let rating of modifiedArray; let i = index">
    <ng-container *ngIf="shouldRenderRating(rating)">
      <div class="bottom-wrapper mt-8">
        <div class="top-content">
          <p class="sub-heading bold">{{ rating.user_rating }} Star Rating</p>
          <app-rating-stars
            [rating]="rating.user_rating"
            [iosRating]="iosSection"
          ></app-rating-stars>
        </div>
        <div class="mt-15">
          <app-rating-line
            [apiDashboard]="apiDashboard"
            [dashboardRatineLineData]="percentageArray[i]"
            [dashboardRatineLineData]="percentageArray[i]"
          ></app-rating-line>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
