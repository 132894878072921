import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppleOneTapComponent } from './apple-one-tap/apple-one-tap.component';
import { GoogleOneTapComponent } from './google-one-tap/google-one-tap.component';

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule],
})
export class AuthenticationModule {}
