<div class="page-wrapper">
  <div class="dialog-content">
    <div class="logo-wrapper">
      <div class="upgrade-logo">
        <img src="../../../assets/icons/upgrade-plan.png" alt="" />
      </div>
    </div>
    <div class="cross-button" (click)="closeUpdatePage()"><span>X</span></div>
    <div class="upgrade-heading">Upgrade to a Paid Plan</div>
    <div class="upgrade-desc">
      Please upgrade your package further to create more workspaces.
    </div>
    <div>
      <button class="upgrade-button" (click)="upgradePlan()">Upgrade</button>
    </div>
  </div>
</div>
