import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-api-dashboard-header',
  templateUrl: './api-dashboard-header.component.html',
  styleUrls: ['./api-dashboard-header.component.scss'],
})
export class ApiDashboardHeaderComponent implements OnInit {
  @Input() componentName: string;
  @Input() headerName: string = 'Pay Anyone';
  @Input() lastUpdateTime: any;
  @Input() overallHealth: any;

  currentDate: string;
  showLeft: boolean = false;
  showRight: boolean = false;
  nextDashboardTimer: any;
  lastUpdated: any;
  updateInterval: any;
  lastUpdatedTime: number;
  constructor(private router: Router, private datePipe: DatePipe) {}

  ngOnInit(): void {
    // this.getCurrentDateTime();
    this.scheduleNextDashboard();
    this.lastUpdatedTime = Date.now(); // Initialize lastUpdatedTime with the current timestamp
    this.lastUpdated = new Date(); // Initialize lastUpdated with the current date
    this.updateLastUpdated();
    this.startUpdateInterval();
  }
  updateLastUpdated() {
    const now = Date.now();
    const diff = Math.floor((now - this.lastUpdateTime) / 1000); // Difference in seconds

    console.log('Now:', now);
    console.log('Last Updated Time:', this.lastUpdateTime);

    if (diff < 60) {
      this.lastUpdated = 'a moment ago';
    } else {
      const minutes = Math.floor(diff / 60);
      this.lastUpdated = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
  }

  startUpdateInterval() {
    this.updateInterval = setInterval(() => {
      this.updateLastUpdated();
    }, 60000); // Update every minute (60000 milliseconds)
  }

  goToNext() {
    // Get the current route
    const currentRoute = this.router.url;

    // Define an array of routes in the desired sequence
    const routes = [
      '/overview-dashboard',
      '/pay-anyone-dashboard',
      '/login-dashboard',
      '/mobile-topup-dashboard',
      '/utility-bill-dashboard',
      '/progressive-insights-dashboard',
    ];

    // Find the index of the current route in the array
    const currentIndex = routes.indexOf(currentRoute);

    // Calculate the next index considering the looping behavior
    const nextIndex = (currentIndex + 1) % routes.length;

    // Navigate to the next route
    this.router.navigateByUrl(routes[nextIndex]);
  }

  goToPrevious() {
    // Get the current route
    const currentRoute = this.router.url;

    // Define an array of routes in the desired sequence
    const routes = [
      '/overview-dashboard',
      '/pay-anyone-dashboard',
      '/login-dashboard',
      '/mobile-topup-dashboard',
      '/utility-bill-dashboard',
      '/progressive-insights-dashboard',
    ];

    // Find the index of the current route in the array
    const currentIndex = routes.indexOf(currentRoute);

    // Calculate the previous index considering the looping behavior
    const previousIndex = (currentIndex - 1 + routes.length) % routes.length;

    // Navigate to the previous route
    this.router.navigateByUrl(routes[previousIndex]);
  }

  scheduleNextDashboard() {
    // Set the interval to switch to the next dashboard every 15 seconds
    this.nextDashboardTimer = setInterval(() => {
      this.goToNext();
    }, 120000); // 20 seconds in milliseconds
  }

  ngOnDestroy(): void {
    // Clear the timer when the component is destroyed to prevent memory leaks
    clearInterval(this.nextDashboardTimer);
    clearInterval(this.updateInterval);
  }
}
