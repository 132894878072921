import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-progressive-and-ios-chart',
  templateUrl: './progressive-and-ios-chart.component.html',
  styleUrls: ['./progressive-and-ios-chart.component.scss'],
})
export class ProgressiveAndIosChartComponent implements OnInit {
  @Input() AndroidData: any;
  @Input() iosData: any;
  @Input() sentimentHistorydataAndroid: any;
  @ViewChild('doughnutChartRef') doughnutChartRef: ElementRef;
  constructor() {}

  ngOnInit(): void {
    if (this.AndroidData) {
      // Calculate the total sum
      const totalSum: any = Object.values(this.AndroidData).reduce(
        (sum, item: any) => sum + item.total,
        0
      );

      // Update the percentage and add remainingPercentage for each item in analysisData
      Object.values(this.AndroidData).forEach((item: any) => {
        item.percentage = Math.round((item.total / totalSum) * 100);
        //   this.totalReviews = totalSum;
      });
    }

    console.log('Android Data ==>', this.AndroidData);

    // if (this.iosData) {
    //   // Calculate the total sum
    //   const totalSum: any = Object.values(this.iosData).reduce(
    //     (sum, item: any) => sum + item.total,
    //     0
    //   );

    //   // Update the percentage and add remainingPercentage for each item in analysisData
    //   Object.values(this.iosData).forEach((item: any) => {
    //     item.percentage = Math.round((item.total / totalSum) * 100);
    //     //   this.totalReviews = totalSum;
    //   });
    // }

    // console.log('Ios Data ==>', this.iosData);
  }

  ngAfterViewInit(): void {
    this.createFullDoughnutChart(
      'doughnutChart',
      [
        this.AndroidData[0].percentage, // Positive
        this.AndroidData[1].percentage, // Neutral
        this.AndroidData[2].percentage, // Negative
      ],
      [
        '#7dd198', // Positive color
        '#adafb8', // Neutral color
        '#ce3632', // Negative color
      ],
      [
        '#7dd198', // Positive color
        '#adafb8', // Neutral color
        '#ce3632', // Negative color
      ]
    );
  }

  createFullDoughnutChart(
    chartId: string,
    data: number[],
    backgroundColor: string[],
    borderColor: string[]
  ) {
    const ctx = this.doughnutChartRef.nativeElement.getContext('2d');
    const fullDoughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Positive', 'Neutral', 'Negative'], // Example labels
        datasets: [
          {
            label: 'Android',
            data: data,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            hoverOffset: 0,
          },
        ],
      },
      options: {
        cutout: '75%', // Set cutout to '80%' for a specific cutout size
        aspectRatio: 1, // Ensure a square aspect ratio
        responsive: true, // Allow chart to be responsive
        maintainAspectRatio: true, // Maintain aspect ratio
        plugins: {
          legend: {
            display: false, // Hide the legend
          },
          tooltip: {
            // Your tooltip configuration
          },
        },
      },
    });
  }
}
