export const API_ENDPOINTS = {
  ONBOARDING: {
    SEND_OTP: 'api/v1/email/send-otp',
    VERIFY_OTP: 'api/v1/email/verify-otp',
    VERIFY_OTP_FOR_RESET_PASSWORD: 'api/v1/email/verify-otp-for-reset-password',
    SIGNUP: 'api/v1/sign-up',
    SIGNIN: 'api/v1/sign-in',
    FETCH_QUESTIONNAIRES: 'api/v1/onboarding/fetch-questionaires',
    FETCH_WORKSPACES_PROJECT: 'api/v1/user/invitation/details',
    SUBMIT_QUESTIONNAIRES: 'api/v1/onboarding/submit-questionaires',
    REQUEST_RESET_PASSWORD: 'api/v1/auth/request-password-reset',
    CREATE_NEW_PASSWORD: 'api/v1/auth/create-new-password',
    ACCEPT_INVITATION_WORKSPACES_PROJECT: 'api/v1/user/invitation/accept',
  },

  CCM: {
    CCM_FETCH_NOTIFICATIONS: 'api/v1/CCM/fetch-notifications',
    CREATE_TEAM: 'api/v1/CCM/createccmTeam',
    CREATE_CCM_COMPLAINT: 'api/v1/CCM/createccmComplaint',
    CCM_DASHBOARD_DATA: 'api/v1/ccm/CCMstats',
    CCM_DASHBOARD_LIST_DATA: 'api/v1/CCM/listDashboardComplaints',
    List_All_Teams: 'api/v1/CCM/get-teams-list',
    DELETE_TEAM_MEMBERS: 'api/v1/CCM/deleteTeamMember',
    DELETE_TEAM: 'api/v1/CCM/deleteCCMTeam',
    COMPLAINT_DETAILS: 'api/v1/CCM/complaintDetials',
    LIST_ALL_COMPLAINTS: 'api/v1/CCM/listAllComplaints',
    CCM_INIT: 'api/v1/ccm/CCMInit',
    UPDATE_CCM_TEAM: 'api/v1/CCM/editTeamInfo',
    INVITE_TEAM_MEMBER: 'api/v1/CCM/invite-team-member',
    GET_PARTIALS: 'api/v1/CCM/getPartials',
    SUBMIT_COMMENT: 'api/v1/CCM/submit-comment',
    FETCH_COMMENT: 'api/v1/CCM/fetch-comments',
    DELETE_COMMENT: 'api/v1/CCM/delete-comments',
    SEARCH_COMPLAINTS: 'api/v1/CCM/search-complaint',
    ASSIGN_COMPLAINTS: 'api/v1/CCM/assignComplaint',
    Next_COMPLAINT: 'api/v1/CCM/nextComplaint',
    CCM_READ_NOTIFICATIONS: 'api/v1/CCM/read-notifications',
  },

  AUTH: {
    TOKEN_REQ_URL: 'v2/user/request-token',
    ENCRYPT_URL: 'v2/encrypt',
  },

  HANDSHAKE: {
    TOKEN_REQ_URL: 'api/v1/client-handshake',
  },

  RESET_PASSWORD: {
    REQUEST_RESET_PASSWORD: 'api/v1/request-reset-password/',
    CREATE_NEW_PASSWORD: 'api/v1/create-new-password-for-web',
  },
  RESEND_EMAIL: {
    REQUEST_RESEND_EMAIL: 'api/v1/resend-email-verification/',
  },
  NOTIFICATIONS: {
    SEND_TOPIC: 'notification/sendToTopic',
  },

  USER: {
    SIGN_UP_CCM: 'api/v1/sign-up-ccm',
    VERIFY_LOGIN_CCM: 'api/v1/verify-ccm-user',
    REGISTER_CLIENT_URL: 'api/v1/sign-up',
    LOGIN_CLIENT_URL: 'api/v1/sign-in',
    GOOGLE_LOGIN_URL: 'api/v1/google/callback',
    APPLE_LOGIN_URL: 'api/v1/apple/callback',
    REGISTER_CLIENT_PROFILE_URL: 'v2/user/register-client-profile',
    PASSPORT_LOGIN: 'api/v1/passport-auth',
  },
  WORKSPACES: {
    USER_WORKSPACES_LIST: 'api/v1/user-workspaces',
    WORKSPACES_CREATE: 'api/v1/workspace/create',
    DELETE_WORKSPACE_BY_ID: 'api/v1/project/delete-workspace',
    UPDATE_WORKSPACE_DETAILS: 'api/v1/project/update-workspace-details',

    INVITE_MEMBER: 'api/v1/project/workspace-invite',
    FETCH_WORKSPACE_MEMBERS: 'api/v1/project/fetch-members',
    FETCH_PENDING_INVITED_MEMBERS: 'api/v1/project/pending-member',
    REMOVE_INVITE: 'api/v1/project/remove-invitee',
    FETCH_WORKSPACE_ROLE: 'api/v1/project/get-user-role-workspace',
    UPLOAD_AVATAR: 'api/v1/user/upload-workspace-avatar',
    REMOVE_MEMBERSHIP: 'api/v1/project/remove-membership',
    SEARCH_WORKSPACE: 'api/v1/workspaces/search',
    SEARCH_MEMBER: 'api/v1/project/search-member',
  },

  RESOLUTION_STATUS: {
    FETCH_RESOLUTION_STATUSES: 'api/v1/project/resolution-statuses',
    UPDATE_RESOLUTION_STATUS: 'api/v1/project/update-bug-status',
    UPDATE_CRASH_RESOLUTION_STATUS: 'api/v1/project/crash/crash-status-update',
    UPDATE_CRASH_PRIORITY: 'api/v1/project/crash/crash-priority-update',
  },
  ASSIGNEE: {
    FETCH_ASSIGNEE: 'api/v1/project/fetch-assignees',
    UPDATE_ASSIGNEE: 'api/v1/project/update-bug-assignee',
    UPDATE_CRASH_ASSIGNEE: 'api/v1/project/crash/crash-assignee-update',
  },
  PRIORITY: {
    UPDATE_BUG_PRIORITY: 'api/v1/project/update-bug-priority',
  },

  PROJECTS: {
    PROJECT_LIST_OWNED: 'api/v1/workspace/owned/project-list',
    PROJECT_LIST_SHARED: 'api/v1/workspace/shared/project-list',
    CREATE_PROJECT: 'api/v1/workspace/project/create',
    INTEGRATE_SDK: 'api/v1/project/update-operation-mode',
    PROJECT_DETAIL: 'api/v1/project/summary',
    PROJECT_DASHBOARD: 'api/v1/project/dashboard',
    PROJECT_DASHBOARD_FOR_WEB: 'api/v1/project/summary',
    PROJECT_DASHBOARD_GRAPHS: 'api/v1/project/dashboard-graph',
    PROJECT_BUG_STATS: 'api/v1/project/bug-total-stats',
    FECTCH_BUGS_FILTERS_LIST: 'api/v1/project/fetch-bug-filters',
    PROJECT_BUG_TEAM_PERFORMANCE: 'api/v1/project/team-performance',
    PROJECT_BUG_REPORT_STATS: 'api/v1/project/reported-bugs',
    PROJECT_BUG_LISTING: 'api/v1/project/bug-list',
    PROJECT_CRASH_LISTING: 'api/v1/project/crash-list',
    PROJECT_BUG_DETAILS: 'api/v1/project/bug-details',
    PROJECT_CRASH_DETAILS: 'api/v1/project/crash/crash-details',
    PROJECT_TAGS: 'api/v1/project/tags',
    PROJECT_STATUSES: 'api/v1/project/resolution-statuses',
    UPDATE_PROJECT_DETAILS: 'api/v1/project/update-project-details',
    UPLOAD_AVATAR: 'api/v1/user/upload-project-avatar',
    DELETE_PROJECT: 'api/v1/project/delete-project',
    FETCH_NOTIFICATIONS: 'api/v1/project/fetch-notifications',
    IS_SDK_INTEGRATED: 'api/v1/project/is-sdk-integrated',

    PROJECT_INVITE_MEMBER: 'api/v1/project/project-invite',
    PROJECT_USER_ROLE: 'api/v1/project/get-user-role-project',
    FETCH_MEMBERS: 'api/v1/project/fetch-members',
    UPDATE_MEMBER_ROLE: 'api/v1/project/update-workspace-member-role',

    FETCH_PROJECT_VERSIONS: 'api/v1/project/fetch-version',
    FETCH_FORCE_UPDATE_PROJECT_VERSION:
      'api/v1/project/fetch-force-update-version',
    SEARCH_PROJECT: 'api/v1/workspace/project/search',
  },

  OVERVIEW_DASHBOARD: {
    SENTIMENT_AND_APP_RATING: 'api/v1/project/overview/sentiment-analysis',
    TOP_RELEASES: 'api/v1/project/overview/topReleases',
    OPEN_BUGS: 'api/v1/project/overview/openBugs',
    OPEN_CRASHES: 'api/v1/project/overview/openCrashes',
    OS_AUDIENCE: 'api/v1/project/overview/osAudience',
    FETCH_SURVEYS_DATA: 'api/v1/project/overview/mostRecentSurveys',
  },

  BUGS: {
    BUG_SEARCH: 'api/v1/project/search-bugs',
    DELETE_BUG: 'api/v1/project/delete-bug',

    POST_COMMENT: 'api/v1/project/submit-comment',
    FETCH_COMMENT: 'api/v1/project/fetch-comments',
    DELETE_COMMENT: 'api/v1/project/delete-comments',
    UPDATE_COMMENT: 'api/v1/project/edit-comment',

    FETCH_BUG_FILTERS: 'api/v1/project/fetch-bug-filters',
    APPLY_BUG_FILTERS: 'api/v1/project/apply-bug-filters',

    BULK_OPERATIONS_FOR_BUG: 'api/v1/project/bulk-operation-for-bug',
    SEARCH_BUGS: 'api/v1/project/search-bugs',
  },

  CRASHES: {
    CRASHES_LIST_URL: 'api/v1/project/crash/crash-list',
    SINGLE_CRASH_URL: 'v2/crash/single',
    MATRICS_URL: 'v2/crash/matrics',
    CRASH_SEARCH: 'api/v1/project/crash/search-dashboard-by-device-id',
    CRASH_OCCURANCE: 'api/v1/project/crash-occurance-breakdown-by-device',
    SEARCH_CRASH_OCCURANCE:
      'api/v1/project/crash/search-occurrences-by-device-id',
    CRASHES_DASHBOARD_STATS: 'api/v1/project/crash/crash-stats',
    CRASH_FREE_SESSIONS: 'api/v1/project/crash/crash-free-sessions',
    CRASH_FREE_USERS: 'api/v1/project/crash/crash-free-users',
    CRASH_OCCURRENCES_BASED_ON_OS:
      'api/v1/project/crash/crash-occurrences-based-on-os',
    CRASH_OCCURRENCES_OVER_TIME:
      'api/v1/project/crash/crash-occurrence-over-time',
    OCCURRENCES_List:
      'api/v1/project/crash/crash-occurance-breakdown-by-device',
    OCCURRENCES_DETAIL: 'api/v1/project/crash/crash-occurance-detail',
    CRASH_FILTERS: 'api/v1/project/crash/fetch-crash-filters',
    OCCURRENCE_FILTERS: 'api/v1/project/crash/fetch-crash-occurrences-filter',
    APPLY_OCCURRENCE_FILTERS:
      'api/v1/project/crash/apply-crash-occurrence-filters',

    FETCH_FILTERS: 'api/v1/project/fetch-crash-filters  ',
    APPLY_FILTERS: 'api/v1/project/crash/apply-crash-filters',

    BULK_OPERATIONS_FOR_CRASH: 'api/v1/project/crash/bulk-operation-for-crash',
    DELETE_CRASH: 'api/v1/project/delete-crash',
  },

  ACCESS_CONTROL: {
    ROLES_LIST: 'api/v1/access/list-workspace-roles',
    FETCH_MEMEBERS: 'api/v1/access/list-workspace-members',
    DELETE_ROLE: 'api/v1/access/deleteRole',
    DELETE_PROJECT: 'api/v1/access/remove-project-member',

    PERMISSION_LIST: 'api/v1/access/available-role-permissions',
    VIEW_ROLE_PERMISSIONS: 'api/v1/access/enabled-role-permissions',
    UPDATE_CUSTOM_ROLE: 'api/v1/access/update_custom_role',
    CREATE_CUSTOM_ROLE: 'api/v1/access/create_custom_role',
    PROJECTS_LIST: 'api/v1/access/provisioned-member-and-limits',
    PROJECTS_INVITE: 'api/v1/access/project-invite',
    REVOKE_INVITE: 'api/v1/access/revoke-invite',
    PROJECT_ROLE_ACCESS: 'api/v1/project/projectRoleAccess',
  },
  INTEGRATION: {
    JIRA_BASIC_1ST_URL: 'v2/project/init-project-external-integration',
    JIRA_VERIFY_INTEGRATION_URL: 'v2/project/verify-integration',
  },
  PROFILE: {
    CHANGE_USER_NAME: 'api/v1/project/change-user-name',
    CHANGE_USER_PASSWORD: 'api/v1/change-password',
    UPLOAD_USER_PROFILE: 'api/v1/user/upload-profile-picture',
    CLOSE_ACCOUNT: 'api/v1/block-user',
  },

  VERSION: {
    FORCE_UPDATE_LISTING: 'api/v1/project/force-update-message-listing',
    SET_RELEASES_FORCE_UPDATE: 'api/v1/project/set-rlease-force-update',
    FETCH_VERSION: 'api/v1/project/fetch-version',
    DELETE_VERSION: 'api/v1/project/delete-version',
    FORCE_UPDATE_MESSAGE_TEMPLATE:
      'api/v1/project/force-update-message-template',
    DEACTIVATE_FORCE_UPDATE: 'api/v1/project/force-update-disable',
  },
  FEEDBACK_HUB: {
    FETCH_USER_FEEDBACK: 'api/v1/project/fetch-users-feedback',
    SETUP_FEEDBACK_ENGINER: 'api/v1/project/setup-feedback-engine',
    DISABLE_FEEDBACK_ENGINE: 'api/v1/project/disable-feedback-engine',
    FETCH_USER_FEEDBACK_GRAPH: 'api/v1/project/feedback-graph',
    FETCH_RATING: 'api/v1/project/fetch-rating',
  },
  SURVEY: {
    FETCH_SURVEY_LIST: 'api/v1/project/survey/listing',
    FETCH_SURVEY_FILTER: 'api/v1/project/survey/fetch-filters',
    FETCH_VERSIONS_LIST: 'api/v1/project/survey/available-version',
    FETCH_APP_RATING_DASHBOARD: 'api/v1/project/survey/reviews-stats',
    FETCH_APP_RATING_LIST_DATA: 'api/v1/project/survey/reviews-listing',
    FETCH_RATING_HISTORY_DATA: 'api/v1/project/survey/rating-history',
    FETCH_RESPONSE_HISTORY_DATA: 'api/v1/project/survey/reponse-history',
    UPDATE_SURVEY_STATUS: 'api/v1/project/survey/update-survey-status',
    DELETE_SURVEY: 'api/v1/project/survey/delete-survey',
    FETCH_REVIEWS_FILTER: 'api/v1/project/survey/fetch-reviews-filters',
    CREATE_SURVEY: 'api/v1/project/survey/create',
    APPLY_FILTERS: 'api/v1/project/survey/apply-filters',
    APPLY_REVIEWS_FILTERS: 'api/v1/project/survey/apply-reviews-filters',
    SEARCH_FILTERS: 'api/v1/project/survey/search',
    REVIEWS_SEARCH_FILTERS: 'api/v1/project/survey/reviews/search',
  },
  RELEASE_MANAGEMENT: {
    FETCH_RELEASE_MANAGEMENT: 'api/v1/project/releases-management',
    UPDATE_RELEASE: 'api/v1/project/update-releases',
    ENABLE_DISABLE_SDK: 'api/v1/project/sdk-monitoring',
    FETCH_RELEASE_NOTE: 'api/v1/project/fetch-release-note',
    ADD_RELEASE_NOTE: 'api/v1/project/add-release-note',
    ADD_SDK_RELEASE_NOTE: 'api/v1/project/add-sdk-release-note',
    FETCH_SDK_RELEASE_NOTE: 'api/v1/project/fetch-sdk-release-note',
    UPLOAD_MAPPING_FILE: 'api/v1/project/upload-release-mapping',
    SEARCH_SDK_RELEASE: 'api/v1/project/search-sdk-release',
    DEETE_RELEASE_MAPPING: 'api/v1/project/delete-release-mapping',
    SEARCH_RELEASE_MANAGEMENT: 'api/v1/project/search-release-version',
  },
  QUALITY_MANAGEMENT: {
    SUITE: {
      CREATE_TEST_SUITE: 'api/v1/project/create-test-suite',
      FETCH_TEST_SUITE: 'api/v1/project/fetch-test-suite',
      UPDATE_TEST_SUITE: 'api/v1/project/update-test-suite',
      DELETE_TEST_SUITE: 'api/v1/project/delete-test-suite',
      SEARCH_TEST_SUITE: 'api/v1/project/search-test-suite',
    },
    CASES: {
      CREATE_TEST_CASE: 'api/v1/project/create-test-case',
      FETCH_TEST_CASES: 'api/v1/project/fetch-test-case',
      SEARCH_TEST_CASE: 'api/v1/project/search-test-case',
      UPDATE_TEST_CASE: 'api/v1/project/update-test-case',
      DELETE_TEST_CASE: 'api/v1/project/delete-test-case',
      TEST_CASE_SEARCH: 'api/v1/project/search-test-case',
    },
    RUNS: {
      CREATE_TEST_RUN: 'api/v1/project/create-test-run',
      FETCH_TEST_RUNS: 'api/v1/project/fetch-test-run',
      UPDATE_TEST_RUNS_STATUS: 'api/v1/project/update-test-run-status',
      DELETE_TEST_RUN: 'api/v1/project/delete-test-run',
      TEST_RUN_DASHBOARD: 'api/v1/project/test-run-dashboard',
      UPDATE_TEST_CASE_STATUS: 'api/v1/project/update-test-case-status',
      SEARCH_TEST_RUN: 'api/v1/project/search-test-run',
    },
  },
  DISTRIBUTION_MANAGEMENT: {
    TESTERS: {
      CREATE_TESTER: 'api/v1/project/create-tester',
      FETCH_TESTER: 'api/v1/project/fetch-tester',
      FETCH_TESTER_WITHOUT_RELEASE: 'api/v1/project/testers-without-release',
      UPDATE_TESTER_DETAILS: 'api/v1/project/update-tester-name',
      DELETE_TESTER: 'api/v1/project/delete-tester',
      SEARCH_TESTER: 'api/v1/project/search-tester',
      REMOVE_TESTER_FROM_GROUP: 'api/v1/project/remove-tester-from-group',
    },
    GROUPS: {
      CREATE_GROUP: 'api/v1/project/create-group',
      FETCH_GROUP: 'api/v1/project/fetch-groups',
      UPDATE_GROUP_DETAILS: 'api/v1/project/update-group-details',
      DELETE_GROUP: 'api/v1/project/delete-group',
      SEARCH_GROUP: 'api/v1/project/search-groups',
    },
    UPLOAD_APK_RELEASE: 'api/v1/project/upload-apk-release',
    REMOVE_TESTER: 'api/v1/project/remove-tester-in-release',
    ADD_TESTER_TO_RELEASE: 'api/v1/project/add-tester-to-release',
    ADD_GROUP_TO_RELEASE: 'api/v1/project/add-group-to-release',
    FETCH_RELEASES: 'api/v1/project/fetch-releases',
    FETCH_RELEASES_DETAILS: 'api/v1/project/fetch-release-details',
    DELETE_APK_RELEASE: 'api/v1/distribution/delete/release',
    VERIFY_APK_RELEASE: 'api/v1/distribution/verify-release-file',
  },
  AI_INSIGHT: {
    FEEDBACK_iNSIGHTS: {
      IS_SDK_INTEGRATED: 'api/v1/review/isSdkIntegrated',
      GET_FEEDBACK_INSIGHTS_DATA: 'api/v1/review/list-feedback-reviews-report',
    },
    SENTIMENT_ANALYSIS: {
      GET_REPORT: 'api/v1/review/submit-app-url',
      REPORT_STATUS: 'api/v1/review/report-status/',
      GET_SENTIMENT_ANALYSIS_DETAILS: 'api/v1/review/list-report',
      GET_HISTORICAL_DATA: 'api/v1/review/historical-data',
    },
    API_INTEGRATION: {
      GET_REPORT: 'api/v1/review/link_app_reviews_store',
      REPORT_STATUS: 'api/v1/review/linked-app-report-status/',
      GET_API_INTEGRATION_DATA: 'api/v1/review/list-linked-review-report',
      CHECK_DASHBOARD_DATA: 'api/v1/review/project-manifests',
    },
  },
  DASHBOARD: {
    GET_JOURNEY_DATA: 'api/v1/project/netstat/journey/dashboard',
    GET_OVERVIEW_DATA: 'api/v1/project/netstat/overview/dashboard',
  },
  EPICNAME: {
    BUGS: 'Bugs',
    CRASHES: 'Crashes',
    VERSION_HISTORY: 'Version history',
    FORCE_UPDATE: 'Force update',
    SURVEY: 'Survey',
    INSTANT_INSIGHT: 'Instant insights',
    DASHBOARD: 'Dashboard',
    PROGRESSIVE_INSIGHTS: 'Progressive insights',
    ACCESS_CONTROL: 'Users access and role management',
  },
};
