import { Component, Input, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-service-line-chart4',
  templateUrl: './service-line-chart4.component.html',
  styleUrls: ['./service-line-chart4.component.scss'],
})
export class ServiceLineChart4Component implements OnInit {
  @Input() recievedChartData: any;
  @Input() recievedChartName: any;

  constructor() {}

  ngOnInit(): void {
    this.createLineChart();
  }

  createLineChart(): void {
    const ctx = document.getElementById(
      'service-line-chart4'
    ) as HTMLCanvasElement;

    const ctx2D = ctx.getContext('2d');
    const gradient = ctx2D.createLinearGradient(0, 0, 0, ctx.height);
    gradient.addColorStop(0, '#33d490');
    gradient.addColorStop(0.5, '#fcd087');
    gradient.addColorStop(1, '#ce3632');

    const labels = Object.keys(this.recievedChartData);
    const data = Object.values(this.recievedChartData).map((value: string) =>
      parseFloat(value)
    );

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: this.recievedChartName,
            data: data,
            borderColor: gradient, // Use a single color for borderColor
            borderWidth: 4,
            pointBorderWidth: 0,
            fill: false,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            intersect: false,
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
            grid: {
              display: false,
            },
            ticks: {
              padding: 0,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
            },
            min: 0,
            max: 100,
            grid: {
              display: true,
              color: '#ececec',
            },
            ticks: {
              stepSize: 25,
              font: {
                size: 12,
              },
              color: '#777777',
              callback: function (value: number) {
                return value + '%';
              },
              padding: 0,
            },
          },
        },
        layout: {
          padding: {
            left: -20,
            bottom: -20,
          },
        },
      },
    });
  }
}
