import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';

// components
import { SigninComponent } from './epics/Account/signin/signin.component';
import { SignupComponent } from './epics/Account/signup/signup.component';
import { ConfirmemailComponent } from './epics/Account/confirmemail/confirmemail.component';
import { CreateWorkspaceComponent } from './epics/fusion/Pages/Workspace/create-workspace/create-workspace.component';
import { SelectWorkspaceComponent } from './epics/fusion/Pages/Workspace/select-workspace/select-workspace.component';
import { LoaderComponent } from 'src/app/shared/shared-components/loader/loader.component';
import { ConfirmationDialogComponent } from './shared/shared-components/confirmation-dialog/confirmation-dialog.component';
import { UpgradeDialogComponent } from './shared/shared-components/upgrade-dialog/upgrade-dialog.component';
// services
import { AuthGuardService } from './core/services/auth/auth-guard.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptorService } from './core/interceptors/eror-interceptor.service';
import { TokenInterceptor } from './core/interceptors/token-interreceptor.service';
import { AuthenticationService } from './core/services/auth/authentication.service';
import { TokenService } from './core/authentication/token.service';
import { EncryptionService } from './core/authentication/encryption-service';
import { CommonService } from './core/services/common/common.service';
import { DashboardService } from './core/services/workspace/dashboard.service';
import { WorkspaceService } from './core/services/workspace/workspace.service';
import { HelperService } from './core/services/helper/helper.service';
import { ProjectService } from './core/services/project/project.service';
import { BugService } from './core/services/bug/bug.service';
import { ProfileService } from './core/services/profile/profile.service';
import { ResetpasswordComponent } from './epics/Account/ResetPassword/resetpassword/resetpassword.component';
import { CreatepasswordComponent } from './epics/Account/createPassword/createpassword/createpassword.component';
import { BillingService } from './core/services/billing/billing.service';
import { CheckoutService } from './core/services/payment/checkout.service';
import { DistributionComponent } from './epics/fusion/Pages/Distribution/distribution/distribution.component';
import { QualityManagementService } from './core/services/quality-management/quality-management.service';
import { ActivationComponent } from './epics/Account/Activation/activation/activation.component';
import { EmptyContainerComponent } from './shared/shared-components/empty-container/empty-container.component';
import { HeaderContainerComponent } from './shared/shared-components/header-container/header-container.component';
import { AiInsightService } from './core/services/ai-insight/ai-insight.service';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthenticationModule } from './core/authentication/authentication.module';
import { CCMLoginComponent } from './epics/Account/ccm-login/ccm-login.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignupComponent,
    ConfirmemailComponent,
    CreateWorkspaceComponent,
    SelectWorkspaceComponent,
    ResetpasswordComponent,
    CreatepasswordComponent,
    LoaderComponent,
    ActivationComponent,
    CCMLoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    EmptyContainerComponent,
    MatInputModule,
    MatCheckboxModule,
    MatTabsModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    ConfirmationDialogComponent,
    HeaderContainerComponent,
    UpgradeDialogComponent,
    AuthenticationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
  ],

  exports: [SigninComponent, SignupComponent],

  providers: [
    AuthenticationService,
    TokenService,
    EncryptionService,
    CommonService,
    HelperService,
    DashboardService,
    WorkspaceService,
    ProjectService,
    BugService,
    ProfileService,
    BillingService,
    CheckoutService,
    AiInsightService,
    DistributionComponent,
    QualityManagementService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
