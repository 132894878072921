import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-journey-health',
  templateUrl: './journey-health.component.html',
  styleUrls: ['./journey-health.component.scss'],
})
export class JourneyHealthComponent implements OnInit {
  constructor() {}
  @Input() percentage: any;
  @Input() journeyName: string = '';
  batteryWidth: number = 370;
  batteryHeight: number = 98;
  ngOnInit(): void {
    if (this.percentage === 0) {
      this.percentage = 1;
    }
  }
  getBatteryColor(): string {
    if (this.journeyName === 'Loading data for graph..') {
      return '#e3e3e3'; // Set color to grey when percentage is 'Data is loading'
    } else if (this.percentage <= 90) {
      return '#fcd087';
    } else if (this.percentage <= 80) {
      return '#fcd087';
    } else {
      return '#7dd198';
    }
  }

  getTextColor(): string {
    return this.percentage <= 50 ? 'black' : 'white';
  }

  calculateMarginLeft(): number {
    if (
      this.percentage == 10 ||
      this.percentage == 9 ||
      this.percentage == 8 ||
      this.percentage == 7
    ) {
      return 400;
    } else if (this.percentage == 6) {
      // Define margin left value for the range 11-20
      return 500;
    } else if (this.percentage == 5) {
      // Define margin left value for the range 11-20
      return 600;
    } else if (this.percentage == 4) {
      // Define margin left value for the range 11-20
      return 700;
    } else if (this.percentage == 3) {
      // Define margin left value for the range 11-20
      return 800;
    } else if (this.percentage == 2) {
      // Define margin left value for the range 11-20
      return 1200;
    } else if (this.percentage == 1) {
      // Define margin left value for the range 11-20
      return 2000;
    } else if (this.percentage > 10 && this.percentage <= 20) {
      // Define margin left value for the range 11-20
      return 300;
    } else if (this.percentage > 21 && this.percentage <= 30) {
      // Define margin left value for the range 11-20
      return 250;
    } else if (this.percentage > 31) {
      // Define margin left value for the range 11-20
      return 0;
    } else if (this.percentage == 0) {
      // Define margin left value for the range 11-20
      return 800;
    }

    // Add more conditions for other ranges as needed
    else {
      return 0; // Default value
    }
  }
}
