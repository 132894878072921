import { Component, Input, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
@Component({
  selector: 'app-radar-graph',
  templateUrl: './radar-graph.component.html',
  styleUrls: ['./radar-graph.component.scss'],
})
export class RadarGraphComponent implements OnInit {
  myRadarChart: any;
  @Input() recievedAndroidRadarData: any;

  constructor() {}

  ngOnInit(): void {
    this.createRadarChart();
    console.log('Recieved Android Radar Data', this.recievedAndroidRadarData);
  }

  createRadarChart() {
    const ctx = document.getElementById('myRadarChart') as HTMLCanvasElement;
    const radarData = this.recievedAndroidRadarData;

    const labels = Object.keys(radarData);
    const data = Object.values(radarData).map((value: string) =>
      parseFloat(value)
    );

    this.myRadarChart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Dataset',
            data: data,
            backgroundColor: 'rgba(181, 206, 252, 0.5)',
            borderColor: 'rgba(181, 206, 252, 0.5)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false, // Remove the legend
          },
        },
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 100,
            backgroundColor: '#f7f7f7',
            ticks: {
              stepSize: 25,
              backdropColor: '#f7f7f7',
              callback: function (value: number) {
                return value + '%';
              },
            },
          },
        },
      },
    });
  }
}
