<div class="multi-progress-bar" *ngIf="showProgressLine() && !apiDashboard">
  <ng-container *ngFor="let progress of progressArray">
    <div
      class="progress-bar"
      [ngClass]="{ 'min-width-7': progress.value >= 1 && progress.value <= 7 }"
      [style.width.%]="progress.value"
      [style.background]="progress.color"
      *ngIf="progress.value !== 0"
    >
      <span class="black bold ml-0">{{ progress.value }}%</span>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="apiDashboard">
  <div class="multi-progress-bar">
    <ng-container *ngFor="let dashboard of dashboardArray">
      <div
        class="progress-bar h-7"
        [ngClass]="{
          'min-width-7': dashboard.value >= 1 && dashboard.value <= 7
        }"
        [style.width.%]="dashboard.value"
        [style.background]="dashboard.color"
        *ngIf="dashboard.value !== 0"
      >
        <span class="dull-black ml-0">{{ dashboard.value }}%</span>
      </div>
    </ng-container>
  </div>
</ng-container>
