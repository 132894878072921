<div class="route-path-guide">
  <div class="status">
    <img class="icon-img" src="../../../../../../assets/icons/Ellipse 27.svg" />
    <div class="status-content">Screens</div>
  </div>
  <div class="status">
    <img src="../../../../../../assets/icons/Forward Flow.svg" />
    <div class="status-content">Forward Flow</div>
  </div>
  <div class="status">
    <img src="../../../../../../assets/icons/Backward Flow.svg" />
    <div class="status-content">Backward Flow</div>
  </div>
</div>
