<div class="content-wrapper mt-20">
  <div class="android-section">
    <p class="sub-heading bold">Android</p>
    <div class="android-wrapper mt-24">
      <div class="android-left mr-16">
        <div>
          <canvas
            #doughnutChartRef
            style="width: 168px; height: 168px"
          ></canvas>
        </div>
        <div class="content-container">
          <div class="content-wrapper">
            <div class="positive-line"></div>
            <div class="content">
              <p class="sub-heading bold h-1">
                {{ AndroidData[0].percentage }}%
              </p>
              <p class="mini-desc-grey f-16">Positive</p>
            </div>
          </div>
          <div class="content-wrapper mt-16">
            <div class="neutral-line"></div>
            <div class="content">
              <p class="sub-heading bold h-1">
                {{ AndroidData[1].percentage }}%
              </p>
              <p class="mini-desc-grey f-16">Neutral</p>
            </div>
          </div>
          <div class="content-wrapper mt-16">
            <div class="negative-line"></div>
            <div class="content">
              <p class="sub-heading bold h-1">
                {{ AndroidData[2].percentage }}%
              </p>
              <p class="mini-desc-grey f-16">Negative</p>
            </div>
          </div>
        </div>
      </div>
      <div class="android-right">
        <div class="top-wrapper">
          <p class="sub-heading bold">Sentiment History</p>
          <div class="right-wrapper">
            <img
              class="icon"
              src="../../../../../../../assets/icons/success-dot.svg"
              alt=""
            />
            <p class="medium-desc f-12 bold mr-16">Positive</p>

            <img
              class="icon"
              src="../../../../../../../assets/icons/neutral-dot.svg"
              alt=""
            />
            <p class="medium-desc f-12 bold mr-16">Neutral</p>

            <img
              class="icon"
              src="../../../../../../../assets/icons/critical-dot.svg"
              alt=""
            />
            <p class="medium-desc f-12 bold">Negative</p>
          </div>
        </div>
        <div class="mt-24">
          <app-sentiment-history-chart
            [recievedSentimentHistoryData]="sentimentHistorydataAndroid"
          ></app-sentiment-history-chart>
        </div>
      </div>
    </div>
  </div>
</div>
