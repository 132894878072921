import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-network-issues-chart',
  templateUrl: './network-issues-chart.component.html',
  styleUrls: ['./network-issues-chart.component.scss'],
})
export class NetworkIssuesChartComponent implements AfterViewInit {
  @Input() data;
  @Input() chartId: string;

  @ViewChild('chartCanvas') chartCanvas: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    this.createChart();
  }

  createChart(): void {
    const label = this.data.map((res) => res.api_response_code);
    const data = this.data.map((res) => res.total_events);

    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    if (!ctx) {
      console.error('Failed to get 2D context from canvas element');
      return;
    }

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: label,
        datasets: [
          {
            label: 'Number of Issues',
            data: data,
            backgroundColor: '#6b9cf9',
            barThickness: 8,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false, // Hide legend
          },
        },
        scales: {
          x: {
            grid: {
              display: false, // Hide x-axis grid lines
            },
            ticks: {
              display: true, // Hide x-axis labels
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                size: 12, // Set font size
              },
              color: '#777777',
            },
            grid: {
              display: true, // Show y-axis grid lines
            },
          },
        },
        layout: {
          padding: {
            left: 0, // Adjust left padding
            right: 0, // Adjust right padding
          },
        },
        elements: {
          bar: {
            borderWidth: 0, // Remove border
            borderRadius: 4, // Set border radius
          },
        },
      },
    });
  }
}
