import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-api-call-cards',
  templateUrl: './api-call-cards.component.html',
  styleUrls: ['./api-call-cards.component.scss'],
})
export class ApiCallCardsComponent implements OnInit {
  @Input() componentName: string;
  @Input() dashboardData;
  projectSpecificAPITrends = [];

  constructor() {}

  ngOnInit(): void {
    this.projectSpecificAPITrends = this.dashboardData;
  }

  journeyName(endpoint) {
    switch (this.componentName) {
      case 'Pay Anyone':
        return this.payAnyoneJourney(endpoint);
      case 'Signin':
        return this.loginJourney(endpoint);
      case 'Utility Bill':
        return this.utilityBillJourney(endpoint);
      case 'Mobile Top-up':
        return this.mobileTopUpJourney(endpoint);
      default:
        return '';
    }
  }

  mobileTopUpJourney(endpoint: string): string {
    // console.log('endpoint______', endpoint);
    switch (endpoint) {
      case 'GetBillCompaniesDetail':
        return 'mobile-topup-card-2';
      case 'LoadPrepaidService':
        return 'mobile-topup-card-1';
      case 'AddBillCompanies':
        return 'mobile-topup-card-7';
      case 'payAllBillConfirmSubmit':
        return 'mobile-topup-card-8';
      case 'DeleteBills':
        return 'mobile-topup-card-4';
      case 'GenerateOTPForAddition':
        return 'mobile-topup-card-5';
      case 'UpdateBills':
        return 'mobile-topup-card-3';
      case 'ScheduleGetPaytoAccount':
        return 'mobile-topup-card-6';
      default:
        return 'dashboard-card-none';
    }
  }

  utilityBillJourney(endpoint: string): string {
    // console.log('Test Endpoints _______', endpoint);
    let className = '';
    switch (endpoint) {
      case 'DetailUtilityBillLoadData':
        className = 'utility-bill-card-1';
        break;
      case 'AddUtilityCompanies':
        className = 'utility-bill-card-4';
        break;

      case 'UpdateBills':
        className = 'utility-bill-card-5';
        break;

      case 'DetailUtilityBillLoadData':
        className = 'utility-bill-card-4';
        break;

      case 'payAllBillConfirmSubmit':
        className = 'utility-bill-card-7';
        break;

      case 'DeleteBills':
        className = 'utility-bill-card-6';
        break;

      case 'GenerateOTPForAddition':
        className = 'utility-bill-card-2';
        break;
      case 'GetBillOverLoad':
        className = 'utility-bill-card-8';
        break;

      //   case 'DetailUtilityBillLoadData':
      //     className = 'utility-bill-card-8';
      default:
        className = 'dashboard-card-none';
    }
    // console.log('Test Class Name _____', className);
    return className;
  }

  loginJourney(endpoint: string): string {
    switch (endpoint) {
      case 'GetUserGoGreenStatus':
        return 'login-dashboard-card-5';
      case 'DisplayGetLastDrCrTransHistory':
        return 'login-dashboard-card-11';
      case 'AccountSummaryV2':
        return 'login-dashboard-card-9';
      case 'mobSignInRooted':
        return 'login-dashboard-card-2';
      case 'ExistingUserMobRegRooted':
        return 'login-dashboard-card-1';
      case 'VerifyOTPForDeviceRegistration':
        return 'login-dashboard-card-6';
      case 'GenerateOTPForDeviceRegistration':
        return 'login-dashboard-card-3';
      case 'MoveBIORequestToBranch':
        return 'login-dashboard-card-10';
      case 'BioVeriSysPOBM':
        return 'login-dashboard-card-7';
      case 'UserMobileDeviceBinding':
        return 'login-dashboard-card-4';
      case 'UpdateUserGoGreenStatus':
        return 'login-dashboard-card-8';
      default:
        return 'dashboard-card-none';
    }
  }

  payAnyoneJourney(endpoint: string): string {
    switch (endpoint) {
      case 'TitlefetchSOA':
        return 'pay-anyone-card-1';
      case 'AddAccountTitleFetch':
        return 'pay-anyone-card-2';
      case 'getIBANTitle':
        return 'pay-anyone-card-3';
      case 'AddA2pBeneficiaryInquiry':
        return 'pay-anyone-card-4';
      case 'LoadPayeeList':
        return 'pay-anyone-card-5';
      case 'payConfirmSubmit':
        return 'pay-anyone-card-6';
      case 'GenerateOTPForAddition':
        return 'pay-anyone-card-7';
      case 'PayAccount':
        return 'pay-anyone-card-8';
      case 'LoadBranches':
        return 'pay-anyone-card-10';
      case 'GetPayDetails':
        return 'pay-anyone-card-11';
      case 'payConfirmSubmit':
        return 'pay-anyone-card-12';
      default:
        return 'dashboard-card-none';
    }
  }
}
