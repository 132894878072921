import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-system-stability-chart',
  templateUrl: './system-stability-chart.component.html',
  styleUrls: ['./system-stability-chart.component.scss'],
})
export class SystemStabilityChartComponent implements OnInit, AfterViewInit {
  @Input() data;
  @Input() recievedChartName: string;
  dateData: { date: string }[] = [];
  @ViewChild('chartCanvas1') chartCanvas1: ElementRef;
  @ViewChild('chartCanvas2') chartCanvas2: ElementRef;
  @ViewChild('chartCanvas3') chartCanvas3: ElementRef;
  @ViewChild('chartCanvas4') chartCanvas4: ElementRef;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.recievedChartName === 'system-stability-line-chart1') {
      this.createLineChart(
        this.chartCanvas1.nativeElement,
        'system-stability-line-chart1'
      );
    }

    if (this.recievedChartName === 'system-stability-line-chart2') {
      this.createLineChart(
        this.chartCanvas2.nativeElement,
        'system-stability-line-chart2'
      );
    }
    if (this.recievedChartName === 'system-stability-line-chart3') {
      this.createLineChart(
        this.chartCanvas3.nativeElement,
        'system-stability-line-chart3'
      );
    }
    if (this.recievedChartName === 'system-stability-line-chart4') {
      this.createLineChart(
        this.chartCanvas4.nativeElement,
        'system-stability-line-chart4'
      );
    }
  }

  createLineChart(canvas: HTMLCanvasElement, chartId: string): void {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, '#33d490');
    gradient.addColorStop(0.5, '#fcd087');
    gradient.addColorStop(1, '#ce3632');

    const labels = this.data.map((obj) =>
      this.datePipe.transform(new Date(obj.summary_date), 'MMM dd')
    );
    const chartData = this.data.map((obj) => obj.success_rate);

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'System Stability',
            data: chartData,
            borderColor: gradient,
            borderWidth: 4,
            pointBorderWidth: 0,
            fill: false,
          },
        ],
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: { mode: 'index', intersect: false },
        },
        elements: { line: { tension: 0.4 }, point: { radius: 0 } },
        scales: {
          x: {
            display: true,
            title: { display: true },
            grid: { display: false },
            ticks: { padding: 0 },
          },
          y: {
            display: true,
            title: { display: true },
            min: 0,
            max: 100,
            grid: { display: true },
            ticks: {
              stepSize: 25,
              font: { size: 12 },
              color: '#777777',
              callback: (value) => value + '%',
              padding: 0,
            },
          },
        },
        layout: { padding: { left: -20, bottom: 0 } },
      },
    });
  }
}
