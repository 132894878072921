import { HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { CommonService } from 'src/app/core/services/common/common.service';
import { DistributionManagementService } from 'src/app/core/services/distribution-management/distribution-management.service';
import { QuillModules } from 'ngx-quill';

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss'],
})
export class DistributionComponent implements OnInit {
  showFilterContainer: boolean = false;
  sidePanelOpen: boolean = false;
  isShowRelease: boolean = false;

  public showDistribution: boolean = false;
  tab: number = 1;
  keyFileName: string = '';
  releaseNotes = '';
  formData: FormData;
  progress: number = 0;
  releasesList: any = []; // List of Releases
  searchTerm$ = new Subject<string>();
  searchText: string = '';
  totalPages: number = 0;
  currentPage: number = 1;
  apkError: boolean;
  presignedURL: string = '';
  selectedFile: File | null = null;
  droppedFiles: File[] = [];
  public quillModules: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // Include only the desired options
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  @ViewChild('file') file;
  uploadPercentage: number = 0;
  showUploadValue: boolean = false;
  showVerifyUploadMessage: string = 'APK uploaded, please wait ...';
  showMessage: boolean = false;

  constructor(
    private router: Router,
    public commonService: CommonService,
    private _DMService: DistributionManagementService
  ) {}

  ngOnInit(): void {
    this.onFetchReleases();
    let data = JSON.parse(localStorage.getItem('selectedProject') || '{}');
    this.commonService.selectedProject = data;
    this.formData = new FormData();
    this.searchTerm$
      .pipe(
        debounceTime(500) // delay search by 500ms
      )
      .subscribe((data) => {
        if (this.searchText !== '') {
          this.onSearch();
        }
      });
  }

  onSearch() {}

  onKeyUp() {
    if (this.searchText !== '') {
      this.searchTerm$.next(this.searchText);
    } else {
      this.onFetchReleases();
    }
  }

  onSelectTab(num: any, name: string) {
    this.tab = num;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.currentPage += 1;
      if (this.currentPage <= this.totalPages) this.onFetchReleases();
    }
  }

  stripPTags(input: string): string {
    if (!input) {
      return input;
    }
    // Remove <p> tags from the start and end of the string
    const regex = /^<p>|<\/p>$/gi;
    return input.replace(regex, '');
  }

  onFetchReleases() {
    let body = {
      payload: JSON.stringify({
        workspace_kuid: JSON.parse(
          localStorage.getItem('lastVisited_workspace') || '{}'
        ).ws_kuid,
        project_kuid: JSON.parse(
          localStorage.getItem('selectedProject') || '{}'
        ).p_kuid,
        pg: this.currentPage,
      }),
    };
    this.commonService.isLoading = true;
    this._DMService.FetchReleases(body).subscribe((res) => {
      this.totalPages = res.totalPages;
      this.currentPage = res.currentPage;
      this.releasesList = res.fetchReleases;
      this.commonService.isLoading = false;
    });
  }
  isShowFiltercontainer() {
    this.showFilterContainer = !this.showFilterContainer;
  }

  showDetails(item: any) {
    this.router.navigate(['project/distribution/releases/view-details'], {
      queryParams: {
        id: item.releaseKuid,
      },
    });
  }

  showShare() {
    this.sidePanelOpen = true;
  }

  onCreateRelease() {
    this.getURL();
    this.isShowRelease = !this.isShowRelease;
  }

  getURL() {
    let body = {
      payload: JSON.stringify({
        workspace_kuid: JSON.parse(
          localStorage.getItem('lastVisited_workspace') || '{}'
        ).ws_kuid,
        project_kuid: JSON.parse(
          localStorage.getItem('selectedProject') || '{}'
        ).p_kuid,
      }),
    };
    this._DMService.getS3URL(body).subscribe(
      (res) => {
        this.presignedURL = res.url;
        this.keyFileName = res.key;
        if (this.presignedURL) {
          this._DMService.sendPresignedURL(this.presignedURL);
        } else {
          this.commonService.showError('Presigned URL not found', '');
        }
      },
      (err) => {
        this.commonService.showError('Something went wrong', '');
      }
    );
  }

  uploadFile() {
    if (this.selectedFile) {
      this.apkError = false;
      this.commonService.isLoading = true;
      // Initialize variables for chunked upload
      const chunkSize = 1024 * 1024; // 1MB chunk size
      const totalChunks = Math.ceil(this.selectedFile.size / chunkSize);
      let currentChunk = 0;
      const uploadNextChunk = () => {
        const start = currentChunk * chunkSize;
        const end = Math.min(start + chunkSize, this.selectedFile.size);
        const chunk = this.selectedFile.slice(start, end);
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('totalChunks', totalChunks.toString());
        formData.append('currentChunk', currentChunk.toString());
        // Send chunk to server
        this._DMService.uploadChunk(this.presignedURL, formData).subscribe(
          () => {
            this.showUploadValue = true;
            currentChunk++;
            this.uploadPercentage = Math.round(
              (currentChunk / totalChunks) * 100
            ); // Calculate upload percentage
            if (currentChunk < totalChunks) {
              // Upload next chunk
              uploadNextChunk();
            } else {
              // All chunks uploaded successfully, call verifyRelease
              this.showUploadValue = false;
              this.showMessage = true;
              this.verifyRelease(this.keyFileName, this.releaseNotes);
            }
          },
          (error) => {
            this.commonService.showError('Something went wrong', '');
          }
        );
      };
      // Start uploading chunks
      uploadNextChunk();
    } else {
      this.apkError = true;
    }
  }
  verifyRelease(key, releaseNotes) {
    const releaseNotesWithoutTagP = this.stripPTags(releaseNotes);
    let body = {
      payload: JSON.stringify({
        workspace_kuid: JSON.parse(
          localStorage.getItem('lastVisited_workspace') || '{}'
        ).ws_kuid,
        project_kuid: JSON.parse(
          localStorage.getItem('selectedProject') || '{}'
        ).p_kuid,
        key: key,
        release_notes: releaseNotesWithoutTagP,
      }),
    };
    this._DMService.verifyAPK(body).subscribe(
      (res) => {
        this.commonService.isLoading = false;
        this.isShowRelease = false;
        this.currentPage = 1;
        this.onFetchReleases();
        this.closeModal();
        this.showMessage = false;
        this.commonService.showSuccess('APK is under verification', '');
      },
      (err) => {
        this.commonService.showError('Something went wrong', '');
        this.showUploadValue = false;
        this.showMessage = false;
      }
    );
  }

  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    this.handleFiles(event.dataTransfer.files);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  private handleFiles(fileList: FileList): void {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file) {
        this.droppedFiles.push(file);
      }
      this.selectedFile = this.droppedFiles[0];
    }
  }

  upload(event: any) {
    this.selectedFile = event.target.files[0];
  }

  //   UploadAPKToServer() {
  //     if (this.formData.has('application')) {
  //       this.commonService.isLoading = true;
  //       this.formData.delete('payload');
  //       this.formData.append(
  //         'payload',
  //         JSON.stringify({
  //           workspace_kuid: JSON.parse(
  //             localStorage.getItem('lastVisited_workspace') || '{}'
  //           ).ws_kuid,
  //           project_kuid: JSON.parse(
  //             localStorage.getItem('selectedProject') || '{}'
  //           ).p_kuid,
  //           note: this.releaseNotes,
  //         })
  //       );
  //       this._DMService.UploadAPK(this.formData).subscribe((res) => {
  //         this.router.navigate(['project/distribution/releases/view-details'], {
  //           queryParams: {
  //             id: res.payload.release_kuid,
  //           },
  //         });
  //         this.commonService.isLoading = false;
  //         this.isShowRelease = false;
  //         this.onFetchReleases();
  //       });
  //     } else {
  //       this.apkError = true;
  //     }
  //   }
  deleteApk(releaseKuid: any) {
    this._DMService
      .deleteApk(releaseKuid)
      .pipe(switchMap(() => this._DMService.FetchReleases(this.currentPage)))
      .subscribe(
        (res) => {
          if (res) {
            this.commonService.showSuccess(
              'Success',
              'Apk file deleted successfully'
            );
            this.totalPages = res.totalPages;
            this.currentPage = res.currentPage;
            this.releasesList = [...res.fetchReleases];
          }
        },
        (error) => {
          this.commonService.showError(
            error.message.title,
            error.message.description
          );
        }
      );
  }

  closeModal() {
    this.selectedFile = null;
    this.file.nativeElement.value = '';
    this.releaseNotes = '';
    this.isShowRelease = false;
    this.apkError = false;
  }
}
