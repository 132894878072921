import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-failure-line',
  templateUrl: './success-failure-line.component.html',
  styleUrls: ['./success-failure-line.component.scss'],
})
export class SuccessFailureLineComponent implements OnInit {
  @Input() successValue: number = 0;
  @Input() failureValue: number = 0;
  @Input() data;
  valuesArray: any = [];

  constructor() {}

  ngOnInit(): void {
    console.log('line-data', this.data);
    this.valuesArray = [
      { value: this.data.success_rate, class: 'success-line' },
      { value: this.data.fail_rate, class: 'validation-line' },
      { value: this.data.tcp_fail_rate, class: 'failure-line' },
    ];
    console.log('valuesArray', this.valuesArray);
  }
}
