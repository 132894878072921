<div class="success-status-guide">
  <div class="status">
    <div class="success-dot-6px"></div>
    <div class="status-content">Success</div>
  </div>
  <div class="status">
    <div class="normal-dot-6px"></div>
    <div class="status-content">Validation error</div>
  </div>
  <div class="status">
    <div class="critical-dot-6px"></div>
    <div class="status-content">Network failure</div>
  </div>
</div>
