<div class="page-wrapper">
  <div class="top-wrapper">
    <p class="sub-heading bold">Services Health</p>
    <div class="right-wrapper">
      <img
        class="icon"
        src="../../../../../../../assets/icons/success-dot.svg"
        alt=""
      />
      <p class="medium-desc f-12 bold mr-16">Good</p>

      <img
        class="icon"
        src="../../../../../../../assets/icons/normal-dot.svg"
        alt=""
      />
      <p class="medium-desc f-12 bold mr-16">Normal</p>

      <img
        class="icon"
        src="../../../../../../../assets/icons/critical-dot.svg"
        alt=""
      />
      <p class="medium-desc f-12 bold">Critical</p>
    </div>
  </div>

  <div class="mt-24">
    <canvas id="service-health-line-chart" width="502" height="336"></canvas>
  </div>
</div>
