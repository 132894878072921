import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { CommonService } from 'src/app/core/services/common/common.service';
@Component({
  selector: 'app-confirmemail',
  templateUrl: './confirmemail.component.html',
  styleUrls: ['./confirmemail.component.scss'],
})
export class ConfirmemailComponent implements OnInit {
  email: string = '';
  @Input() resetPassword: boolean;
  @Input() emailValue: string;

  constructor(
    private _authService: AuthenticationService,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.email = localStorage.getItem('email');
  }

  goToGmail(): void {
    window.location.href =
      'https://mail.google.com/mail/u/0/#search/apis.o3interfaces@gmail.com';
  }
  resendEmail() {
    this._authService.resendVerificationEmail(this.email).subscribe(
      (res: any) => {
        if (res) {
          this._commonService.showSuccess(
            res.message.title,
            res.message.description
          );
        } else {
          this._commonService.showError('Something went wrong', '');
        }
      },
      (error) => {
        this._commonService.showError(
          error.message.title,
          error.message.description
        );
      }
    );
  }
}
