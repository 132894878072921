import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  public workSpaceURL = environment.workspaceApiUrl + 'api/v1/';
  professionalPlan: any;

  constructor(private httpClient: HttpClient) {}

  getBillingPlans(url: string) {
    return this.httpClient.get(this.workSpaceURL + url);
  }

  cancelSubscription() {
    const url = this.workSpaceURL + 'subscription/cancel';
    return this.httpClient.post<any>(url, '');
  }

  sendStripeToken(
    stripeToken: any,
    price_id: string,
    subscription_kuid: string
  ): Observable<any> {
    const url = this.workSpaceURL + 'subscription/checkout';
    return this.httpClient.post<any>(url, {
      payload: JSON.stringify({
        token: stripeToken,
        price_id: price_id,
        subscription_kuid: subscription_kuid,
      }),
    });
  }

  getBillingHistory() {
    const url = this.workSpaceURL + 'subscription/user-billings';
    return this.httpClient.post<any>(url, '');
  }

  billingDetails() {
    const url = this.workSpaceURL + 'user/billing-details';
    return this.httpClient.get(url);
  }
}
