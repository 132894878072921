import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { UblDashboardService } from 'src/app/core/services/dashboard/ubl-dashboard.service';

@Component({
  selector: 'app-progressive-insights-dashboard',
  templateUrl: './progressive-insights-dashboard.component.html',
  styleUrls: ['./progressive-insights-dashboard.component.scss'],
})
export class ProgressiveInsightsDashboardComponent implements OnInit {
  componentName = 'Progressive Insights';
  progressiveInsightsDashboardData: any;
  //   androidPage: string = 'Android';
  //   iosPage: string = 'iOS';
  androidPageRating: string = 'Android Rating';
  iosPageRating: string = 'iOS Rating';
  androidPageIncidents: string = 'Android Incidents';
  iosPageIncidents: string = 'iOS Incidents';
  progressiveInsightsAndroidData: any;
  progressiveInsightsIosData: any;
  doughnutChartAndroidData: any;
  doughnutChartIosData: any;
  sentimentHistoryDataIos: any;
  sentimentHistoryDataAndroid: any;
  androidRating: any;
  iosRating: any;
  androidIncidents: any;
  iosIncidents: any;
  ublDashboardPayloadData: any;
  w_kuid: string;
  androidP_kuid: string;
  iosP_kuid: string;
  overallHealth: number;
  lastFetchTime: Date;
  constructor(
    private router: Router,
    private _ublDash: UblDashboardService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.emitublDashboardPayloadData();
    this.getProgressiveInsightsDetails();
  }

  retrivedDashboardComponentsData(androidData, IOSData) {
    console.log(
      'Recieved Progressive Insights Android Data',
      this.progressiveInsightsAndroidData
    );
    console.log(
      'Recieved Progressive Insights Ios Data',
      this.progressiveInsightsIosData
    );

    this.doughnutChartAndroidData = androidData.unified;
    console.log('Doughnut Chart Android Data', this.doughnutChartAndroidData);
    this.doughnutChartIosData = IOSData.unified;
    console.log('Doughnut Chart Ios Data', this.doughnutChartIosData);

    this.sentimentHistoryDataAndroid = androidData.linkLastSevenDaysData;
    console.log('Sentiment History Data', this.sentimentHistoryDataAndroid);

    this.sentimentHistoryDataIos = IOSData.linkLastSevenDaysData;
    console.log('Sentiment History Data', this.sentimentHistoryDataIos);

    this.androidRating = androidData.apprating;
    console.log('Android Rating Data', this.androidRating);

    this.iosRating = IOSData.apprating;
    console.log('Ios Rating Data', this.iosRating);

    this.androidIncidents = androidData.topCategories;
    console.log('Android Incidents Data', this.androidIncidents);

    this.iosIncidents = IOSData.topCategories;
    console.log('Ios Incidents Data', this.androidIncidents);
  }

  emitublDashboardPayloadData() {
    this.ublDashboardPayloadData = JSON.parse(
      localStorage.getItem('UblDashboardPayloadData')
    );
    if (this.ublDashboardPayloadData) {
      this.setUblDashboardPayloadData();
    } else {
      console.log('No Ubl Dashboard Payload Data found in localStorage');
    }
  }

  setUblDashboardPayloadData() {
    this.w_kuid = this.ublDashboardPayloadData[0].workspace_kuid;
    this.iosP_kuid = this.ublDashboardPayloadData[0].project_kuid;
    this.androidP_kuid = this.ublDashboardPayloadData[1].project_kuid;
  }

  getProgressiveInsightsDetails() {
    this.commonService.isLoading = true;
    const payloadAndroid = JSON.stringify({
      workspace_kuid: this.w_kuid,
      project_kuid: this.androidP_kuid,
    });
    const payloadIos = JSON.stringify({
      workspace_kuid: this.w_kuid,
      project_kuid: this.iosP_kuid,
    });
    this._ublDash
      .getProgressiveInsightsData(payloadAndroid, payloadIos)
      .subscribe((res: any) => {
        console.log('Progressive Insights Data ==>', res);
        this.progressiveInsightsDashboardData = res;

        this.progressiveInsightsAndroidData = res.androidData;
        console.log(
          'this.progressiveInsightsAndroidData',
          this.progressiveInsightsAndroidData
        );
        this.progressiveInsightsIosData = res.iosData;
        console.log(
          'this.progressiveInsightsIosData',
          this.progressiveInsightsIosData
        );
        this.lastFetchTime = res.lastFetchTime;
        console.log(
          'Test Last Fetch Time ________________',
          this.lastFetchTime
        );
        this.overallHealth = res.overallHealth;
        console.log(
          'Test Overall HEalth __________________',
          this.overallHealth
        );
        this.retrivedDashboardComponentsData(
          this.progressiveInsightsAndroidData,
          this.progressiveInsightsIosData
        );
        this.commonService.isLoading = false;
      });
  }
}
