<div class="success-failure-line">
  <ng-container *ngFor="let item of valuesArray">
    <div [style.width.%]="item.value" [ngClass]="item.class">
      <span
        class="percentage dull-black f-15"
        *ngIf="item.class === 'success-line'"
      >
        {{ item.value | number : "1.0-0" }}%
      </span>
    </div>
  </ng-container>
</div>
