<!-- Centered layout as in v2 designs -->

<div
  class="layout-centered"
  *ngIf="!showCreatingWorkspaceScreen && !showSuccessScreen"
>
  <div class="container">
    <div class="cancel-container" (click)="goToWorkspaceManagement()">X</div>
    <div class="content-container">
      <div class="logo-container">
        <div class="logo-title">
          <img
            src="assets/icons/fusion-filled-logo.svg"
            alt="logo"
            class="logo-image"
          />
        </div>

        <!-- <div class="logo-title">Fussion</div> -->
      </div>

      <div class="title">Create Workspace</div>
      <div class="description">
        Create a workspace by entering a name that reflects your project,
        establishes a dedicated space for collaboration and success.
      </div>

      <form [formGroup]="WorkspaceForm" class="full-width">
        <mat-form-field
          class="input-feild"
          [class.invalid-input]="
            (WorkspaceForm.get('title').hasError('required') &&
              WorkspaceForm.get('title').touched) ||
            (WorkspaceForm.get('title').hasError('maxlength') &&
              WorkspaceForm.get('title').dirty)
          "
          appearance="fill"
        >
          <mat-label class="label">Workspace Name</mat-label>
          <input
            class="text-input-feild"
            matInput
            id="title"
            name="title"
            type="title"
            formControlName="title"
          />
        </mat-form-field>
        <div
          class="error-message"
          *ngIf="
            WorkspaceForm.get('title').hasError('required') &&
            WorkspaceForm.get('title').touched
          "
        >
          Workspace name is required.
        </div>
        <div
          class="error-message"
          *ngIf="
            WorkspaceForm.get('title').hasError('maxlength') &&
            WorkspaceForm.get('title').dirty
          "
        >
          Workspace name cannot be greater than 35 characters.
        </div>
        <!-- {{ WorkspaceForm.get('title').errors | json }} -->
        <button class="btn-primary" (click)="createWorkspace()">
          Continue
        </button>
      </form>
    </div>
  </div>
</div>

<div class="layout-centered" *ngIf="showSuccessScreen">
  <div class="container">
    <div class="content-container">
      <div class="logo-container polar">
        <div class="logo-title">
          <img
            src="../../../../../../assets/icons/group.svg"
            alt="logo"
            class="logo-image"
          />
        </div>
        <!-- <div class="logo-title">Fussion</div> -->
      </div>

      <div class="title tile">Workspace created successfully</div>
      <div class="description">
        Congratulations workspace created. Enjoy the wide range of fusion
        features.
      </div>

      <div class="btn-primary" (click)="goToDashboard()">Go To Dashboard</div>
    </div>
  </div>
</div>

<div class="layout-centered" *ngIf="showCreatingWorkspaceScreen">
  <div class="logo-container polar">
    <div class="logo-image-container">
      <img
        src="../../../../assets/icons/logo-fusion-logo-colored.svg"
        alt="logo"
        class="logo"
      />
    </div>
  </div>

  <div class="content-container1">
    <div class="content1">
      <div class="logo1">
        <img
          src="../../../../../../assets/icons/fusion-filled-logo.svg"
          alt=""
        />
      </div>

      <div class="title">We're creating your workspace</div>

      <!-- <div class="title-desc">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet
        urna quam. Donec sodales lectus erat.
      </div> -->

      <div class="content">
        <div class="content-logo"></div>
        <div class="content-desc">{{ title | uppercase }}</div>
      </div>

      <div class="end-line">
        <div class="center"></div>
      </div>
    </div>
  </div>

  <div class="copyRight">© Fusion 2023</div>
</div>
