import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-sentiment-history-chart',
  templateUrl: './sentiment-history-chart.component.html',
  styleUrls: ['./sentiment-history-chart.component.scss'],
})
export class SentimentHistoryChartComponent implements OnInit {
  @ViewChild('lineChart') private lineChartRef: ElementRef;
  @Input() recievedSentimentHistoryData: any;

  private lineChart: Chart;

  constructor() {}

  ngOnInit(): void {
    console.log(
      'Recieved Sentiment History Data ==>',
      this.recievedSentimentHistoryData
    );
  }
  ngAfterViewInit(): void {
    this.createLineChart();
  }

  createLineChart() {
    const ctx = this.lineChartRef.nativeElement.getContext('2d');

    // Extract labels and dataset values from the received data
    const labels = this.recievedSentimentHistoryData.map(
      (data) => data.date_posted
    );
    const positiveData = this.recievedSentimentHistoryData.map((data) =>
      parseFloat(data.positive)
    );
    const neutralData = this.recievedSentimentHistoryData.map((data) =>
      parseFloat(data.neutral)
    );
    const negativeData = this.recievedSentimentHistoryData.map((data) =>
      parseFloat(data.negative)
    );

    this.lineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Positive',
            data: positiveData,
            backgroundColor: '#7dd198',
            borderColor: '#7dd198',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Neutral',
            data: neutralData,
            backgroundColor: '#adafb8',
            borderColor: '#adafb8',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Negative',
            data: negativeData,
            backgroundColor: '#ce3632',
            borderColor: '#ce3632',
            borderWidth: 2,
            fill: false,
          },
        ],
      },
      options: {
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              //   text: 'Days',
            },
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            min: 0,
            max: 100,
            title: {
              display: true,

              //   text: 'Percentage (%)',
            },
            ticks: {
              callback: function (value) {
                return value + '%';
              },
              stepSize: 25,
            },
          },
        },
        layout: {
          padding: {
            left: -15, // Adjust as needed
            bottom: -15, // Adjust as needed
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            labels: {
              font: {
                size: 12,
              },
              color: '#fffff', // Change label color here
            },
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            backgroundColor: 'rgba(0,0,0,0.8)', // Custom background color
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
          point: {
            radius: 0,
          },
        },
      },
    });
  }
}
