import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { API_ENDPOINTS } from 'src/app/core/http/api-end-points';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class QualityManagementService {
  httpClint: any;
  project_kuid: string = JSON.parse(
    localStorage.getItem('selectedProject') || '{}'
  ).p_kuid;
  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {
    this.project_kuid = JSON.parse(
      localStorage.getItem('selectedProject') || '{}'
    ).p_kuid;
  }

  testCaseSearch(body: any): Observable<any> {
    const url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.TEST_CASE_SEARCH;
    return this.commonService.performPostRequest(url, body);
  }

  testRunSearch(body: any): Observable<any> {
    const url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.SEARCH_TEST_RUN;
    return this.commonService.performPostRequest(url, body);
  }

  SuitesSearch(data: any): Observable<any> {
    const url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.SEARCH_TEST_SUITE;
    const body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        ...data,
      }),
    };
    return this.commonService.performPostRequest(url, body);
  }

  // All functions related to Test Suites
  fetchTestSuites(body): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.FETCH_TEST_SUITE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.FETCH_TEST_SUITE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  createTestSuite(data: any): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        title: data.title,
        description: data.description,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.CREATE_TEST_SUITE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.CREATE_TEST_SUITE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  updateTestSuite(data: any): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        suite_title: data.title,
        suite_description: data.description,
        suite_kuid: data.suite_kuid,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.UPDATE_TEST_SUITE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.UPDATE_TEST_SUITE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  deleteTestSuite(suiteId: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        suite_kuid: suiteId,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.DELETE_TEST_SUITE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.DELETE_TEST_SUITE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  searchTestSuite(title: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        title: title,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.SEARCH_TEST_SUITE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.SEARCH_TEST_SUITE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  // All functions related to Test Cases

  createTestCase(data: any): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        title: data.title,
        suite_kuid: data.suite_kuid,
        description: data.description,
        type: data.type,
        subSteps: data.subSteps,
        subStepCount: data.subStepCount,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.CREATE_TEST_CASE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.CREATE_TEST_CASE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  fetchTestCase(body: any): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.FETCH_TEST_CASES,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.FETCH_TEST_CASES,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  searchTestCase(title: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        title: title,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.SEARCH_TEST_CASE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.SEARCH_TEST_CASE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  updateTestCase(data: any): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        test_case_id: data.test_case_id,
        title: data.title,
        description: data.description,
        type: data.type,
        subSteps: data.subSteps,
        subStepCount: data.subStepCount,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.UPDATE_TEST_CASE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.UPDATE_TEST_CASE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  deleteTestCase(caseId: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        test_case_id: caseId,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.DELETE_TEST_CASE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.CASES.DELETE_TEST_CASE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  // All functions related to Test Runs
  createTestRuns(data: any): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        ...data,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.CREATE_TEST_RUN,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.CREATE_TEST_RUN,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }
  fetchTestRuns(page): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        pg: page,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.FETCH_TEST_RUNS,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.FETCH_TEST_RUNS,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  fetchTestRunsDashboad(id: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        runable_kuid: id,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.TEST_RUN_DASHBOARD,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.TEST_RUN_DASHBOARD,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }
  updateTestCaseStatus(data: any): Observable<any> {
    let body = {
      payload: JSON.stringify({
        // project_kuid: this.project_kuid,
        ...data,
        project_kuid: this.project_kuid,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.UPDATE_TEST_CASE_STATUS,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.UPDATE_TEST_CASE_STATUS,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  deleteTestRun(testRunId: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        runable_kuid: testRunId,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.DELETE_TEST_RUN,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.RUNS.DELETE_TEST_RUN,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }
}
