import { CommonService } from 'src/app/core/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';
@Component({
  selector: 'app-createpassword',
  templateUrl: './createpassword.component.html',
  styleUrls: ['./createpassword.component.scss'],
})
export class CreatepasswordComponent implements OnInit {
  email: string;
  token: string;
  createPasswordForm: FormGroup;

  constructor(
    private _activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private _authService: AuthenticationService,
    private fb: FormBuilder,
    private _hpService: HelperService
  ) {}

  ngOnInit(): void {
    this.initializeCreatePasswordForm();
    this._activatedRoute.queryParams.subscribe((params: any) => {
      this.email = params.email;
      this.token = params.token;
    });
  }

  initializeCreatePasswordForm() {
    this.createPasswordForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(14),
          this.passwordValidator,
        ],
      ],
      confirmPassword: ['', [Validators.required, this.matchValue('password')]],
    });

    this.createPasswordForm.controls['password'].valueChanges.subscribe({
      next: () =>
        this.createPasswordForm.controls[
          'confirmPassword'
        ].updateValueAndValidity(),
    });
  }

  matchValue(matchTo: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control.value === control.parent?.get(matchTo)?.value
        ? null
        : { notMatching: true };
    };
  }

  passwordValidator = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const errors: { [key: string]: any } = {};
    if (value.length < 8) {
      errors['minlength'] = true;
      errors['pattern'] = true;
    } else if (value.length > 14) {
      errors['maxLength'] = true;
    } else if (this._hpService.validatePassword(value)) {
      errors['pattern'] = true;
    }
    if (Object.keys(errors).length > 0) {
      return errors;
    }
    return null;
  };

  onCreatePassword() {
    if (this.createPasswordForm.valid) {
      this.commonService.isLoading = true;
      if (this.createPasswordForm.valid) {
        this._authService
          .CreateNewPassword(
            this.email,
            this.token,
            this.createPasswordForm.value.password
          )
          .subscribe((res) => {
            this.commonService.isLoading = false;
            this.commonService.showSuccess(
              'Password reset successfully. Please login',
              'Password Reset'
            );
            window.location.replace('/');
          });
      } else {
        this.commonService.showWarning(
          'Password does not match',
          'Password Reset'
        );
      }
    } else {
      // Invalid form, mark fields as touched and set errors
      this.commonService.isLoading = false;
      for (const controlName in this.createPasswordForm.controls) {
        const control = this.createPasswordForm.get(controlName);
        if (control && !control.touched) {
          control.markAsTouched();
        }
      }
    }
  }
}
