<!-- Centered layout as in v2 designs -->
<div class="layout-centered">
  <div class="logo-container">
    <div class="logo-image-container">
      <img
        src="../../../../assets/icons/logo-fusion-logo-colored.svg"
        alt="logo"
        class="logo-image"
      />
    </div>
  </div>
  <div class="container">
    <div class="content-container">
      <div class="signIn">Sign In</div>
      <div class="signIndescription">
        Enter your credentials to start reporting bugs.
      </div>
      <form [formGroup]="signInForm" class="full-width">
        <div class="server-error-meesage" *ngIf="commonService.showServerError">
          <svg
            class="icon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="​http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1-8.16L8.736 9.4a.548.548 0 0 1-.216.424A.828.828 0 0 1 8 10a.828.828 0 0 1-.52-.175.549.549 0 0 1-.215-.424L7 4.841a.679.679 0 0 1 .065-.32.814.814 0 0 1 .214-.273c.094-.078.205-.14.329-.183a1.2 1.2 0 0 1 .78 0c.124.042.236.105.329.183a.814.814 0 0 1 .214.273c.048.102.07.21.066.32L9 4.839z"
              fill="#FF5E5E"
              fill-rule="nonzero"
            />
          </svg>
          <span class="text">Incorrect email or password</span>
        </div>
        <mat-form-field
          class="input-feild"
          [class.invalid-input]="
            signInForm.get('email').invalid && signInForm.get('email').touched
          "
          appearance="fill"
        >
          <mat-label>Email</mat-label>
          <input
            class="text-input-feild"
            matInput
            id="email"
            name="email"
            type="email"
            formControlName="email"
            email="true"
          />
        </mat-form-field>
        <div
          class="error-message align-error"
          *ngIf="
            signInForm.get('email').hasError('required') &&
            signInForm.get('email').touched
          "
        >
          Email required
        </div>
        <div
          class="error-message align-error"
          *ngIf="
            signInForm.get('email').hasError('email') &&
            signInForm.get('email').touched
          "
        >
          Invalid email format.
        </div>
        <mat-form-field
          class="input-feild"
          [class.invalid-input]="
            signInForm.get('password').invalid &&
            signInForm.get('password').touched
          "
          appearance="fill"
        >
          <mat-label>Password</mat-label>
          <div class="flx">
            <input
              class="text-input-feild"
              matInput
              type="password"
              name="password"
              id="password"
              formControlName="password"
              (keyup.enter)="login()"
              [type]="showPassword ? 'text' : 'password'"
            />
            <mat-icon
              *ngIf="!showPassword"
              (click)="showPassword = !showPassword"
            >
              <img
                class="eye"
                src="../../../../assets/icons/i-os-icon-small-eye-disabled.svg"
                alt="Closed Eye Icon"
              />
            </mat-icon>
            <mat-icon
              *ngIf="showPassword"
              (click)="showPassword = !showPassword"
            >
              <img
                class="eye"
                src="../../../../assets/icons/i-os-icon-small-eye-enabled.svg"
                alt="Opened Eye Icon"
              />
            </mat-icon>
          </div>
        </mat-form-field>
        <div
          class="error-message align-error"
          *ngIf="
            signInForm.get('password').hasError('required') &&
            signInForm.get('password').touched
          "
        >
          Password required
        </div>
        <h4 style="color: red">{{ this.appleLoginError }}</h4>
        <button
          class="btn-primary"
          style="margin-top: 24px"
          type="submit"
          (click)="login()"
        >
          Sign In
        </button>
      </form>
      <div class="Divider"></div>
      <div class="social-logins-container">
        <app-google-one-tap></app-google-one-tap>
        <!-- <app-apple-one-tap
          class="flex-center"
          (showAppleLoginError)="receiveDataFromApple($event)"
        ></app-apple-one-tap> -->
      </div>
      <div class="forgot-password-container">
        <div class="discription-text">Forgot Password?</div>
        <a [routerLink]="['/account/resetpassword']" class="action-text">
          Reset</a
        >
      </div>
      <div class="signup-text-container">
        <div class="discription-text">Don't have an account?</div>
        <a [routerLink]="['/account/signup']" class="action-text"> Sign Up </a>
      </div>
    </div>
  </div>
  <div class="copyRight">© Fusion 2023</div>
</div>
