<div class="page-wrapper mt-18">
  <div class="radar1">
    <p class="medium-desc f-19 bold">Android</p>
    <div class="radar1-wrapper">
      <app-radar-graph
        [recievedAndroidRadarData]="recievedAndroidRadarData"
      ></app-radar-graph>
    </div>
  </div>
  <div class="radar2">
    <p class="medium-desc f-19 bold">iOS</p>
    <div class="radar2-wrapper">
      <app-radar-chart-ios
        [recievedIosRadarData]="recievedIosRadarData"
      ></app-radar-chart-ios>
    </div>
  </div>
</div>
