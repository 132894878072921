import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { WorkspaceService } from 'src/app/core/services/workspace/workspace.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss'],
})
export class CreateWorkspaceComponent implements OnInit {
  title: string = '';
  showCreatingWorkspaceScreen: boolean = false;
  showSuccessScreen: boolean = false;
  WorkspaceForm: FormGroup;
  constructor(
    private _router: Router,
    private _workspaceService: WorkspaceService,
    public _commonService: CommonService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initializeWorkspaceForm();
  }

  initializeWorkspaceForm() {
    this.WorkspaceForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(35)]],
    });
  }

  createWorkspace() {
    if (this.WorkspaceForm.valid) {
      this.title.replace(/\s/g, '');
      const payload = {
        payload: JSON.stringify({
          workspace_title: this.WorkspaceForm.value.title,
        }),
      };

      this._commonService.isLoading = true;
      this._workspaceService.createUserWorkspace(payload).subscribe(
        (res) => {
          let {
            token,
            owned_workspaces,
            shared_workspaces,
            lastVisited_workspace,
          } = res;
          this.showCreatingWorkspaceScreen = true;
          this.title = this.WorkspaceForm.value.title;
          setTimeout(() => {
            this.showSuccessScreen = true;
          }, 5000);
          localStorage.setItem('token', token);
          localStorage.setItem(
            'owned_workspaces',
            JSON.stringify(owned_workspaces)
          );
          localStorage.setItem(
            'shared_workspaces',
            JSON.stringify(shared_workspaces)
          );
          localStorage.setItem(
            'lastVisited_workspace',
            JSON.stringify(lastVisited_workspace)
          );
          this.WorkspaceForm.reset();
        },
        (error: HttpErrorResponse) => {
          this.showCreatingWorkspaceScreen = false;
          this.showSuccessScreen = false;
        }
      );
      this._commonService.isLoading = false;
    } else {
      // Invalid form, mark fields as touched and set errors
      this._commonService.isLoading = false;
      for (const controlName in this.WorkspaceForm.controls) {
        const control = this.WorkspaceForm.get(controlName);
        if (control && !control.touched) {
          control.markAsTouched();
        }
      }
    }
  }

  goToDashboard() {
    this._router.navigate(['/']);
  }

  goToWorkspaceManagement() {
    this._router.navigate(['/workspace/workspace-management']);
    this.WorkspaceForm.reset();
  }
}
