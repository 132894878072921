import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { TokenService } from 'src/app/core/authentication/token.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  email: string = '';
  password: string = '';
  error: boolean = false;
  showPassword: boolean = false;
  signInForm: FormGroup;
  appleLoginError: string;

  constructor(
    private _auth: AuthenticationService,
    private _router: Router,
    public commonService: CommonService,
    private _tokenService: TokenService,
    private fb: FormBuilder,
    private ngZone: NgZone
  ) {
    if (this._auth.loggedIn) {
      this._router.navigate(['']);
    }
  }

  ngOnInit(): void {
    this.initializesignInForm();
    if (localStorage.getItem('passportToken') !== null) {
      this._tokenService.requestHandShakeToken().subscribe(async (res) => {
        let { token } = res;
        localStorage.setItem('token', token);
        this._auth.passportLogin().subscribe((res) => {
          if (res) {
            this._router.navigate(['workspace/select-workspace']);
          }
        });
      });
    }
  }

  receiveDataFromApple(data: string) {
    this.appleLoginError = data;
  }

  initializesignInForm() {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  async login() {
    this.commonService.isLoading = true;
    if (this.signInForm.valid) {
      this._tokenService.requestHandShakeToken().subscribe(async (res) => {
        let { token } = res;
        localStorage.setItem('token', token);
        this.ngZone.run(async () => {
          (
            await this._auth.loginUserObservable(this.signInForm.value)
          ).subscribe(
            (response) => {
              if (response) {
                this.commonService.isLoading = false;
                this._router.navigate(['workspace/select-workspace']);
              } else {
                this.commonService.showError('Something went wrong', '');
              }
            },
            (error) => {
              this.commonService.showError(
                error.message.title,
                error.message.description
              );
            }
          );
        });
      });
    } else {
      // Invalid form, mark fields as touched and set errors
      this.commonService.isLoading = false;
      for (const controlName in this.signInForm.controls) {
        const control = this.signInForm.get(controlName);
        if (control && !control.touched) {
          control.markAsTouched();
        }
      }
    }
  }
}
