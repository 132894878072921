<div class="page-wrapper">
  <div class="top-wrapper">
    <p class="sub-heading bold">API Issues</p>
    <div class="right-wrapper">
      <img
        class="icon"
        src="../../../../../../../assets/icons/success-dot.svg"
        alt=""
      />
      <p class="medium-desc f-12 bold mr-16">Success</p>

      <img
        class="icon"
        src="../../../../../../../assets/icons/normal-dot.svg"
        alt=""
      />
      <p class="medium-desc f-12 bold mr-16">Validation</p>

      <img
        class="icon"
        src="../../../../../../../assets/icons/critical-dot.svg"
        alt=""
      />
      <p class="medium-desc f-12 bold">Failure</p>
    </div>
  </div>

  <div class="mt-24">
    <div class="bottom-wrapper" *ngFor="let item of data.slice(0, 4)">
      <p class="mini-desc-api-endpoint">
        {{
          item.api_endpoint.length > 25
            ? item.api_endpoint.slice(0, 25) + "..."
            : item.api_endpoint
        }}
      </p>
      <app-success-failure-line [data]="item"></app-success-failure-line>
    </div>
  </div>
</div>
