<div class="screen-container">
  <div class="no-container">
    <img
      *ngIf="icon == ''"
      src="../../../../../../assets/icons/no-issue-icon.svg"
      class="no-icon"
    />
    <img *ngIf="icon !== ''" [src]="icon" class="no-icon" />
    <div class="no-text">{{ heading }}</div>
    <div class="no-description">
      <!-- No issues have been detected here. -->
      {{ description }}
    </div>
    <div class="btn-Done" *ngIf="isShowButton" (click)="onDone()">
      {{ buttonTitle }}
    </div>
    <div
      class="btn-Done"
      *ngIf="isShowTesterButton"
      (click)="onButtonClick.emit()"
    >
      {{ buttonTitle }}
    </div>
    <div
      class="btn-Done"
      *ngIf="isShowGroupButton"
      (click)="onButtonClick.emit()"
    >
      {{ buttonTitle }}
    </div>
  </div>
</div>
