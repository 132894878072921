import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { API_ENDPOINTS } from 'src/app/core/http/api-end-points';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class DistributionManagementService {
  httpClint: any;
  presignedURL: string = '';
  workspace_kuid = JSON.parse(
    localStorage.getItem('lastVisited_workspace') || '{}'
  ).ws_kuid;
  project_kuid: string = JSON.parse(
    localStorage.getItem('selectedProject') || '{}'
  ).p_kuid;

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {}

  sendPresignedURL(data) {
    this.presignedURL = data;
  }

  getPresignedURL() {
    return this.presignedURL;
  }

  getS3URL(data): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl + 'api/v1/distribution/get-presigned-url',
        data
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.QUALITY_MANAGEMENT.SUITE.FETCH_TEST_SUITE,
              body: data,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  // Upload APK File to server
  uploadChunk(url: string, formData: FormData): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/vnd.android.package-archive', // Adjust content type if needed
    });
    return this.httpClient.put(url, formData, {
      headers,
      observe: 'response',
    });
  }

  verifyAPK(body) {
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.VERIFY_APK_RELEASE,
        body
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  deleteApk(releaseKuid: any) {
    let body = {
      payload: JSON.stringify({
        workspace_kuid: this.workspace_kuid,
        project_kuid: this.project_kuid,
        release_kuid: releaseKuid,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.DELETE_APK_RELEASE,
        body
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // All functions related to Testers
  CreateTester(name: string, email: string, gid: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        workspace_kuid: this.workspace_kuid,
        project_kuid: this.project_kuid,
        name,
        email,
        group_kuids: gid,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.CREATE_TESTER,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.CREATE_TESTER,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  CreateTesterInManageGroup(
    name: string,
    email: string,
    gid: any
  ): Observable<any> {
    let body = {
      payload: JSON.stringify({
        workspace_kuid: this.workspace_kuid,
        project_kuid: this.project_kuid,
        name,
        email,
        group_kuids: gid,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.CREATE_TESTER,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.CREATE_TESTER,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  removeTesterFromGroup(body): Observable<any> {
    const url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.REMOVE_TESTER_FROM_GROUP;
    return this.commonService.performPostRequest(url, body);
  }

  testerSearch(data: any): Observable<any> {
    const url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.SEARCH_TESTER;
    const body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        workspace_kuid: this.workspace_kuid,
        ...data,
      }),
    };
    return this.commonService.performPostRequest(url, body);
  }

  groupSearch(data: any): Observable<any> {
    const url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.GROUPS.SEARCH_GROUP;
    const body = {
      payload: JSON.stringify({
        project_kuid: this.project_kuid,
        workspace_kuid: this.workspace_kuid,
        ...data,
      }),
    };
    return this.commonService.performPostRequest(url, body);
  }

  FetchTesters(data): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.FETCH_TESTER,
        data
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.FETCH_TESTER,
              body: data,
              response: res,
            },
          ];
          return res;
        })
      );
  }
  FetchTestersWithOutRelease(data): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS
            .FETCH_TESTER_WITHOUT_RELEASE,
        data
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS
                  .FETCH_TESTER_WITHOUT_RELEASE,
              body: data,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  DeleteTester(body): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.DELETE_TESTER,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.DELETE_TESTER,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  UpdateTesterDetails(id: string, name: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        tester_kuid: id,
        name,
        workspace_kuid: this.workspace_kuid,
        project_kuid: this.project_kuid,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS.UPDATE_TESTER_DETAILS,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.TESTERS
                  .UPDATE_TESTER_DETAILS,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  // All functions related to Groups
  CreateGroup(name: string, description: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        name,
        description,
        workspace_kuid: this.workspace_kuid,
        project_kuid: this.project_kuid,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.GROUPS.CREATE_GROUP,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.GROUPS.CREATE_GROUP,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  FetchGroup(body): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.GROUPS.FETCH_GROUP,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.GROUPS.FETCH_GROUP,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  DeleteGroup(body): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.GROUPS.DELETE_GROUP,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.GROUPS.DELETE_GROUP,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  UpdateGroupDetails(id: string, name: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        workspace_kuid: this.workspace_kuid,
        project_kuid: this.project_kuid,
        tester_kuid: id,
        name,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.GROUPS.UPDATE_GROUP_DETAILS,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.GROUPS
                  .UPDATE_GROUP_DETAILS,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  // Releated to releases

  RemoveTester(id: string, releaseId: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        tester_kuid: id,
        release_kuid: releaseId,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.REMOVE_TESTER,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.REMOVE_TESTER,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }

  AddTestersToRelease(ids: string[], releaseId: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        tester_kuids: ids,
        release_kuid: releaseId,
        // workspace_kuid: this.workspace_kuid,
        // project_kuid: this.project_kuid,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.ADD_TESTER_TO_RELEASE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.ADD_TESTER_TO_RELEASE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }
  FetchReleaseDetails(releaseId: string): Observable<any> {
    const w_kuid = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    ).ws_kuid;
    const p_kuid = JSON.parse(
      localStorage.getItem('selectedProject') || '{}'
    ).p_kuid;
    let body = {
      payload: JSON.stringify({
        release_kuid: releaseId,
        workspace_kuid: w_kuid,
        project_kuid: p_kuid,
      }),
    };
    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.FETCH_RELEASES_DETAILS;

    return this.httpClient.post(url, body).pipe(
      map((res) => {
        this.commonService.apiLogs = [
          ...this.commonService.apiLogs,
          {
            url: url,
            body: body,
            response: res,
          },
        ];
        return res;
      })
    );
  }

  AddGroupsToRelease(ids: string[], releaseId: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        tester_kuid: ids,
        release_kuid: releaseId,
        workspace_kuid: this.workspace_kuid,
        project_kuid: this.project_kuid,
      }),
    };
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.ADD_GROUP_TO_RELEASE,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.ADD_GROUP_TO_RELEASE,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }
  // Function to fetch uploaded releases from th server
  FetchReleases(body): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl +
          API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.FETCH_RELEASES,
        body
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.DISTRIBUTION_MANAGEMENT.FETCH_RELEASES,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }
}
