import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from 'src/app/core/services/common/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upgrade-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './upgrade-dialog.component.html',
  styleUrls: ['./upgrade-dialog.component.scss'],
})
export class UpgradeDialogComponent implements OnInit {
  constructor(private commonService: CommonService, private router: Router) {}

  ngOnInit(): void {}

  closeUpdatePage() {
    this.commonService.isPackegeExpired = false;
  }

  upgradePlan() {
    this.commonService.isPackegeExpired = false;
    this.router.navigateByUrl('/account-settings/billing-plan');
  }
}
