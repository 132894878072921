<div class="chart-section" *ngIf="dashboardData">
  <div class="chart-wrapper mt-20">
    <app-success-failure-chart
      [data]="successFailureJourneyData"
    ></app-success-failure-chart>
  </div>
  <div class="chart-wrapper mt-20">
    <app-network-issues-chart
      [data]="journeyNetworkIssues"
      [chartId]="recievedNetworkChartName"
    ></app-network-issues-chart>
  </div>
  <div class="chart-wrapper mt-20">
    <app-system-stability-chart
      [data]="journeySystemStability"
      [recievedChartName]="recievedChartName"
    ></app-system-stability-chart>
  </div>
  <div class="chart-wrapper mt-20">
    <app-api-issues [data]="joureyAPIIssues"></app-api-issues>
  </div>
</div>
