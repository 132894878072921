import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-rating-line',
  templateUrl: './rating-line.component.html',
  styleUrls: ['./rating-line.component.scss'],
})
export class RatingLineComponent implements OnInit {
  @Input() progressValues: number[] = [];
  @Input() ratineLineData: number[] = [];
  @Input() dashboardProgressValues: number[] = [];
  @Input() dashboardRatineLineData: number[] = [];
  @Input() apiDashboard: string = '';
  colors: string[] = ['#177e89', '#adafb8', '#d62828'];
  progressArray: { value: number; color: string }[] = [];
  dashboardArray: { value: number; color: string }[] = [];

  constructor() {}
  ngOnInit(): void {
    this.progressArray = [
      { value: this.ratineLineData[0], color: '#177e89' },
      { value: this.ratineLineData[1], color: '#adafb8' },
      { value: this.ratineLineData[2], color: '#d62828' },
    ];
    this.dashboardArray = [
      { value: this.dashboardRatineLineData[0], color: '#7dd198' },
      { value: this.dashboardRatineLineData[1], color: '#adafb8' },
      { value: this.dashboardRatineLineData[2], color: '#ce3632' },
    ];
    console.log('Dashboard name ==>', this.apiDashboard);
  }

  showProgressLine(): boolean {
    // Check if all neutral, positive, and negative values are zero
    return this.progressArray.some((progress) => progress.value !== 0);
  }
}
