import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-empty-container',
  templateUrl: './empty-container.component.html',
  styleUrls: ['./empty-container.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class EmptyContainerComponent implements OnInit {
  @Input() heading: string;
  @Input() icon: string = '';
  @Input() description: string;
  @Input() buttonTitle: string;
  @Input() isShowButton: boolean = false;
  @Input() isShowTesterButton: boolean = false;
  @Input() isShowGroupButton: boolean = false;
  @Output() onButtonClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  onDone() {
    window.location.replace('/project/integrate-sdk');
  }
}
