<!-- Centered layout as in v2 designs -->
<div class="layout-centered">
  <div>
    <div class="logo-container">
      <div class="logo-image-container">
        <img
          src="../../../../assets/icons/logo-fusion-logo-colored.svg"
          alt="logo"
          class="logo-image"
        />
      </div>
    </div>
    <div class="container">
      <div class="content-container">
        <div class="signIn">Sign Up</div>
        <div class="signIndescription">
          Get Started to Manage and Monitor your Product Health - it's Free.
        </div>
        <form [formGroup]="signUpForm" class="full-width">
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              signUpForm.get('name').invalid && signUpForm.get('name').touched
            "
            appearance="fill"
          >
            <mat-label class="label">Name</mat-label>
            <input
              class="text-input-feild"
              matInput
              id="name"
              name="name"
              type="name"
              formControlName="name"
            />
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              signUpForm.get('name').hasError('required') &&
              signUpForm.get('name').touched
            "
          >
            Username is required.
          </div>
          <div
            class="error-message align-error"
            *ngIf="signUpForm.get('name').hasError('maxlength')"
          >
            Username cannot be longer than 30 characters.
          </div>
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              signUpForm.get('email').invalid && signUpForm.get('email').touched
            "
            appearance="fill"
          >
            <mat-label class="label">Email</mat-label>
            <input
              class="text-input-feild"
              matInput
              id="email"
              name="email"
              type="email"
              formControlName="email"
              email="true"
              appEmailValidator
            />
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              signUpForm.get('email').hasError('required') &&
              signUpForm.get('email').touched
            "
          >
            Email is required.
          </div>
          <div
            class="error-message align-error"
            *ngIf="
              !signUpForm.get('email').hasError('required') &&
              signUpForm.get('email').hasError('invalidEmail') &&
              signUpForm.get('email').touched
            "
          >
            Please enter a valid email.
          </div>
          <mat-form-field
            class="input-feild"
            [class.invalid-input]="
              signUpForm.get('password').invalid &&
              signUpForm.get('password').touched
            "
            appearance="fill"
          >
            <mat-label class="label">Password</mat-label>
            <div class="flx">
              <input
                class="text-input-feild"
                matInput
                type="password"
                name="password"
                id="password"
                formControlName="password"
                [type]="showPassword ? 'text' : 'password'"
              />
              <mat-icon
                *ngIf="!showPassword"
                (click)="showPassword = !showPassword"
              >
                <img
                  class="eye"
                  src="../../../../assets/icons/i-os-icon-small-eye-disabled.svg"
                  alt="Closed Eye Icon"
                />
              </mat-icon>
              <mat-icon
                *ngIf="showPassword"
                (click)="showPassword = !showPassword"
              >
                <img
                  class="eye"
                  src="../../../../assets/icons/i-os-icon-small-eye-enabled.svg"
                  alt="Opened Eye Icon"
                />
              </mat-icon>
            </div>
          </mat-form-field>
          <div
            class="error-message align-error"
            *ngIf="
              signUpForm.get('password').hasError('required') &&
              signUpForm.get('password').touched
            "
          >
            Password is required.
          </div>
          <div
            class="error-message align-error"
            *ngIf="
              signUpForm.get('password').hasError('pattern') &&
              signUpForm.get('password').touched
            "
          >
            Password should be 8 characters long with letters and numbers.
          </div>
          <div
            class="error-message align-error"
            *ngIf="signUpForm.get('password').hasError('maxLength')"
          >
            Password length must not exceed 14 characters.
          </div>
          <h4 style="color: red">{{ this.appleLoginError }}</h4>
          <button class="btn-primary" style="--mt: 3rem" (click)="signup()">
            Sign Up
          </button>
        </form>
        <div class="Divider"></div>
        <div class="social-logins-container">
          <app-google-one-tap></app-google-one-tap>
          <!-- <app-apple-one-tap
            (showAppleLoginError)="receiveDataFromApple($event)"
          ></app-apple-one-tap> -->
        </div>
        <div class="signup-text-container">
          <div class="discription-text">Already have an account?</div>
          <a [routerLink]="['/account/signin']" class="action-text">
            Sign in
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="copyRight">© Fusion 2023</div>
</div>
