import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-header-container',
  templateUrl: './header-container.component.html',
  styleUrls: ['./header-container.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class HeaderContainerComponent implements OnInit {
  @Input() headerTitle: string = '';
  @Input() bgColor: string = '';
  @Input() isShowButton: boolean = false;
  @Input() buttonTitle: string = '';
  @Input() buttonIcon: string = '';
  @Output() onButtonClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
