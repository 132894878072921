<div class="page-wrapper">
  <p class="sub-heading bold">Success / Failure of journey</p>
  <div class="content-wrapper mt-24">
    <div class="left-content-wrapper">
      <canvas #successFailureChart width="177.2" height="85"></canvas>
      <div class="donut-content">
        <div class="mini-gap-line"></div>
        <p class="lighter-grey f-16 mt-8">Overall Score</p>
      </div>
    </div>
    <div class="right-content-wrapper">
      <div class="content-wrapper">
        <div class="success-line-height-42"></div>
        <div class="content lh-1">
          <p class="medium-desc f-21 bold lh-1">
            {{ successPercentage | number : "1.0-0" }}%
          </p>
          <p class="mini-desc-grey f-13">Success</p>
        </div>
      </div>

      <div class="content-wrapper">
        <div class="normal-line-height-42"></div>
        <div class="content">
          <p class="medium-desc f-21 bold lh-1">
            {{ validationPercentage | number : "1.0-0" }}%
          </p>
          <p class="mini-desc-grey f-13">Validation</p>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="critical-line-height-42"></div>
        <div class="content">
          <p class="medium-desc f-21 bold lh-1">
            {{ failurePercentage | number : "1.0-0" }}%
          </p>
          <p class="mini-desc-grey f-13">Failure</p>
        </div>
      </div>
    </div>
  </div>
</div>
