import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { TokenService } from 'src/app/core/authentication/token.service';
import { _MAT_HINT } from '@angular/material/form-field';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  showPassword: boolean = false;
  user: any;
  loggedIn: any;
  submitted = false;
  signUpForm: FormGroup;
  enableEmailValidation: boolean = true;
  appleLoginError: string;
  constructor(
    private _auth: AuthenticationService,
    private _router: Router,
    public _commonService: CommonService,
    private _tokenService: TokenService,
    private fb: FormBuilder,
    private _hpService: HelperService
  ) {}
  ngOnInit(): void {
    this.initializesignUpForm();
  }
  initializesignUpForm() {
    this.signUpForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(30)]],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(14),
          this.passwordValidator,
        ],
      ],
    });
  }

  receiveDataFromApple(data: string) {
    this.appleLoginError = data;
  }

  passwordValidator = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const errors: { [key: string]: any } = {};
    if (value.length < 8) {
      errors['minlength'] = true;
      errors['pattern'] = true;
    } else if (value.length > 14) {
      errors['maxLength'] = true;
    } else if (this._hpService.validatePassword(value)) {
      errors['pattern'] = true;
    }
    if (Object.keys(errors).length > 0) {
      return errors;
    }
    return null;
  };

  async signup() {
    this._commonService.isLoading = true;
    if (this.signUpForm.valid) {
      this._tokenService.requestHandShakeToken().subscribe(async (res) => {
        let { token } = res;
        localStorage.setItem('token', token);
        (
          await this._auth.signUpUserObservable(this.signUpForm.value)
        ).subscribe((userResponse) => {
          if (userResponse.status == 'success') {
            this._commonService.isLoading = false;
            this._router.navigate(['account/confirm_email']);
            localStorage.setItem('email', this.signUpForm.value.email);
          }
        });
      });
    } else {
      // Invalid form, mark fields as touched and set errors
      this._commonService.isLoading = false;
      for (const controlName in this.signUpForm.controls) {
        const control = this.signUpForm.get(controlName);
        if (control && !control.touched) {
          control.markAsTouched();
        }
      }
    }
  }
}
