<div class="page-wrapper">
  <p class="sub-heading bold">Identified Issues</p>
  <div class="mt-24" *ngIf="chartId === 'chart1'">
    <canvas #chartCanvas id="chart1" width="402" height="161"></canvas>
  </div>
  <div class="mt-24" *ngIf="chartId === 'chart2'">
    <canvas #chartCanvas id="chart2" width="402" height="161"></canvas>
  </div>
  <div class="mt-24" *ngIf="chartId === 'chart3'">
    <canvas #chartCanvas id="chart3" width="402" height="161"></canvas>
  </div>
  <div class="mt-24" *ngIf="chartId === 'chart4'">
    <canvas #chartCanvas id="chart4" width="402" height="161"></canvas>
  </div>
</div>
