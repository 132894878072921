<div class="page-wrapper">
  <p class="medium-desc f-19 bold">{{ journeyName }}</p>
  <div
    class="battery-container mt-5"
    [style.width.px]="batteryWidth"
    [style.height.px]="batteryHeight"
  >
    <div class="battery">
      <div
        class="level"
        [style.background-color]="getBatteryColor()"
        [style.width.%]="percentage"
      >
        <span
          *ngIf="journeyName !== 'Loading data for graph..'"
          class="f-36"
          [style.margin-left.%]="calculateMarginLeft()"
          [style.color]="getTextColor()"
        >
          {{ percentage }}%
        </span>
        <span
          *ngIf="journeyName === 'Loading data for graph..'"
          class="f-36"
          [style.margin-left.%]="calculateMarginLeft()"
          [style.color]="getTextColor()"
        >
          {{ percentage }}
        </span>
      </div>
    </div>
  </div>
</div>
