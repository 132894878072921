<router-outlet></router-outlet>
<app-loader *ngIf="isLoading | async"></app-loader>
<app-upgrade-dialog *ngIf="commonService.isPackegeExpired"></app-upgrade-dialog>

<div
  class="custom-toaster"
  [ngClass]="{
    active: commonService.onShowToaster.isError,
    inactive: !commonService.onShowToaster.isError
  }"
>
  <div class="icon" *ngIf="commonService.onShowToaster.isError">
    <!-- *ngIf="commonService.onShowToaster.isError" -->
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="m20.22 17.027-6.493-11.96a2 2 0 0 0-3.514 0l-6.5 11.96A2 2 0 0 0 5.473 20H18.46a2 2 0 0 0 1.76-2.953v-.02zm-9.247-8.534a1 1 0 0 1 2 0v4.594a1 1 0 1 1-2 0V8.493zM12 17.5a1.147 1.147 0 1 1 0-2.293 1.147 1.147 0 0 1 0 2.293z"
          fill="#FFF"
          fill-rule="nonzero"
        />
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  </div>

  <div *ngIf="commonService.onShowToaster.isError">
    <div class="title-toaster">{{ commonService.onShowToaster.title }}</div>
    <p class="toaster-description">{{ commonService.onShowToaster.message }}</p>
  </div>
  <!-- <div *ngIf="commonService.onShowToaster.isError">
    <h3>{{ commonService.onShowToaster.title }}</h3>
    <p>{{ commonService.onShowToaster.message }}</p>
  </div> -->
</div>

<div
  class="custom-toaster-success"
  [ngClass]="{ active: commonService.onShowToaster.isSuccess }"
>
  <div class="icon" *ngIf="commonService.onShowToaster.isSuccess">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75A9.769 9.769 0 0 0 12 2.25zm4.64 8.044-5.493 5.25a.76.76 0 0 1-.525.206.722.722 0 0 1-.516-.206L7.36 12.919a.75.75 0 1 1 1.032-1.088l2.23 2.128 4.988-4.753a.75.75 0 0 1 1.032 1.088z"
          fill="#FFF"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  </div>

  <div *ngIf="commonService.onShowToaster.isSuccess">
    <div class="title-toaster-success">
      {{ commonService.onShowToaster.title }}
    </div>
    <p class="toaster-description-success">
      {{ commonService.onShowToaster.message }}
    </p>
  </div>
</div>
