import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-time-stamp-conversion',
  templateUrl: './time-stamp-conversion.component.html',
  styleUrls: ['./time-stamp-conversion.component.scss'],
})
export class TimeStampConversionComponent implements OnInit, OnChanges {
  @Input() utcTime: string;
  @Input() showTimeWithDate: boolean = false;
  @Input() showTimeOnly: boolean = false;
  @Input() showTimeWithSeconds: boolean = false;
  @Input() showTimeWithDateSeconds: boolean = false;
  convertedTime: string;
  localTime: string;
  userTimeZone: string;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.detectUserTimeZone();
  }

  detectUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(this.utcTime);
    const dataWithTime = 'MMM d, y - h:mm a';
    const dateOnly = 'MMM d, y';
    const timeOnly = 'h:mm a';
    const timeWithSeconds = 'h:mm:ss a';
    const timeWithDateSeconds = 'MMM d, y - hh:mm:ss a';
    this.userTimeZone = userTimeZone;
    var dateFormat: string = '';

    if (this.utcTime) {
      if (this.showTimeWithDate) {
        dateFormat = dataWithTime;
      } else if (this.showTimeOnly) {
        dateFormat = timeOnly;
      } else if (this.showTimeWithSeconds) {
        dateFormat = timeWithSeconds;
      } else if (this.showTimeWithDateSeconds) {
        dateFormat = timeWithDateSeconds;
      } else {
        dateFormat = dateOnly;
      }
      this.localTime = this.datePipe.transform(
        date,
        dateFormat,
        this.userTimeZone
      );
    } else {
      this.localTime = '-';
    }
  }
}
