import { Component, Input, OnInit } from '@angular/core';
interface GroupedData {
  ai_tag1: string;
  items: any[];
  sum_of_reviews: number;
  sum_of_ratings: number;
  average_user_rating: number;
}
@Component({
  selector: 'app-and-ios-incidents',
  templateUrl: './and-ios-incidents.component.html',
  styleUrls: ['./and-ios-incidents.component.scss'],
})
export class AndIosIncidentsComponent implements OnInit {
  @Input() recievedAndroidIncidentsData: any;
  androidSection: string = 'Android Incidents';
  iosSection: string = 'iOS Incidents';
  pageName: string = 'androidIncidents;';
  @Input() sectionName: string = '';
  totalSumOfReviews: number = 0;

  constructor() {}

  ngOnInit(): void {
    console.log(
      'Android Categories data ==> ',
      this.recievedAndroidIncidentsData
    );
    this.groupByTag();
  }

  calculatePercentage(group: GroupedData) {
    // Check if all three values are zero
    if (group.sum_of_reviews === 0) {
      group.items.forEach((item) => {
        item.percentage = 0;
      });
    } else {
      group.items.forEach((item) => {
        item.percentage = Math.round(
          (item.reviews_count / group.sum_of_reviews) * 100
        );
      });
    }

    // Sort the items array based on ai_positivity_sentiment
    group.items.sort((a, b) => {
      const sentimentOrder = ['positive', 'neutral', 'negative'];
      return (
        sentimentOrder.indexOf(a.ai_positivity_sentiment) -
        sentimentOrder.indexOf(b.ai_positivity_sentiment)
      );
    });
  }

  groupByTag() {
    const groupedByTag: { [tag: string]: GroupedData } = {};

    this.recievedAndroidIncidentsData.forEach((item) => {
      const tag = item.ai_tag1;

      if (!groupedByTag[tag]) {
        groupedByTag[tag] = {
          ai_tag1: tag,
          items: [],
          sum_of_reviews: 0,
          sum_of_ratings: 0,
          average_user_rating: 0,
        };
      }

      groupedByTag[tag].items.push(item);
      groupedByTag[tag].sum_of_reviews += item.reviews_count;
      groupedByTag[tag].sum_of_ratings += parseFloat(item.avg_user_rating); // Convert to number and accumulate

      // Update the total sum of reviews
      this.totalSumOfReviews += item.reviews_count;
    });

    // Calculate the sum of reviews, sum of ratings, and percentage for each group
    Object.values(groupedByTag).forEach((group: GroupedData) => {
      this.calculatePercentage(group);

      // Calculate the average user rating for each group
      group.average_user_rating = group.sum_of_ratings / group.items.length;
    });

    this.recievedAndroidIncidentsData = Object.values(
      groupedByTag
    ) as GroupedData[];

    console.log(
      'Grouped by Tag with Sum, Percentage, and Average User Rating ==>',
      this.recievedAndroidIncidentsData
    );
  }
}
