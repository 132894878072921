<div class="top-wrapper">
  <p class="sub-heading bold">Android Insights</p>
  <div class="right-wrapper">
    <img
      class="icon"
      src="../../../../../../../assets/icons/success-dot.svg"
      alt=""
    />
    <p class="medium-desc f-12 bold mr-16">Positive</p>

    <img
      class="icon"
      src="../../../../../../../assets/icons/neutral-dot.svg"
      alt=""
    />
    <p class="medium-desc f-12 bold mr-16">Neutral</p>

    <img
      class="icon"
      src="../../../../../../../assets/icons/critical-dot.svg"
      alt=""
    />
    <p class="medium-desc f-12 bold">Negative</p>
  </div>
</div>

<div class="mt-24">
  <div
    class="buckets-content-wrapper mt-8"
    *ngFor="let bucket of recievedAndroidIncidentsData"
  >
    <div class="rating-content">
      <p class="sub-heading bold">{{ bucket.ai_tag1 | slice : 0 : 30 }}</p>
      <app-rating-stars
        [rating]="bucket.average_user_rating"
        [androidIncidents]="pageName"
      ></app-rating-stars>
    </div>

    <div class="bucket-section mt-12">
      <ng-container *ngFor="let item of bucket.items">
        <div class="bucket-content">
          <div
            [ngClass]="{
              'positive-incident': item.ai_positivity_sentiment === 'positive',
              'neutral-incident': item.ai_positivity_sentiment === 'neutral',
              'negative-incident': item.ai_positivity_sentiment === 'negative'
            }"
          ></div>
          <p class="sub-heading bold f-16 ml-8 dull-black">
            {{ item.percentage }}%
          </p>
        </div></ng-container
      >
    </div>
  </div>
</div>
