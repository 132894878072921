import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-progressive-and-ios-sentiment-chart',
  templateUrl: './progressive-and-ios-sentiment-chart.component.html',
  styleUrls: ['./progressive-and-ios-sentiment-chart.component.scss'],
})
export class ProgressiveAndIosSentimentChartComponent implements OnInit {
  @Input() @Input() sentimentHistorydataIos: any;
  @Input() iosData: any;
  @ViewChild('doughnutChartRef') doughnutChartRef: ElementRef;

  constructor() {}

  ngOnInit(): void {
    if (this.iosData) {
      // Calculate the total sum
      const totalSum: any = Object.values(this.iosData).reduce(
        (sum, item: any) => sum + item.total,
        0
      );
      // Update the percentage and add remainingPercentage for each item in analysisData
      Object.values(this.iosData).forEach((item: any) => {
        item.percentage = Math.round((item.total / totalSum) * 100);
        //   this.totalReviews = totalSum;
      });
    }
    console.log('Ios Data ==>', this.iosData);
  }

  ngAfterViewInit(): void {
    this.createFullDoughnutChart(
      'doughnutChart',
      [
        this.iosData[0].percentage, // Positive
        this.iosData[1].percentage, // Neutral
        this.iosData[2].percentage, // Negative
      ],
      [
        '#7dd198', // Positive color
        '#adafb8', // Neutral color
        '#ce3632', // Negative color
      ],
      [
        '#7dd198', // Positive color
        '#adafb8', // Neutral color
        '#ce3632', // Negative color
      ]
    );
  }

  createFullDoughnutChart(
    chartId: string,
    data: number[],
    backgroundColor: string[],
    borderColor: string[]
  ) {
    const ctx = this.doughnutChartRef.nativeElement.getContext('2d');
    const fullDoughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Positive', 'Neutral', 'Negative'], // Example labels
        datasets: [
          {
            label: 'Android',
            data: data,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            hoverOffset: 0,
          },
        ],
      },
      options: {
        cutout: '75%', // Set cutout to '80%' for a specific cutout size
        aspectRatio: 1, // Ensure a square aspect ratio
        responsive: true, // Allow chart to be responsive
        maintainAspectRatio: true, // Maintain aspect ratio
        plugins: {
          legend: {
            display: false, // Hide the legend
          },
          tooltip: {
            // Your tooltip configuration
          },
        },
      },
    });
  }
}
