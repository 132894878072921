import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateNumber',
})
export class AbbreviateNumberPipe implements PipeTransform {
  transform(value: number, includeDecimal: boolean): string {
    if (value === null || value === undefined) {
      return '';
    }
    if (includeDecimal) {
      if (value < 1000) {
        return value.toString();
      } else if (value < 1000000) {
        return (value / 1000).toFixed(1) + 'K';
      } else if (value < 1000000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else {
        return (value / 1000000000).toFixed(1) + 'B';
      }
    } else {
      if (value < 1000) {
        return value.toString();
      } else if (value < 1000000) {
        return (value / 1000).toFixed(1) + 'K';
      } else if (value < 1000000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else {
        return (value / 1000000000).toFixed(1) + 'B';
      }
    }
  }
}
