import { Injectable } from '@angular/core';
import { User } from 'src/app/core/models/user';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TokenService } from 'src/app/core/authentication/token.service';
import { EncryptionService } from 'src/app/core/authentication/encryption-service';
import { environment } from 'src/environments/environment';
import { API_ENDPOINTS } from 'src/app/core/http/api-end-points';
import { CommonService } from '../common/common.service';
import { LoaderService } from '../LoaderService/loader.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public currentUser = new Subject<any>();
  public currentUser$ = this.currentUser.asObservable();
  skeletonValue = new BehaviorSubject(false);
  invitationNavigation: Subject<any>;

  constructor(
    private encryptionService: EncryptionService,
    private tokenService: TokenService,
    private httpClint: HttpClient,
    private _commonService: CommonService,
    private loaderService: LoaderService // Inject LoaderService
  ) {
    this.invitationNavigation = new Subject();
  }

  logout() {
    localStorage.removeItem('isLoggedIn');
  }

  CCMSignIn(data): Observable<any> {
    return this.httpClint
      .post(environment.baseApiUrl + API_ENDPOINTS.USER.LOGIN_CLIENT_URL, data)
      .pipe(
        map((response) => {
          let { status, token, user, passportToken } = JSON.parse(
            JSON.stringify(response)
          );
          if (status == 'success') {
            localStorage.setItem('token', token);
            localStorage.setItem('passportToken', passportToken);
            localStorage.setItem('currentUser', JSON.stringify(user));
          } else {
            // this._commonService.showServerError = true;
          }
          this.currentUser.next(user);
          return response;
        })
      );
  }

  CCMSignUp(data): Observable<any> {
    return this.httpClint
      .post(environment.baseApiUrl + API_ENDPOINTS.USER.SIGN_UP_CCM, data)
      .pipe(
        map((response) => {
          let { status, token, user, passportToken } = JSON.parse(
            JSON.stringify(response)
          );
          if (status == 'success') {
            localStorage.setItem('token', token);
            localStorage.setItem('passportToken', passportToken);
            localStorage.setItem('currentUser', JSON.stringify(user));
          } else {
            // this._commonService.showServerError = true;
          }
          this.currentUser.next(user);
          return response;
        })
      );
  }

  async verifyCCMUserObservable(email: string): Promise<Observable<any>> {
    let userData = {
      email: email,
    };
    return this.httpClint
      .post(environment.baseApiUrl + API_ENDPOINTS.USER.VERIFY_LOGIN_CCM, {
        payload: JSON.stringify(userData),
      })
      .pipe(
        finalize(() => {
          this.loaderService.hide(); // Hide loader when request completes
        }),
        map((response) => {
          let { status, token, user, passportToken } = JSON.parse(
            JSON.stringify(response)
          );
          if (status == 'success') {
            localStorage.setItem('token', token);
            localStorage.setItem('passportToken', passportToken);
            localStorage.setItem('currentUser', JSON.stringify(user));
          } else {
            // this._commonService.showServerError = true;
          }
          this.currentUser.next(user);
          return response;
        })
      );
  }

  async loginUserObservable(userData: User): Promise<Observable<any>> {
    this.loaderService.show();
    return this.httpClint
      .post(environment.baseApiUrl + API_ENDPOINTS.USER.LOGIN_CLIENT_URL, {
        payload: JSON.stringify(userData),
      })
      .pipe(
        finalize(() => {
          this.loaderService.hide(); // Hide loader when request completes
        }),
        map((response) => {
          let { status, token, user, passportToken } = JSON.parse(
            JSON.stringify(response)
          );
          if (status == 'success') {
            localStorage.setItem('token', token);
            localStorage.setItem('passportToken', passportToken);
            localStorage.setItem('currentUser', JSON.stringify(user));
          } else {
            // this._commonService.showServerError = true;
          }
          this.currentUser.next(user);
          return response;
        })
      );
  }

  async googleLogin(response: any): Promise<Observable<any>> {
    this.loaderService.show();

    return this.httpClint
      .post(environment.baseApiUrl + API_ENDPOINTS.USER.GOOGLE_LOGIN_URL, {
        response,
      })
      .pipe(
        finalize(() => {
          this.loaderService.hide(); // Hide loader when request completes
        }),
        map((response) => {
          let { token, user } = JSON.parse(JSON.stringify(response));
          localStorage.setItem('token', token);
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUser.next(user);
          return response;
        })
      );
  }

  async appleLogin(response: any): Promise<Observable<any>> {
    this.loaderService.show();

    return this.httpClint
      .post(environment.baseApiUrl + API_ENDPOINTS.USER.APPLE_LOGIN_URL, {
        payload: { id_token: response },
      })
      .pipe(
        finalize(() => {
          this.loaderService.hide(); // Hide loader when request completes
        }),
        map((response) => {
          let { token, user } = JSON.parse(JSON.stringify(response));
          localStorage.setItem('token', token);
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUser.next(user);
          return response;
        })
      );
  }

  passportLogin(): Observable<any> {
    this.loaderService.show();

    return this.httpClint
      .post(environment.baseApiUrl + API_ENDPOINTS.USER.PASSPORT_LOGIN, {
        payload: JSON.stringify({
          sign: localStorage.getItem('passportToken'),
        }),
      })
      .pipe(
        finalize(() => {
          this.loaderService.hide(); // Hide loader when request completes
        }),
        map((response) => {
          let { status, token, user, passportToken } = JSON.parse(
            JSON.stringify(response)
          );
          if (status == 'success') {
            localStorage.setItem('token', token);
            localStorage.setItem('passportToken', passportToken);
            localStorage.setItem('currentUser', JSON.stringify(user));
          } else {
            this._commonService.showServerError = true;
          }
          this.currentUser.next(user);
          return response;
        })
      );
  }

  async signUpUserObservable(user: User): Promise<Observable<any>> {
    this.loaderService.show();

    return this.httpClint
      .post(environment.baseApiUrl + API_ENDPOINTS.USER.REGISTER_CLIENT_URL, {
        payload: JSON.stringify(user),
      })
      .pipe(
        finalize(() => {
          this.loaderService.hide(); // Hide loader when request completes
        }),
        map((response) => {
          return response;
        })
      );
  }

  public get loggedIn(): boolean {
    return localStorage.getItem('currentUser') !== null;
  }

  RequestResetPassword(email: string, redirectUrl: string): Observable<any> {
    this.loaderService.show();

    let body = {
      payload: JSON.stringify({
        email: email,
        redirect_url: redirectUrl,
      }),
    };
    return this.httpClint
      .post(
        environment.baseApiUrl +
          API_ENDPOINTS.RESET_PASSWORD.REQUEST_RESET_PASSWORD,
        body
      )
      .pipe(
        finalize(() => {
          this.loaderService.hide(); // Hide loader when request completes
        }),
        map((res) => {
          this._commonService.apiLogs = [
            ...this._commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.RESET_PASSWORD.REQUEST_RESET_PASSWORD,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }
  CreateNewPassword(
    email: string,
    token: string,
    password: string
  ): Observable<any> {
    let body = {
      payload: JSON.stringify({
        email,
        token,
        password,
      }),
    };
    this.loaderService.show();

    return this.httpClint
      .post(
        environment.baseApiUrl +
          API_ENDPOINTS.RESET_PASSWORD.CREATE_NEW_PASSWORD,
        body
      )
      .pipe(
        finalize(() => {
          this.loaderService.hide(); // Hide loader when request completes
        }),
        map((res) => {
          this._commonService.apiLogs = [
            ...this._commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.RESET_PASSWORD.CREATE_NEW_PASSWORD,
              body: body,
              response: res,
            },
          ];
          return res;
        })
      );
  }
  resendVerificationEmail(email: string) {
    let body = {
      payload: JSON.stringify({
        email: email,
      }),
    };
    this.loaderService.show();

    return this.httpClint
      .post(
        environment.baseApiUrl +
          API_ENDPOINTS.RESEND_EMAIL.REQUEST_RESEND_EMAIL,
        body
      )
      .pipe(
        finalize(() => {
          this.loaderService.hide(); // Hide loader when request completes
        }),
        map((res) => {
          return res;
        })
      );
  }
}
