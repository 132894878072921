import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { API_ENDPOINTS } from 'src/app/core/http/api-end-points';
import { CommonService } from '../common/common.service';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  httpClint: any;
  consoleLogFileS3URL: string = '';
  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {}

  assignees: any = [];

  getConsoleLogS3File(url: string): Observable<string> {
    this.consoleLogFileS3URL = url;
    return this.httpClient.get(url, {
      responseType: 'text',
    });
  }

  getData(): string {
    return this.consoleLogFileS3URL;
  }
  @Cacheable()
  getAssignee(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let body = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };

    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.ASSIGNEE.FETCH_ASSIGNEE;

    return this.commonService.performPostRequest(url, body);
  }

  createProject(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.CREATE_PROJECT;

    return this.commonService.performPostRequest(url, body);
  }
  integrateSDK(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.INTEGRATE_SDK;

    return this.commonService.performPostRequest(url, body);
  }

  getProjectDetails(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
        pg: 0,
      }),
    };
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.PROJECT_DETAIL;

    return this.commonService.performPostRequest(url, requestBody);
  }
  getProjectDashboardDetails(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };

    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.PROJECT_DASHBOARD;

    return this.commonService.performPostRequest(url, requestBody);
  }

  getProjectDetailsForWebOnDashboard(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };

    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.PROJECT_DASHBOARD_FOR_WEB;

    return this.commonService.performPostRequest(url, requestBody);
  }

  getProjectDashboardGraphs(): Observable<any> {
    const numberOfIntervals = 7;
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
        interval: numberOfIntervals,
      }),
    };

    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.PROJECT_DASHBOARD_GRAPHS;

    return this.commonService.performPostRequest(url, requestBody);
  }
  fetchProjectNotifications(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };

    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.FETCH_NOTIFICATIONS;

    return this.commonService.performPostRequest(url, requestBody);
  }
  @Cacheable()
  getBugsStats() {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
        pg: 0,
      }),
    };
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.PROJECT_BUG_STATS;

    return this.commonService.performPostRequest(url, requestBody);
  }
  @Cacheable()
  getBugsTeamPerformance() {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
        pg: 0,
      }),
    };
    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.PROJECT_BUG_TEAM_PERFORMANCE;

    return this.commonService.performPostRequest(url, requestBody);
  }
  getBugsReportStats(days: any, versions: any) {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
        env_hostAppVersion: versions,
        filterDays: days,
      }),
    };
    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.PROJECT_BUG_REPORT_STATS;

    return this.commonService.performPostRequest(url, requestBody);
  }
  getProjectBugListing(page: number): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
        pg: page,
      }),
    };

    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.PROJECT_BUG_LISTING;

    return this.commonService.performPostRequest(url, requestBody);
  }
  getProjectBugDetails(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.PROJECT_BUG_DETAILS;
    return this.commonService.performPostRequest(url, body);
  }
  getProjectCrashDetails(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.PROJECT_CRASH_DETAILS;
    return this.commonService.performPostRequest(url, body);
  }
  updateProjectDetails(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.UPDATE_PROJECT_DETAILS;
    return this.commonService.performPostRequest(url, body);
  }
  uploadAvatar(formData: any): Observable<any> {
    return this.httpClient
      .post(
        environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.UPLOAD_AVATAR,
        formData
      )
      .pipe(
        map((res) => {
          this.commonService.apiLogs = [
            ...this.commonService.apiLogs,
            {
              url:
                environment.workspaceApiUrl +
                API_ENDPOINTS.PROJECTS.UPLOAD_AVATAR,
              body: formData,
              response: res,
            },
          ];
          return res;
        })
      );
  }
  deleteProject(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.DELETE_PROJECT;

    return this.commonService.performPostRequest(url, body);
  }

  getProjectCrashListing(page: number): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
        pg: page,
      }),
    };
    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.PROJECT_CRASH_LISTING;
    return this.commonService.performPostRequest(url, requestBody);
  }

  // fetching project tags from backend server
  @Cacheable()
  getProjectTags(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };

    let url = environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.PROJECT_TAGS;

    return this.commonService.performPostRequest(url, requestBody);
  }

  // fetching project statuses  from backend server
  getProjectStatus(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: project.p_kuid,
      }),
    };
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.PROJECT_STATUSES;

    return this.commonService.performPostRequest(url, requestBody);
  }

  // invite members to project
  // --------------------------------------
  inviteMember(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.PROJECT_INVITE_MEMBER;
    return this.commonService.performPostRequest(url, body);
  }
  updateMemberRole(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.UPDATE_MEMBER_ROLE;

    return this.commonService.performPostRequest(url, body);
  }
  fetchMembers(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.FETCH_MEMBERS;

    return this.commonService.performPostRequest(url, body);
  }
  fetchProjectRoles(body: any): Observable<any> {
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.PROJECT_USER_ROLE;

    return this.commonService.performPostRequest(url, body);
  }
  fetchProjectVersions(): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: JSON.parse(
          localStorage.getItem('selectedProject') || '{}'
        ).p_kuid,
      }),
    };

    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.FETCH_FORCE_UPDATE_PROJECT_VERSION;
    return this.commonService.performPostRequest(url, body);
  }
  checkIsSDKIntegratedOrNot(id: string): Observable<any> {
    let body = {
      payload: JSON.stringify({
        project_kuid: id,
      }),
    };

    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.IS_SDK_INTEGRATED;

    return this.commonService.performPostRequest(url, body);
  }

  getProjectsList(): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
      }),
    };
    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.PROJECT_LIST_OWNED;

    return this.commonService.performPostRequest(url, requestBody);
  }
  updateProjectdata(name: any, env: any, p_kuid: any): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let project = JSON.parse(localStorage.getItem('selectedProject') || '{}');

    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: p_kuid,
        title: name,
        environment: env,
      }),
    };
    let url =
      environment.workspaceApiUrl +
      API_ENDPOINTS.PROJECTS.UPDATE_PROJECT_DETAILS;

    return this.commonService.performPostRequest(url, requestBody);
  }

  deleteProjectFromSetting(p_kuid: any): Observable<any> {
    let workspace = JSON.parse(
      localStorage.getItem('lastVisited_workspace') || '{}'
    );
    let requestBody = {
      payload: JSON.stringify({
        workspace_kuid: workspace.ws_kuid,
        project_kuid: p_kuid,
      }),
    };

    let url =
      environment.workspaceApiUrl + API_ENDPOINTS.PROJECTS.DELETE_PROJECT;

    return this.commonService.performPostRequest(url, requestBody);
  }
}
