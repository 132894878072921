import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common/common.service';
import { UblDashboardService } from 'src/app/core/services/dashboard/ubl-dashboard.service';
@Component({
  selector: 'app-pay-anyone-dashboard',
  templateUrl: './pay-anyone-dashboard.component.html',
  styleUrls: ['./pay-anyone-dashboard.component.scss'],
})
export class PayAnyoneDashboardComponent implements OnInit {
  @Input() dashboardData: any;
  componentName = 'Pay Anyone';
  chartName: string = 'system-stability-line-chart1';
  networkChartName: string = 'chart1';
  statsByPlatform: any;
  ublDashboardPayloadData: any;
  w_kuid: string;
  androidP_kuid: string;
  iosP_kuid: string;
  overallHealth: number;
  lastFetchTime: Date;
  ngOnInit(): void {
    this.emitublDashboardPayloadData();
    this.fetchAPIData();
  }

  constructor(
    private _ublDash: UblDashboardService,
    private router: Router,
    private commonService: CommonService
  ) {}

  emitublDashboardPayloadData() {
    this.ublDashboardPayloadData = JSON.parse(
      localStorage.getItem('UblDashboardPayloadData')
    );
    if (this.ublDashboardPayloadData) {
      this.setUblDashboardPayloadData();
    } else {
      console.log('No Ubl Dashboard Payload Data found in localStorage');
    }
  }

  setUblDashboardPayloadData() {
    this.w_kuid = this.ublDashboardPayloadData[0].workspace_kuid;
    this.iosP_kuid = this.ublDashboardPayloadData[0].project_kuid;
    this.androidP_kuid = this.ublDashboardPayloadData[1].project_kuid;
  }

  fetchAPIData() {
    this.commonService.isLoading = true;
    let body = {
      workspace_kuid: this.w_kuid,
      project_kuids: [this.androidP_kuid, this.iosP_kuid],
      date: this._ublDash.getCurrentDateTime(),
      journey_name: this.componentName,
    };
    this._ublDash.getPayAnyoneDashboardData(body).subscribe((res) => {
      if (res) {
        console.log('response-dash: ', res);
        this.dashboardData = res.graphData;
        this.lastFetchTime = res.lastFetchTime;
        console.log(
          'Test Last Fetch Time ________________',
          this.lastFetchTime
        );
        this.overallHealth = res.overallHealth;
        console.log(
          'Test Overall HEalth __________________',
          this.overallHealth
        );
        this.statsByPlatform = res.graphData.stats_by_platform;
        console.log('this.statsByPlatform', this.statsByPlatform);
        console.log('this.dashboardData', this.dashboardData);
      }
      //   else
      //   {
      //     this.commonService.showError('Something Went Wrong', '');
      //   }
      this.commonService.isLoading = false;
    });
  }
}
